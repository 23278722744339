<template>
  <el-card class="box-card main-full-content">
    <div class="f-p-b-15">
      <el-button
        @click="addOne"
        type="primary"
        size="small"
        style="float: left; margin-left: 20px"
        >添加</el-button
      >
      <el-button
        @click="editBatch"
        type="primary"
        size="small"
        style="float: left; margin-left: 20px"
        >保存</el-button
      >
      <el-button
        type="primary"
        size="medium"
        @click="downloadModel(url.downloadExcel, '快递单号模板')"
        >模板</el-button
      >
      <el-upload
        style="float: left; margin-left: 20px"
        class="upload-demo"
        action=""
        :http-request="importOperation"
        :show-file-list="false"
        :before-upload="beforeUpload"
        accept=".xls,.xlsx"
      >
        <el-button type="primary" size="medium">导入</el-button>
      </el-upload>
      <el-button
        style="float: left; margin-left: 20px"
        type="primary"
        size="small"
        icon="el-icon-download"
        @click="handleExportXls($route.query.orderNo + '-跟踪号数据')"
        >导出</el-button
      >
      <!-- <div>{{ showBillNumber }}</div> -->
      <div class="showBillNumber_box">
        <div>主跟踪单号：</div>
        <div v-for="(item, index) in showBillNumberList" :key="index">
          <span >
          {{ item }}
        </span>
          <el-button type="text" @click="query17Track(item)" icon="el-icon-search"></el-button>
        </div>

      </div>
    </div>

    <!--        <el-button type="primary"  icon="el-icon-download" @click="handleExportXls" size="small">导出</el-button>-->

    <!--      展示跟踪号数据-->
    <!--      跟踪号：{{logNoDetails}}-->
    <el-form :model="form" ref="form" :rules="rules">
      <f-table
        v-loading="loading"
        ref="GTable"
        border
        size="medium"
        row-key="id"
        class="c-th-has-bgcolor"
        :columns="columns"
        :data="form.tableList"
        :cell-style="{ 'text-align': 'center' }"
      >
        <template slot-scope="scope">
          <template v-if="scope.column.property === 'action'">
            <el-button
              @click="deleteOne(scope.row, scope.$index)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
          <template v-else-if="scope.column.property === 'logNo'">
            <el-form-item :prop="'tableList.' + scope.$index + '.logNo'">
              <el-input
                v-model="scope.row.logNo"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="scope.column.property === 'logType'">
            <el-form-item :prop="'tableList.' + scope.$index + '.logType'">
              <f-select
                v-model="scope.row.logType"
                :is-need="scope.row.logType"
                maxlength="50"
                show-word-limit
                :dict="'hy_log_no_type'"
              ></f-select>
            </el-form-item>
          </template>
        </template>
      </f-table>
    </el-form>
    <!-- 弹框 -->
    <track-dialog
        :visible.sync="trackVisible"
        :row="trackRow"
        @ok="closeTrack"
    />

  </el-card>
</template>

<script>
import { deleteAction,getAction,postAction } from "@/api/manage";
import trackDialog from "@/views/publicPage/component/trackDialog.vue";

export default {
  name: "LogNoModule",
  components: {trackDialog},
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      columns: [
        {
          prop: "action",
          label: "操作",
        },
        {
          type: "index",
          label: "编号",
        },
        {
          prop: "logNo",
          label: "单号",
        },
        {
          prop: "logType",
          label: "类型",
        },
      ],
      url: {
        list: "/order/log/no/listByOrderNo",
        addOrUpdate: "/order/log/no/addOrUpdate",
        import: "/order/log/no/import",
        downloadExcel: "/order/log/no/downloadExcel",
        delOne: "/order/log/no/delOne",
        saveBatch: "/order/log/no/saveBatch",
        exportXlsUrl: "/file/export/excel/osOrderLogNoExcel",
        query17Track: "/interface/17track/checkTrackingListByOrderNoAndLogNo",
      },
      form: {},
      loading: false,
      queryForm: {},
      filterForm: {},
      rules: {
        logNo: [{ required: true, message: "请输入单号", trigger: "change" }],
        logType: [{ required: true, message: "请选择类型", trigger: "change" }],
      },
      logNoDetails: "",
      showBillNumberList: [],
      trackVisible : false,
      trackRow : {},
    };
  },
  methods: {
    closeTrack(){
      this.trackRow = {};
      this.trackVisible = false;
    },
    query17Track(logNo) {
      getAction(this.url.query17Track,
          {
            orderNo : this.$route.query.orderNo,
            logNo : logNo
          }).then(res => {
            if (res && res.success && res.result && res.result.length > 0) {
              this.trackRow = {trackIng : res.result};
              this.trackVisible = true;
            }else {
              this.$message.warning("未查询到物流轨迹");
            }
      })
    },
    downloadModel(url, fileName) {
      let params = { ...this.queryForm, ...this.filterForm };
      postAction(url, params, { responseType: "blob" }).then((response) => {
        if (!response) {
          return;
        }
        const blob = new Blob([response]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          let u = window.URL.createObjectURL(response);
          let aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = u;
          aLink.setAttribute("download", fileName + ".xls");
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
          window.URL.revokeObjectURL(u);
        }
        // var blob = new Blob([response],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
        // var downloadElement = document.createElement('a');
        // var herf = window.URL.createObjectURL(blob);
        // downloadElement.herf = herf;
        // downloadElement.download = fileName + ".xlsx";
        // document.body.appendChild(downloadElement);
        // downloadElement.click();
        // document.body.removeChild(downloadElement);
        // window.URL.revokeObjectURL(herf);
      });
    },
    //导入操作绑定
    importOperation(data) {
      console.log("上传文件", data);
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("orderNo", this.$route.query.orderNo);
      postAction(this.url.import, formData).then((res) => {
        console.log("产品返回数据", res);
        if (res.success) {
          this.$message.success(res.message);
          this.handleQuery();
        }
      });
    },
    beforeUpload(file) {
      //对文件大小进行限制
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        this.$message.warning("文件大小不能超过15MB");
      }
      const isFile = file.name.indexOf(".") !== -1;
      if (!isFile) {
        this.$message.warning("该文件名不合法");
      }
      return isLt2M && isFile;
    },
    deleteOne(row, index) {
      if (row.id) {
        deleteAction(this.url.delOne, { id: row.id }).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.initVue();
          }
        });
      } else {
        this.form.tableList.splice(index, 1);
      }
    },

    editBatch() {
      postAction(this.url.saveBatch, this.form.tableList).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.initVue();
        }
      });
    },
    addOne() {
      if (!this.form.tableList) {
        this.form.tableList = [];
      }
      this.form.tableList.push({
        logNo: "",
        logType: "",
        orderNo: this.$route.query.orderNo,
      });
    },
    initVue() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.handleQuery();
    },
    handleQuery() {
      this.loading = true;

      getAction(this.url.list, { orderNo: this.queryForm.orderNo })
        .then((res) => {
          console.log(res);
          if (res.success) {
            if (res.result) {
              // const {records, total} = res.result

              this.form.tableList = res.result;
              if (!this.form.tableList) {
                this.form.tableList = [];
              }
              res.result.forEach((item) => {
                if (item.isMain === "1") {
                  this.showBillNumberList.push(item.logNo);
                }
              });
            } else {
              this.form.tableList = [];
            }
            // this.logNoDetails = this.form.tableList.map(t=>t.logNo).join(',')
          }
        })
        .finally(() => {
          this.loading = false;
          // this.initMoreFuc && this.ipagination.currentPage == 1 ? this.initMoreFuc() : ''; //用于初始的时候调用其他的方法
        });
    },
    handleExportXls(fileName) {
      if (!fileName || typeof fileName != "string") {
        fileName = "导出文件";
      }
      if (fileName === "正常产品" || fileName === "所有产品") {
        this.$confirm(`导出的数据量比较庞大，需要等待1-2分钟?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
          .then(() => {
            this.exportExcel(fileName);
          })
          .catch(() => {});
      } else {
        this.exportExcel(fileName);
      }
    },
    exportExcel(fileName) {
      let params = { ...this.queryForm, ...this.filterForm };
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        params["selections"] = this.selectedRowKeys.join(",");
        params["ids"] = this.selectedRowKeys.join(",");
        params["orderNos"] = this.selectedRowOrderNos.join(",");
      } else {
        params["ids"] = "";
      }

      postAction(this.url.exportXlsUrl, params, { responseType: "blob" }).then(
        (data) => {
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(
              new Blob([data], { type: "application/vnd.ms-excel" }),
              fileName + ".xls"
            );
          } else {
            let url = window.URL.createObjectURL(
              new Blob([data], { type: "application/vnd.ms-excel" })
            );
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + ".xls");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(url); //释放掉blob对象
          }
        }
      );
    },
  },

  created() {
    this.initVue();
  },
};
</script>

<style lang="scss" scoped>
.showBillNumber_box {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
  span {
    margin-right: 5px;
  }
}
</style>
