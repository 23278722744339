<template>
    <div class="main-full-content">
        <div class="fixed_top">
            <el-card class="box-card">
                
                <div ref="getheight" class="customer_info" >
                    <div v-html="orderInfo"></div>
                    <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="120px"
                size="medium"
        >
        <el-form-item
                                label="报关方式"
                                prop="declareType"
                                style='width:314.75px;'
                        >
                            <f-select
                                    :is-need="form.declareType"
                                    :dict="'kd_declare_type'"
                                    v-model="form.declareType"
                            >
                            </f-select>
                        </el-form-item>
    </el-form>
                   
                </div>
                <div>
                   
                </div>
            </el-card>
        </div>
        <!-- <div ref="getheight" class="fixed">
            <span style="font-weight: bold" v-text="orderInfo"></span>
        </div> -->

        <!-- <el-divider content-position="left"></el-divider> -->
        <el-tabs class="fixed_main no_step" tab-position="top" @tab-click="handleClick" v-model="activeName"
                 style="padding: 0 10px">
            <el-tab-pane
                    label="基本信息" name="first" :key="'first'">
                <basic-module :order="order" v-if="isFirst" @update="updateOrder" :declareType="form.declareType"
                              @getOrderData="getOrderData($route.query.orderNo)" @setDeclareType='setDeclareType'></basic-module>
            </el-tab-pane>
            <el-tab-pane
                    label="材积信息" name="second" :key="'second'">
                <volume-of-wood-module :order="order" v-if="isSecond" @update="updateOrder"></volume-of-wood-module>
            </el-tab-pane>
            <el-tab-pane
                    label="应收账单" name="third" :key="'third'">
                <rec-module :order="order" v-if="isThird"></rec-module>
            </el-tab-pane>
            <el-tab-pane label="应付账单" name="four" :key="'four'" v-if="isShowPayModule">
                <pay-module :order="order" v-if="isFour"></pay-module>
            </el-tab-pane>
            <el-tab-pane
                    label="文件信息" name="five" :key="'five'">
                <file-module :order="order" v-if="isFive"></file-module>
            </el-tab-pane>
            <el-tab-pane
                    label="物流信息" name="six" :key="'six'">
                <status-module :order="order" v-if="isSix"></status-module>
            </el-tab-pane>
            <!-- <el-tab-pane
                    label="产品信息" name="seven" :key="'seven'">
                <product-module :order="order" v-if="isSeven" @getOrderData="getOrderData($route.query.orderNo)"></product-module>
            </el-tab-pane> -->
            <el-tab-pane
                    label="操作记录" name="eight" :key="'eight'">
                <log-module :order="order" v-if="isEight"></log-module>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import BasicModule from "./modules/BasicModule";
    import VolumeOfWoodModule from "./modules/VolumeOfWoodModule";
    import RecModule from "./modules/RecModule";
    import PayModule from "./modules/PayModule";
    import FileModule from "./modules/FileModule";
    import StatusModule from "./modules/StatusModule";
    // import ProductModule from "./modules/ProductModule";
    import LogModule from "./modules/LogModule";
    import {getAction} from '@/api/manage'
    import {setRouteQuery} from '@/utils/util';

    export default {
        name: 'Edit',
        components: {
            BasicModule,
            VolumeOfWoodModule,
            RecModule,
            PayModule,
            FileModule,
            StatusModule,
            // ProductModule,
            LogModule,
        },
        data() {
            return {
                orderInfo: '',
                //默认第一个tab
                activeName: 'first',
                isFirst: true,
                isSecond: false,
                isThird: false,
                isFour: false,
                isFive: false,
                isSix: false,
                isSeven: false,
                isEight: false,
                isShowPayModule:true,
                tabs: [
                    {label: '基本信息', component: 'BasicModule', show: true},
                    {label: '材积信息', component: 'VolumeOfWoodModule', show: true},
                    {label: '应收信息', component: 'RecModule', show: true},
                    {label: '应付信息', component: 'PayModule', show: true},
                    {label: '文件信息', component: 'FileModule', show: true},
                    {label: '物流信息', component: 'StatusModule', show: true},
                    // {label: '产品信息', component: 'ProductModule', show: true},
                    {label: '操作记录', component: 'LogModule', show: true},
                ],
                //将页面的id传递至下一层
                pageId: '',
                url: {
                    queryByOrderNo: '/order/kdOrder/queryByOrderNo/',
                },
                order: {},
                form:{
                    declareType:''
                },
                rules:{
                    declareType: [
                    {required: true, message: "请选择报关方式", trigger: "change"},
                ],
                }
            }
        },
        watch: {
            '$route': {
                deep: true,
                handler(to, from) {
                    if (to.path !== '/kdorder/Edit') {
                        console.log(to, from, '跳转的地址不是/kdorder/Edit');
                        return;
                    }
                    this.initPage();
                }
            }
        },
        methods: {
            updateOrder(val) {
                console.log('儿子传过来的order、', val);
                this.order = val;
                this.initOrderInfo();
            },
            handleClick(tab) {
                if (tab.name === 'first') {
                    this.isFirst = true;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'second') {
                    this.isFirst = false;
                    this.isSecond = true;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'third') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = true;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'four') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = true;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'five') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = true;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'six') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = true;
                    this.isSeven = false;
                    this.isEight = false;
                } else if (tab.name === 'seven') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = true;
                    this.isEight = false;
                } else if (tab.name === 'eight') {
                    this.isFirst = false;
                    this.isSecond = false;
                    this.isThird = false;
                    this.isFour = false;
                    this.isFive = false;
                    this.isSix = false;
                    this.isSeven = false;
                    this.isEight = true;
                }
            },
            initOrderInfo() {
                this.orderInfo = `
                <span class="key">客户名：</span><span class="value">${this.order.cusName}</span>
                <span class="key">订单号：</span><span class="value">${this.order.orderNo}</span>
                <span class="key">业务员：</span><span class="value">${this.order.salesman}</span>
                <span class="key">预计件数：</span><span class="value">${this.order.ctn}</span>
                `;
                if (this.order.actualCtn) {
                    this.orderInfo += `<span class="key">实际件数：</span><span class="value">${this.order.actualCtn}</span>`;
                }
                if (this.order.actualFeeWeight) {
                    this.orderInfo += `<span class="key">计费重：</span><span class="value">${this.order.updateFeeWeight}</span>`;
                }
                if (this.order.actualCmbWeight) {
                    this.orderInfo += `<span class="key">体积重：</span><span class="value">${this.order.actualCmbWeight}</span>`;
                }
                if (this.order.actualWeight) {
                    this.orderInfo += `<span class="key">实重：</span><span class="value">${this.order.actualWeight}</span>`;
                }
                if (this.order.receiveCountry) {
                    this.orderInfo += `<span class="key">国家：</span><span class="value">${this.order.receiveCountry}</span>`;
                }
                if (this.order.zipCode) {
                    this.orderInfo += `<span class="key">邮编：</span><span class="value">${this.order.zipCode}</span>`;
                }
                if (this.order.shipmentId) {
                    this.orderInfo += `<span class="key">shipmentId：</span><span class="value">${this.order.shipmentId}</span>`;
                }
                if (this.order.channelName) {
                    this.orderInfo += `<span class="key">渠道：</span><span class="value">${this.order.channelName}</span>`;
                }
                if (this.order.isRemote && this.order.isRemote == '1') {
                    this.orderInfo += `<span class="key" style="color: red">偏远</span>`;
                }


            },
            initPage() {
                //页面一加载完成，根据订单号获取数据 渲染在头部
                let orderNo = this.$route.query.orderNo;
                this.isFirst = false;
                this.isSecond = false;
                this.isThird = false;
                this.isFour = false;
                this.isFive = false;
                this.isSix = false;
                this.isSeven = false;
                this.isEight = false;
                console.log('订单号', orderNo);
                if (orderNo) {
                    getAction(this.url.queryByOrderNo + '?orderNo=' + orderNo)
                        .then(res => {
                            this.order = res.result;
                            console.log('获取的订单数据', this.order);
                            // tab初始切换
                            this.activeName = this.$route.query.activeName || 'first';
                            this.handleClick({name: this.activeName});
                            this.initOrderInfo();
                        })
                }
            },
            getOrderData(orderNo) {
                getAction(this.url.queryByOrderNo + '?orderNo=' + orderNo)
                    .then(res => {
                        this.order = res.result;
                        console.log('获取的订单数据', this.order);
                    })
            },
            getUserIsSales() {
                let flag = this.buttonShowList.see;
                 console.log('是否可以查看应付页面',flag);
                if (flag) {

                } else {
                    this.isShowPayModule = false;
                }
            },
            setDeclareType(e) {
                this.$nextTick(()=>{
                    this.form.declareType = e;
                })
            }
        },
        created() {
            this.getUserIsSales();
            this.initPage();
            setRouteQuery(this.$route.query.orderNo, this.$route.fullPath);
        },
      computed:{
        buttonShowList(){
          return {
            
 'see':this.$btnIsShow('kdorder/AllKdOrder', '0', '是否可以查看应付页面'),
          }
        }
      }
    }
</script>

<style lang="scss" scoped>
    .main-full-content {
        // margin-top: 50px;
    }
    .customer_info {
        flex-wrap: nowrap;
    }
    .no_step {
        margin-top: 60px;
    }

    .el-divider--horizontal {
        margin-top: 16px;
        margin-bottom: 20px;

        .el-divider__text {
            font-size: 20px;
        }
    }

    .fixed {
        position: fixed;
        width: auto;
        top: -4px;
        left: 397px;
        color: #000000;
        height: 50px;
        line-height: 50px;
        /* background-color: #ffffff; */
        z-index: 10000;
        font-size: 11px;
    }
</style>
