<template>

  <div class="main-full-content">
    <el-card class="box-card">
      <div class="f-m-b-10" style="display: flex">
        <el-button
            @click="addFee"
            type="primary"
            size="medium"
            v-if="order.recLock === '0' && buttonShowList.add"
        >新增一行
        </el-button>
        <el-button
            @click="delCommissionBatch"
            type="primary"
            size="medium"
            v-if="buttonShowList.plsc"
          >批量删除
        </el-button>
        <el-button
            @click="copyFee"
            type="primary"
            size="medium"
            v-if="buttonShowList.fzddfy  && order.recLock==='0'"
        >复制订单费用
        </el-button>
        <el-button @click="lockBatch" type="primary" size="medium"
                   v-if="buttonShowList.plsdfy"
                   :disabled="selectionRows2.length===0"
        >批量锁定费用
        </el-button>
        <el-button @click="unlockBatch" type="primary" size="medium"
                   v-if="buttonShowList.pljsfy"
                   :disabled="selectionRows2.length===0"
        >批量解锁费用

        </el-button>
        <el-button @click="lockOrder" type="primary" size="medium"
                   v-if="buttonShowList.sdddys && order.recLock==='0'"
        >锁定订单应收
        </el-button>
        <el-button @click="unlockOrder" type="primary" size="medium"
                   v-if="buttonShowList.jsddys && order.recLock==='1'"
                :disabled = "order.salesmanCommission =='1'">
          解锁订单应收
        </el-button>

        <el-button @click="addAttach" type="primary" size="medium"
                   v-if="buttonShowList.tjfsfy && vailCheck(order.beforeApril,order.performanceCheckStatus,order.salesmanCommission) "
                   >添加附属费用</el-button>
        <el-button size="medium" type="primary" @click="osDocPageDownloadBill('2')"
                   v-if="buttonShowList.xzhkyzdzh"
        >下载海空运账单-折合
        </el-button>

        <el-button
            type="primary"
            size="medium"
            @click="downloadModel(url.downloadHyYsModel, '海运应收批量导入模板下载') "
            v-if="buttonShowList.ysdrxz"
        >海运应收批量导入模板下载
        </el-button>
        <el-upload
            action="/"
            :show-file-list="false"
            :http-request="uploadSectionFileYs"
            accept=".xls,.xlsx"
            style="margin:0 10px;display:inline"
        >
          <el-button size="medium" type="primary"
                     :loading="btnLoadingYs"
                     v-if="buttonShowList.ysdr"
          >批量海运应收导入
          </el-button>
        </el-upload>
          <el-button
                  type="primary"
                  size="medium"
                  @click="downloadModel(url.downloadCommissionExcel, '批量导入成本账单模板')"
                  v-if="buttonShowList.pldrmbxz"
          >批量应收导入成本模板下载
          </el-button>
          <el-upload
                  action="/"
                  :show-file-list="false"
                  :http-request="uploadSettleCommissionFile"
                  accept=".xls,.xlsx"
                  style="margin:0 10px;display:inline"
          >
              <el-button size="medium" type="primary"
                         :loading="btnLoading"
                         v-if="buttonShowList.pldr"
              >批量导入应收费用到成本
              </el-button>
          </el-upload>
      </div>
      <el-form :model="form" ref="payref" :rules="rules">
        <el-table
            :header-cell-style="{ 'text-align': 'center' }"
            ref="goodtab"
            :data="form.osOrderSettleList"
            :cell-style="{padding:'0px',margin:'0px'}"
            style="width: 99%"
            class="customTable"
            v-loading="goodsloading"
            @selection-change="handleSelectionChange2"
            @sort-change="handleTableChange"

            :cell-class-name="changeCellStyle">
          <el-table-column
              type="selection"
              width="30">
          </el-table-column>
          <el-table-column
              prop="action"
              label="操作"
              minWidth="120"
          >
            <template slot-scope="scope">
              <!--          <el-button type="text" @click="saveShip(scope.row)">保存</el-button>-->
              <div v-if="order.recLock=='0' || scope.row.isAttach ==='1'">
                <el-button
                    type="text"
                    @click="removeSettle(scope.$index,scope.row.feename)"
                    :disabled="scope.row.locker ==='1' || ['1','2','9'].indexOf(scope.row.status) !== -1 "
                    v-if="buttonShowList.sc"
                >删除
                </el-button>
                <el-button
                    type="text"
                    @click="unlock(scope.row)"
                    v-if="buttonShowList.js && scope.row.locker === '1' && ['1','2','9'].indexOf(scope.row.status) === -1"
                >
                  解锁
                </el-button>
                <el-button
                    type="text"
                    @click="lock(scope.row)"
                    v-if="buttonShowList.sd && scope.row.locker === '0'">
                  锁定
                </el-button>
                <el-button
                    type="text"
                    @click="syncButton(scope.row)"
                    :disabled="scope.row.locker ==='1'"
                    v-if="buttonShowList.tb"
                >同步
                </el-button>
                  <el-button
                      type="text"
                      @click="updateSettleAccountStatus(scope.row,'1')"
                      v-if="buttonShowList.gz && scope.row.isCloseAccount =='0'"
                  >
                      关账
                  </el-button>
                  <el-button
                      type="text"
                      @click="updateSettleAccountStatus(scope.row,'0')"
                      v-if="buttonShowList.jsgz && scope.row.isCloseAccount =='1' "
                  >
                      解除关账
                  </el-button>
              </div>
              <div v-if="order.recLock=='1' && scope.row.isAttach ==='0'">
                <el-form-item class="el_form_item">
                  <span style="color:red;">已锁定</span>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="locker"
              label="锁定状态"
              minWidth="60"
          >
            <template slot-scope="scope">
              <!--          <el-button type="text" @click="saveShip(scope.row)">保存</el-button>-->
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.locker'">
                <span>{{getTag(scope.row.locker)}}</span>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
              prop="billStatus"
              label="是否已开账单"
              minWidth="120"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.billStatus'">

                <span>{{getType(scope.row.billStatus)}}</span>

              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
              prop="settlement"
              label="结算对象"
              minWidth="150"
          >
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">结算对象</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.settleId'"
                            :rules="rules.settlement">

                <f-select :data-list="settlementSelectedList" :dict-size="tableCount" @change="getSettle(scope)" v-model="scope.row.settleId" :is-need="scope.row.settleId"></f-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column

              prop="feename"
              label="费用中文名"
              minWidth="150"
              :show-overflow-tooltip="true"
          >
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">费用中文名</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.feeId'"
                            :rules="rules.feeId">
                <f-select :data-list="hyFeeSelectedList" :dict-size="tableCount" @change="updateFeeType(scope.row,scope.$index)"
                          v-model="scope.row.feeId" :is-need="scope.row.feeId"></f-select>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="feenameUs"
              label="费用英文名"
              minWidth="120"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.feenameUs'">
                <span>{{scope.row.feenameUs}}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="price"
              label="价格"
              minWidth="80"
          >
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">价格</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.price'"
                            :rules="rules.price">

                <el-input v-model="scope.row.price" type="number" @input="getSummaries2"
                          :disabled="getIsDisable(scope.row)"></el-input>


              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="quantity"
              label="数量"
              minWidth="80"
          >
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">数量</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.quantity'"
                            :rules="rules.qty">
                <el-input v-model="scope.row.quantity" type="number" @input="getSummaries2"
                          :disabled="getIsDisable(scope.row)"></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
              prop="total"
              label="合计"
              minWidth="100"
          >
            <template slot-scope="scope">
               <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.total'"
                :rules="rules.price" :key="'osOrderSettleList.' + scope.$index + '.total'">
<!--                  <el-input v-model="scope.row.total" disabled></el-input>-->
                 <span>{{scope.row.total}}</span>

              </el-form-item>
            </template>
          </el-table-column>
<!--          <el-table-column prop="perferFee" style="color: green" label="减免金额" minWidth="80">-->

<!--            &lt;!&ndash; <template slot-scope="scope"> &ndash;&gt;-->
<!--            &lt;!&ndash; <el-form-item class="el_form_item" >-->

<!--                <el-input class="inputGreen" v-model="scope.row.perferFee" type="number"-->
<!--                          disabled></el-input>-->
<!--            </el-form-item> &ndash;&gt;-->
<!--            &lt;!&ndash;                            <span>{{scope.row.perferFee}}</span>&ndash;&gt;-->
<!--            &lt;!&ndash; </template> &ndash;&gt;-->
<!--          </el-table-column>-->
<!--          <el-table-column prop="perferType" style="color: green" label="减免类型" minWidth="80">-->

<!--            &lt;!&ndash; <template slot-scope="scope"> &ndash;&gt;-->
<!--            &lt;!&ndash; <el-form-item class="el_form_item" >-->

<!--                <el-input class="inputGreen" v-model="scope.row.perferType"-->
<!--                          disabled></el-input>-->
<!--            </el-form-item> &ndash;&gt;-->
<!--            &lt;!&ndash;                            <span>{{scope.row.perferType}}</span>&ndash;&gt;-->
<!--            &lt;!&ndash; </template> &ndash;&gt;-->
<!--          </el-table-column>-->
          <el-table-column
              prop="moneyType"
              label="交易币种"
              min-width="100"
          >
            <!-- 标头需要加必填标识 -->
            <template slot="header">
              <template>
                <span class="is_require_form">交易币种</span>
              </template>
            </template>
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.moneyType'"
                            :rules="rules.moneyType">
                <f-select
                    v-model="scope.row.moneyType"
                    :isNeed="scope.row.moneyType"
                    type="number" :data-list="queryDictCodeListMap['bd_cur']"
                    @change="getSummaries2(scope.row.moneyType,scope.$index)"
                    :disabled="getIsDisable(scope.row)"></f-select>
              </el-form-item>
            </template>

          </el-table-column>
          <el-table-column
              prop="realMoneyType"
              label="折合币种"
              minWidth="100"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item exchange"
                            :prop="'osOrderSettleList.' + scope.$index + '.realMoneyType'">
                <span>{{scope.row.realMoneyType}}</span>
              </el-form-item>

            </template>
          </el-table-column>
          <el-table-column
              prop="exchangeRate"
              label="汇率"
              minWidth="80"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.exchangeRate'">
                <span v-if="!buttonShowList.xghl">{{scope.row.exchangeRate}}</span>
                <span v-if="buttonShowList.xghl">
                                  <el-input v-model="scope.row.exchangeRate" oninput="value=value.match(/\d+\.?\d{0,3}/)" @change="getExchangeTotalNow(scope.row)"></el-input>
                                </span>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="exchangeTotal"
              label="折合金额"
              minWidth="80"
          >
            <template slot-scope="scope">
              <!-- <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.exchangeTotal'"> -->
              <!--                                                            {{ // getChangeTotal(scope.row)}}-->
              <!-- <el-input v-model="scope.row.exchangeTotal" disabled></el-input>
          </el-form-item> -->
              <span>{{scope.row.exchangeTotal}}</span>
            </template>
          </el-table-column>

          <el-table-column
              prop="remark"
              label="备注"
              minWidth="120"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item f-p-5" :prop="'osOrderSettleList.' + scope.$index + '.remark'">
                <el-input v-model="scope.row.remark" maxlength="250" type="textarea"
                          :disabled="getIsDisable(scope.row)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="withinRemark"
              label="内部备注"
              minWidth="120"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item f-p-5"
                            :prop="'osOrderSettleList.' + scope.$index + '.withinRemark'">
                <el-input v-model="scope.row.withinRemark" maxlength="250" type="textarea"

                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
                  prop="createBy"
                  label="客户端是否可见"
                  minWidth="90"
                  show-tooltip-when-overflow
          >
              <template slot-scope="scope">
                  <el-form-item class="el_form_item"
                                :prop="'osOrderSettleList.' + scope.$index + '.createBy'">
                      <el-select v-model="scope.row.isCostomerVisiabled" placeholder="请选择">
                          <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                          </el-option>
                      </el-select>                  </el-form-item>
              </template>
          </el-table-column>
          <el-table-column
              prop="createBy"
              label="录入人"
              minWidth="75"
              show-tooltip-when-overflow
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.createBy'">
                <span>{{scope.row.createBy}}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="录入时间"
              minWidth="80"
              show-tooltip-when-overflow

          >
            <template slot-scope="scope">

              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.createTime'">
                {{formateTime(scope.row.createTime)}}
                <!--              <el-input v-model="scope.row.createTime"  disabled></el-input>-->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="锁单时间"
              minWidth="75"
              show-tooltip-when-overflow
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.lockTime'">
                {{formateTime(scope.row.lockTime)}}
                <!--              <el-input v-model="scope.row.lockTime"  disabled></el-input>-->
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
              prop="billTime"
              label="开账单时间"
              minWidth="75"
              show-tooltip-when-overflow
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.billTime'">
                {{formateTime(scope.row.billTime)}}
                <!--              <el-input v-model="scope.row.lockTime"  disabled></el-input>-->
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column
              prop="moneyWay"
              label="收款方式"
              minWidth="75"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item"
                            :prop="'osOrderSettleList.' + scope.$index + '.moneyWay'">
                <span v-text="scope.row.moneyWay"></span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="status"
              label="是否已销账"
              minWidth="75"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.status'">
                <!--                                <span v-if="scope.row.status && scope.row.status ==='1'" style="color:green">已销账</span>-->
                <!--                                <span v-else style="color:red">未销账</span>-->
                <span v-if="scope.row.status && scope.row.status==='1'" style="color:green">已销账</span>
                <span v-else-if="scope.row.status && scope.row.status==='2'" style="color:green">部分销账</span>
                <span v-else-if="scope.row.status && scope.row.status==='9'" style="color:green">付款费用已申请</span>
                <span v-else style="color:red">未销账</span>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="applyNo"
              label="收款编码"
              minWidth="75"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.applyNo'">
                <span v-text="scope.row.applyNo"></span>

              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="billTime"
              label="销账时间"
              minWidth="75"
          >
            <template slot-scope="scope">
              <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.xzTime'">
                <span>{{formateTime(scope.row.xzTime)}}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="taxPosition"
              label="关税位置"
              minWidth="100"
          >
            <template slot-scope="scope">
              <!-- <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.taxPosition'">
                  <el-input v-model="scope.row.taxPosition" type="textarea" disabled></el-input>
              </el-form-item> -->
              <span>{{scope.row.taxPosition}}</span>
            </template>
          </el-table-column>
          <!--                    <el-table-column-->
          <!--                            prop="settleTime"-->
          <!--                            label="销账时间"-->
          <!--                            minWidth="75"-->
          <!--                    >-->
          <!--                        <template slot-scope="scope">-->
          <!--                            <el-form-item class="el_form_item" :prop="'osOrderSettleList.' + scope.$index + '.settleTime'">-->

          <!--                                <span>{{formateTime(scope.row.settleTime)}}</span>-->
          <!--                            </el-form-item>-->
          <!--                        </template>-->
          <!--                    </el-table-column>-->
        </el-table>
        <div class="flex_wrap_between">
          <div>{{ss}}</div>
          <div class="center_content2" :class="{ centered2: !buttonShowList.bc }">
            <f-select
                class="margin_5"
                style="width: 100px"
                :data-list="queryDictCodeListMap['bd_cur']"
                v-model="exchangeCur"
                :isNeed="exchangeCur"
                @change="curChangeBank"
                placeholder="请选择折合币种"
            >
            </f-select>
            <el-select v-model="bankId"
                       placeholder="根据币种选择银行"
                       clearable
                       filterable
                       style="width: 250px;margin-left: -9px">
              <el-option v-for="item in banks" :key="item.id" :value="item.id" :label="item.bankName">
                {{item.bankName + '-' + item.accountName + '-' + item.account + '-' + item.cur }}
              </el-option>
            </el-select>
            <el-button style="margin-left: 10px" @click="setSettlesExchangCur">
              设置折合
            </el-button>
          </div>

          <el-button
              class="margin_5"
              @click="updateBatch"
              type="primary"
              size="medium"
              v-if="buttonShowList.bc"
          >保存
          </el-button>
        </div>
          <!--<div>利润：<span v-text="newProfitTotal"></span></div>-->
        <div v-if="this.containerType =='0' && this.solicitation!=1">
            {{this.commissionTotal}}
            <div v-if="this.isLose == '1'" style="display: flex;flex-direction: column; color: red"><span v-for="item in formatNewProfitTotal.split('\r\n')" v-text="item"></span></div>
            <div v-else style="display: flex;flex-direction: column"><span v-for="item in formatNewProfitTotal.split('\r\n')" v-text="item"></span></div>
            <div>提成：<span v-text="commissionTotal"></span></div>
        </div>
        <div v-else>
            应收费用：
            <div v-if="receiveList && receiveList.length>0">
                  <span  v-for="(item,index) in receiveList" :key="index"
                         style="margin: 0 15px 0 0;font-size: 18px">{{ item.moneyType }}:{{ item.total }}
<!--                  <span style="color: red">（汇率：{{item.exchangeRate}}）</span>-->
                  </span>
            </div>

            <br>
            实际的应付费用：
            <div v-if="payList && payList.length>0">
            <span  v-for="(item,index) in payList" :key="index"
                   style="margin: 0 15px 0 0;font-size: 18px">{{item.moneyType}}:{{item.total}}
<!--            <span style="color: red">（汇率：{{item.exchangeRate}}）</span>-->
            </span>
            </div>
            <div v-if="this.containerType !== '1'">
              预估的应付费用：
              <div v-if="preList && preList.length>0">
              <span  v-for="(item,index) in preList" :key="index"
                    style="margin: 0 15px 0 0;font-size: 18px">{{ item.moneyType }}:{{ item.total }}</span>
              </div>
            </div>

            <br>
            <div>
              实际利润（折合成CNY）
                <span style="margin: 0 15px 0 0;font-size: 18px">
                    <span style="color: red;margin: 0 5px 0 0"></span>
                    &nbsp;&nbsp;CNY：{{profit}}
                </span> &nbsp;&nbsp;
            </div>

         </div>
      </el-form>
    </el-card>

    <el-dialog title="复制订单" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="copy" ref="copy" :rules="rules">
        <el-form-item class="el_form_item" label="订单号" label-width="140px" prop="orderNo">
          <f-select
              v-model="copy.orderNo"
              :isNeed="copy.orderNo"
              :isSuo="true"
              :dict="'os_order_no'"></f-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false" >取 消</el-button>
        <el-button size="medium" type="primary" @click="copySubmit" >确定</el-button>
      </div>
    </el-dialog>

      <el-dialog
              title="同步到"
              :visible.sync="syncFeeDialogVisible"
              width="15%"
              center
      >
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="syncFeeByType(2)">成本</el-button>
                <!--<el-button type="primary" @click="syncFeeByType(3)">应付</el-button>-->
                <el-button type="primary" @click="syncFeeDialogVisible = false">取消</el-button>
            </span>
      </el-dialog>

    <!-- <el-divider></el-divider> -->
    <el-card class="box-card">
      <fee-log-module ref="fee" @ok="init" :type="'0'"></fee-log-module>
    </el-card>
    <no-convert-bill ref="noConvert" @ok="handleQuery2"></no-convert-bill>
    <convert-bill ref="convert" @ok="handleQuery2"></convert-bill>
    <multi-cur-convert-bill ref="multi" @ok="handleQuery2"></multi-cur-convert-bill>
  </div>
</template>

<script>
import {deleteAction, putAction,getAction, postAction} from '@/api/manage'
import FeeLogModule from "./FeeLogModule";
import QueryMixins from "@/mixins/QueryMixins.js"
import {validateCmb, validateCtn, validateUnitValue} from "@/utils/validator";
import noConvertBill from "@/views/hyFinanceManage/modules/receive/noConvertBill";
import convertBill from "@/views/hyFinanceManage/modules/receive/convertBill";
import multiCurConvertBill from "@/views/hyFinanceManage/modules/receive/multiCurConvertBill";
import {caculateSishewuru} from "@/utils/caculate";


export default {
  name: 'RecModule',
  mixins: [QueryMixins],
  components: {
    FeeLogModule,noConvertBill,
    convertBill,multiCurConvertBill
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
      containerType:{
        type: String,
        default: null
      },
      solicitation:{
        type:String,
        default:null
      },
      countFeeNum:{
        type:String,
        default:""
      },
  },
  data() {
    return {
        options:[
            {
                value: '1',
                label: '是'
            },
            {
                value: '0',
                label: '否'
            },
        ],
        settleProfit: [],
        profit: '',
        receiveList: [],
        payList: [],
        preList: [],
      needMulti:false,
      sysCurRateList: [],
      allSettlelist: [],
      allFeeTypelist: [],
      order: {},
      formData2: {
        pageIndex: 1,
        pageSize: 100
      },
      cusId:'',
      customer:'',
      flag:false,
      curFlag:false,
      //GBP
      totalgbp: '',
      // SGD
      totalsgd: '',
      // USD
      totalusd: '',
      // NCD
      totalncd: '',
      // AUD
      totalaud: '',
      // GBP

      // VND
      totalvnd: '',
      // NZD
      totalnzd: '',
      // HKD
      totalhkd: '',
      // CAD
      totalcad: '',
      // EUR
      totaleur: '',
      // INR
      totalinr: '',
      // CNY
      totalcny: '',
      // KRW
      totalkrw: '',
      // NOK
      totalnok: '',
      // JPY
      totaljpy: '',
      // NZD
      ss: '',
      // TWD
      totaltwd: '',

      visible: false,
      row: {},
      feeTypeList: [],
      feeTypeMap: {},
      form: {osOrderSettleList: []},
      url: {
        cacuProfit:'/order/os/settle/caculateProfit',
        getSettleList: '/order/os/settle/list',
        copy: '/order/os/settle/copyOrderFee',
        delete: '/order/os/settle/delete',
        deleteBatch: '/order/os/settle/deleteBatch',
        lock: '/order/os/settle/lock',
        updateSettleAccountStatus: '/order/os/settle/updateSettleAccountStatus',
        unlock: '/order/os/settle/unlock',
        lockBatch: '/order/os/settle/lockBatch',
        lockOrder: '/order/os/settle/lockOrder',
        lockIsOrder: '/order/os/settle/lockIsOrder',
        unlockIsOrder: '/order/os/settle/unLockIsOrder',
        importHySettlesYs: "/order/os/goods/importHySettlesYs",
        downloadHyYsModel: '/order/os/settle/Hy/in/downloadHyYsExcel',
        unlockBatch: '/order/os/settle/unlockBatch',
        unlockOrder: '/order/os/settle/unlockOrder',
        updateBatch: '/order/os/settle/updateBatch',
        syncFee2CommissionBySingle: '/finance/fee/settleCommission/syncFee2CommissionBySingle',
        manualsyncFee: '/finance/fee/order/manualsyncFee',
        syncFee2CommissionByList: '/finance/fee/settleCommission/syncFee2CommissionByList',
        getProfitAndCommission: '/finance/fee/settleCommission/getProfitAndCommission',
        downloadModel:'/order/os/settle/tax/out/dwonloadExcel',
        curGetBank:'/shipping/hySettleReceive/curGetBank',
        checkBill: '/shipping/hySettleReceive/checkReceiveSettleId',
        downloadCommissionExcel: "/finance/fee/settleCommission/downloadCommissionExcel",
        importCommission: '/finance/fee/settleCommission/importCommission',
        openBill:  '/file/jasper/openReceiveBill',
      },
      userlist: [],
      fbaloading: false,
      storeloading: false,
      warehouseList: [],
      fbalist: [],
      addresslist: [],
      addressloading: false,
      btnLoadingYs: false,
      btnLoading: false,
      goodsloading: false,
      selectedRowKeys2: [],
      selectionRows2: [],
      //校验
      rules: {
        settlement: [
          {required: true, message: '请选择结算对象', trigger: 'change'}
        ],
        feeId: [
          {required: true, message: '请选择费用中文名', trigger: 'change'}
        ],
        price: [
          {required: true, message: '请输入价格', trigger: 'blur'},
          {validator: validateUnitValue, trigger: 'change'},
        ],


        qty: [
          {required: true, message: '请填写数量', trigger: 'blur'},
          {validator: validateUnitValue, trigger: 'change'},
        ],
        moneyType: [
          {required: true, message: '请选择币种', trigger: 'change'},

        ],
        orderNo: [
          {required: true, message: '请选择订单号', trigger: 'change'},
        ],

      },
      copy: {},
      dialogFormVisible: false,
      syncFeeDialogVisible: false,
      isouri: false,
      cploading: false,
      proMap: {},
      prolist: [],
      coloading: false,
      exchangeCur:'',//折合币种
      banks:'',//币种对应的银行
      bankId:'',//选中的银行id
      //费用对象 集合
      hyFeeList:[],
      //费用对象 下拉框
      hyFeeSelectedList:[],
      //费用个数
      tableCount:0,
      //结算对象集合
      settlementList:[],
      //结算对象下拉框
      settlementSelectedList:[],
      newProfitTotal: '',
      formatNewProfitTotal:'',
      commissionTotal: '',
      isLose: '',
      syncRow: null,
      needQueryDictCodes:[
          "bd_cur"
      ]
    }
  },
  methods: {
    vailCheck(b,performanceCheckStatus,salesmanCommission){
      if(b && (salesmanCommission ==='1' || performanceCheckStatus==='1')){
        return false;
      }else if (this.order.recLock === '1' && !this.buttonShowList.tjfsfy1){
        return false;
      }else {
        return true;
      }
    },
    // 更新订单费用的关账状态
    updateSettleAccountStatus(row,type){
        if(!row.id){
            this.$message.warning('请保存数据');
            return;
        }
        let form = {id:row.id,isCloseAccount:type,orderNo:row.orderNo,type:'0',feename:row.feename,orderType:'0',settleType:'0'}
        let title ="确定将费用" + row.feename + (type=='0'? "关账吗":"解除关账吗")
        this.$confirm(title, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
            closeOnClickModal: false,
        }).then(() => {
            putAction(this.url.updateSettleAccountStatus,form).then(res => {
                this.$message({
                    type: 'success',
                    message: res.message
                })
                this.initBase()
            })
        }).catch(() => {

        })

    },
     syncButton(row){
      this.syncFeeDialogVisible = true
      this.syncRow = row;
    },
    changeCellStyle (row, column, rowIndex, columnIndex) {
      // 应收-是否已销账添加背景色
      if(row.row.status && ['1', '2', '9'].indexOf(row.row.status) !== -1) {
        return 'table_green_bg'
      } else {
        return 'table_red_bg'
      }
    },
    getType(item) {
      if (item == '1'){
        item ="中文账单";
      }else if (item == '2'){
        item ="英文账单";
      }else if (item == '3'){
        item ="中文折合账单";
      }else if(item == '4'){
        item ="英文折合账单";
      }else{
        item ="";
      }
      return item;
    },
    downloadModel(url, fileName) {
      let params = {...this.queryForm, ...this.filterForm}
      postAction(url, params, {responseType: 'blob'})
          .then(response => {
            if (!response) {
              return
            }
            const blob = new Blob([response])
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName)
            } else {
              let u = window.URL.createObjectURL(response)
              let aLink = document.createElement('a')
              aLink.style.display = 'none'
              aLink.href = u
              aLink.setAttribute('download', fileName + '.xls')
              document.body.appendChild(aLink)
              aLink.click()
              document.body.removeChild(aLink)
              window.URL.revokeObjectURL(u)
            }

          })
    },
      uploadSettleCommissionFile(param) {
          this.btnLoading = true;
          //file就是当前添加的文件
          var fileObj = param.file;
          //FormData 对象
          var form = new FormData();
          //文件对象，key是后台接受的参数名称
          form.append("file", fileObj);
          //执行上传方法
          console.log("执行上传方法");
          this.$confirm("是否确定导入到成本?", "提示", {
              confirmButtonText: "是",
              cancelButtonText: "否",
              distinguishCancelAndClose: true,
              type: "warning",
              closeOnClickModal: false,
          }).then(()=>{
              form.append("type","1");
              postAction(this.url.importCommission, form)
                  .then((res) => {
                      this.btnLoading = true;
                      //这里做上传之后的操作
                      if (res.success) {
                          this.$message.success(res.message);
                          this.initSettleList();
                          this.init();
                      }

                  }).finally(() => {
                  this.btnLoading = false;
              })
          }).catch((action=>{

          }))


      },

    syncFeeByType(type) {
      postAction(this.url.manualsyncFee + "?source=1&target=" + type, this.syncRow).then(res => {
        this.$message({
          type: 'success',
          message: res.message
        })
        this.initBase();
      })
      this.syncFeeDialogVisible = false;
    },

    getIsDisable(row) {
      if (row.isAttach === '1') {
        return false;
      } else if (this.order.recLock === '1' || row.locker === '1') {
        return true;
      } else {
        return false;
      }
    },
    // 批量海运应付导入
    uploadSectionFileYs(param) {
      this.btnLoadingYs = true;
      //file就是当前添加的文件
      var fileObj = param.file;
      //FormData 对象
      var form = new FormData();
      //文件对象，key是后台接受的参数名称
      form.append("file", fileObj);
      //执行上传方法
      console.log("执行上传方法");
      postAction(this.url.importHySettlesYs, form)
          .then((res) => {
            //这里做上传之后的操作
            if (res.success) {
              this.$message.success(res.message);
              this.initSettleList();
              this.init();
            }

          }).finally(() => {
        this.btnLoadingYs = false;

      })

    },

    getSettle(scope) {
      let val = scope.row.settleId;
      if (this.proMap[val]) {
        this.$set(scope.row, 'settlement', this.proMap[val]['settlement']);

        console.log('获取结算对象', scope, this.form.osOrderSettleList);
      }
    },
    init() {
      console.log('费用操作记录', this.$refs['fee']);
      if (this.$refs['fee']) {
        this.$refs['fee'].initVue();
      }
    },
    handleClearSelection() {
      this.$refs['shiptab'].clearSelection();
    },
    handleClearSelection2() {
      this.$refs['goodtab'].clearSelection();
    },

    //导入产品数据


    getTag(item) {
      return item == '1' ? '已锁定' : '未锁定';
    },

    delCommissionBatch() {
      let ids = this.selectionRows2.map((s) => s.id).filter((id) => id!== null && id!== undefined && id!== "").join(",");
      let warningMsg = "";
      let indexList = [];
      let isAllDelete = true;
      this.selectionRows2.forEach((s) => {
        if (!s.id) {
          indexList.push(this.form.osOrderSettleList.indexOf(s));
        }
          if (s.isCloseAccount == "1") {
              warningMsg = "已关账的费用的费用不允许删除";
              isAllDelete = false;
              return false;
          }
        if (s.locker == "1") {
          warningMsg = "已锁定的费用不允许删除";
          isAllDelete = false;
          return false;
        }
      });
      if (!isAllDelete) {
        this.$message.warning(warningMsg);
        return;
      }
      this.$confirm(`确定删除吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      }).then(() => {
          if (ids && ids.length > 1) {
            deleteAction(this.url.deleteBatch,{ ids: ids })
              .then((res) => {
              this.$message.success(res.message);
              this.initBase();
            });
          }
          if (indexList && indexList.length) {
    indexList.sort((a, b) => b - a);
    indexList.forEach(i => {
      this.form.osOrderSettleList.splice(i, 1);
    });
}
        })
        .catch(() => {});
    },
    removeSettle(index, feename) {
      let id = this.form.osOrderSettleList[index].id;
        if ("1" === this.form.osOrderSettleList[index].isCloseAccount) {
            this.$message.warning('费用已关账不允许操作');
            return;
        }
      this.$confirm(`确定删除${feename || ''}吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
          .then(() => {
            if (id && id.length > 1) {
              deleteAction(this.url.delete, {id: id})
                  .then(res => {
                    this.$message.success(res.message);
                    this.initSettleList();
                    // this.init();
                    this.initHTML();
                    // this.form.osOrderSettleList.splice(index, 1);
                  })
            } else {
              this.form.osOrderSettleList.splice(index,1)
            }
            
          }).catch(() => {

      })


    },
    copyFee() {
      this.copy = {};
      this.dialogFormVisible = true;
    },
    copySubmit() {
      this.$refs['copy'].validate(va => {
        if (va) {
          this.copy.type = '0';
          this.copy.oldOrderNo = this.$route.query.orderNo;
          putAction(this.url.copy + "?orderNo=" + this.copy.orderNo + "&type=0&oldOrderNo=" + this.copy.oldOrderNo)
              .then(res => {
                this.$message.success(res.message);
                this.dialogFormVisible = false;
                this.initSettleList();
                this.init();
              })
        }
      })
    },
    addFee() {
      let quantity = this.countFeeNum? this.countFeeNum.replace("CBM", "").replace("KG", "") : 0

      this.form.osOrderSettleList.push({
        perferFee:'',
        settlement: this.order.customer,
        settleId: this.order.cusId,
        feename: '',
        feenameUs: '',
        price: '',
        quantity: quantity,
        moneyType: 'CNY',
        total: '',
        remark: '',
        withinRemark:'',
        createBy: '',
        createTime: '',
        lockTime: '',
        realMoneyType: '',
        exchangeRate: '',
        exchangeTotal: '',
        moneyWay: '',
        fkCode: '',
        xzCode: '',
        fkTime: '',
        xzTime: '',
        locker: '0',
        isCostomerVisiabled:'1',
      })
    },

    addAttach() {
        let quantity = this.countFeeNum? this.countFeeNum.replace("CBM", "").replace("KG", "") : 0
        this.form.osOrderSettleList.push({
        perferFee:'',
        settlement: '',
        feename: '',
        feenameUs: '',
        locker: '0',
        price: '',
        quantity: quantity,
        moneyType: '',
        total: '',
        remark: '',
        withinRemark:'',
        createBy: '',
        createTime: '',
        lockTime: '',
        realMoneyType: '',
        exchangeRate: '',
        exchangeTotal: '',
        moneyWay: '',
        fkCode: '',
        xzCode: '',
        fkTime: '',
        xzTime: '',
        isAttach: '1'
      })
    },
    getAllPriceZero(row) {
      console.log('要计算的对象',row);


      if (!isNaN((parseFloat(row.quantity))) && !isNaN((parseFloat(row.price)))) {
        let per = 0;
        let total = (parseFloat(row.quantity) * parseFloat(row.price)).toFixed(0);
        if (row.perferType ==='数值') {
          per = row.perferFee?row.perferFee:0;
          row.total = (parseFloat(row.quantity) * parseFloat(row.price)).toFixed(2) - Number(per);
        }else if (row.perferType === '百分比') {

          per = (parseFloat(total)*parseFloat(row.perferFee?row.perferFee/100:0)).toFixed(0);
          if (parseFloat(total)>parseFloat(per)) {
            row.total = (parseFloat(total) - parseFloat(per).toFixed(2)).toFixed(0);
          }else {
            row.total = 0;
          }


        }else {
          row.total = total;
        }

      } else {
        row.total = 0;
      }


    },

    getAllPrice(row) {
      console.log('要计算的对象',row);

      row.total = caculateSishewuru(row.quantity,row.price);
      // if (!isNaN((parseFloat(row.quantity))) && !isNaN((parseFloat(row.price)))) {
      //   let per = 0;
      //   let total = (parseFloat(row.quantity) * parseFloat(row.price)).toFixed(2);
      //   // if (row.perferType ==='数值') {
      //   //   per = row.perferFee?row.perferFee:0;
      //   //   row.total = (parseFloat(row.quantity).toFixed(0) * parseFloat(row.price)).toFixed(2) - Number(per);
      //   // }else if (row.perferType === '百分比') {
      //   //
      //   //   per = (parseFloat(total)*parseFloat(row.perferFee?row.perferFee/100:0)).toFixed(2);
      //   //   if (parseFloat(total)>parseFloat(per)) {
      //   //     row.total = (parseFloat(total) - parseFloat(per).toFixed(2)).toFixed(2);
      //   //   }else {
      //   //     row.total = 0;
      //   //   }
      //   //
      //   //
      //   // }else {
      //     row.total = total;
      //   // }
      //
      // } else {
      //   row.total = 0;
      // }


    },
    getChangeTotal(item) {
      if (!isNaN((parseFloat(item.total))) && !isNaN((parseFloat(item.exchangeRate)))) {
        item.exchangeTotal = (parseFloat(item.total) * parseFloat(item.exchangeRate)).toFixed(3);
      } else {
        item.exchangeTotal = 0;
      }
    },

    formateTime(val) {
      if (val) {
        val = val.substring(0, 10);
        return val;
      } else {
        return val;
      }
    },
    updateFeeType(row, index) {

      if (this.feeTypeMap[row.feeId]) {
        console.log('费用的map', this.feeTypeMap[row.feeId])
        this.$set(this.form.osOrderSettleList[index], 'feenameUs', this.feeTypeMap[row.feeId]['englishName']);
        this.$set(this.form.osOrderSettleList[index], 'feename', this.feeTypeMap[row.feeId]['chineseName']);
        this.$set(row, 'feename', this.feeTypeMap[row.feeId]['chineseName']);
        // getAction('/customer/discount/getFeeById',{cusId:this.order.cusId,feeId:row.feeId,total:row.total,channelId:this.order.transportStatus})
        //     .then(res=>{
        //       if (res.success){
        //         this.$set(this.form.osOrderSettleList[index], 'perferFee', res.result.perferFee);
        //         this.$set(this.form.osOrderSettleList[index], 'perferType', res.result.perferType);
        //       }
        //     })
      }

    },



    handleSelectionChange2(selection) { // 多选
      let arr = []
      this.nameList = []
      this.selectionRows2 = [];
      for (let i = 0; i < selection.length; i++) {
        arr.push(selection[i]['id'])
        this.nameList.push(selection[i]['feename'])
        if (selection[i].locker === '0') {

        }
        this.selectionRows2.push(selection[i]);

      }

      this.selectedRowKeys2 = [...arr]
      console.log('选中的集合', this.selectionRows2);
    },

    getSummaries2(param,index) {

      //遍历集合
      this.totalgbp = 0;
      this.totalsgd = 0;
      this.totalusd = 0;
      this.totalncd = 0;
      this.totalaud = 0;
      this.totalvnd = 0;
      this.totalnzd = 0;
      this.totalhkd = 0;
      this.totalcad = 0;
      this.totaleur = 0;
      this.totalinr = 0;
      this.totalcny = 0;
      this.totalkrw = 0;
      this.totalnok = 0;
      this.totaljpy = 0;
      this.totaltwd = 0;
      if (this.form.osOrderSettleList) {
        // if(this.form.osOrderSettleList[index]){
        //   this.form.osOrderSettleList[index].exchangeRate = ''
        // }
        this.form.osOrderSettleList.forEach(s => {
          if (s) {
            if(param  && (s.price == param || s.quantity == param)) {
              this.getAllPrice(s);
            }
            console.log('应收对象的类型',s.moneyType,s.total);
            if (s.moneyType == 'GBP') {
              this.totalgbp += Number(s.total);
            }
            if (s.moneyType == 'SGD') {
              this.totalsgd += Number(s.total);
            }
            if (s.moneyType == 'USD') {
              this.totalusd += Number(s.total);
            }
            if (s.moneyType == 'NCD') {
              this.totalncd += Number(s.total);
            }
            if (s.moneyType == 'AUD') {
              this.totalaud += Number(s.total);
            }
            if (s.moneyType == 'VND') {
              this.totalvnd += Number(s.total);
            }
            if (s.moneyType == 'NZD') {
              this.totalnzd += Number(s.total);
            }
            if (s.moneyType == 'HKD') {
              this.totalhkd += Number(s.total);
            }
            if (s.moneyType == 'CAD') {
              this.totalcad += Number(s.total);
            }
            if (s.moneyType == 'EUR') {
              this.totaleur += Number(s.total);
            }
            if (s.moneyType == 'INR') {
              this.totalinr += Number(s.total);
            }
            if (s.moneyType == 'CNY') {
              this.totalcny += Number(s.total);
            }
            if (s.moneyType == 'KRW') {
              this.totalkrw += Number(s.total);
            }
            if (s.moneyType == 'NOK') {
              this.totalnok += Number(s.total);
            }
            if (s.moneyType == 'JPY') {
              this.totaljpy += Number(s.total);
            }
            if (s.moneyType == 'TWD') {
              this.totaltwd += Number(s.total);
            }

          }

        })
      }
      console.log('this',this);
      this.showSumm();
      console.log('展示费用总计', this.ss);

    },
    getExchangeTotalNow(row){
      row.exchangeTotal = (Number(row.total) * Number(row.exchangeRate)).toFixed(2)
    },
    showSumm() {
      this.ss = "总计: ";
      if (this.totalusd != 0) {
        this.ss += " USD:" + this.totalusd.toFixed(3);
      }
      if (this.totalcny != 0) {
        this.ss += " CNY:" + this.totalcny.toFixed(3);
      }
      if (this.totalcad != 0) {
        this.ss += " CAD:" + this.totalcad.toFixed(3);
      }
      if (this.totalaud != 0) {
        this.ss += " AUD:" + this.totalaud.toFixed(3);
      }
      if (this.totaleur != 0) {
        this.ss += " EUR:" + this.totaleur.toFixed(3);
      }
      if (this.totalgbp != 0) {
        this.ss += " GBP:" + this.totalgbp.toFixed(3);
      }
      if (this.totalhkd != 0) {
        this.ss += " HKD:" + this.totalhkd.toFixed(3);
      }
      if (this.totalinr != 0) {
        this.ss += " INR:" + this.totalinr.toFixed(3);
      }
      if (this.totaljpy != 0) {
        this.ss += " JPY:" + this.totaljpy.toFixed(3);
      }
      if (this.totalkrw != 0) {
        this.ss += " KRW:" + this.totalkrw.toFixed(3);
      }
      if (this.totalncd != 0) {
        this.ss += " NCD:" + this.totalncd.toFixed(3);
      }
      if (this.totalnok != 0) {
        this.ss += " NOK:" + this.totalnok.toFixed(3);
      }
      if (this.totalnzd != 0) {
        this.ss += " NZD:" + this.totalnzd.toFixed(3);
      }
      if (this.totalsgd != 0) {
        this.ss += " SGD:" + this.totalsgd.toFixed(3);
      }
      if (this.totaltwd != 0) {
        this.ss += " TWD:" + this.totaltwd.toFixed(3);
      }
      if (this.totalvnd != 0) {
        this.ss += " VND:" + this.totalvnd.toFixed(3);
      }

    },
    loadmore() {
      this.formData.pageIndex++;
      this.getMorePortlist(this.formData);
    },
    getMorePortlist(v) {
      let num = ~~this.formData.pageIndex * ~~this.formData.pageSize;
      this.prolist = this.allSettlelist.filter((item, index, arr) => {
        return index < num;
      });
    },
    loadmore2() {
      this.formData2.pageIndex++;
      this.getMorePortlist2(this.formData2);
    },
    getMorePortlist2(v) {
      let num = ~~this.formData2.pageIndex * ~~this.formData2.pageSize;
      this.feeTypeList = this.allFeeTypelist.filter((item, index, arr) => {
        return index < num;
      });
    },
    updateBatch() {
      this.$refs['payref'].validate(valid => {
        if (valid) {
          //全选了才判断
          this.form.osOrderSettleList.forEach(s => {
            s.type = '0';
            s.orderNo = this.order.orderNo;
            if (this.feeTypeMap[s.feeId]){
              s.feename = this.feeTypeMap[s.feeId]['chineseName'];
              s.feecode = this.feeTypeMap[s.feeId]['code'];
              s.fileType = this.feeTypeMap[s.feeId]['type'];
            }
            this.getAllPrice(s);
          })
          putAction(this.url.updateBatch + '?orderNo=' + this.order.orderNo, this.form.osOrderSettleList)
              .then(res => {
                if (res.success) {
                  this.$message.success(res.message);
                  this.initSettleList();
                  this.init();
                  setTimeout(() => {
                    this.initHTML();
                  }, 500);
                }
              })

          postAction(this.url.syncFee2CommissionByList + '?type=1', this.form.osOrderSettleList)
            .then(res => {
              this.$message.warning(res.message);
            })
        }
      })

    },
    lock(row) {
      // if (!row.settlement) {
      //     this.$message.warning('请选择结算对象');
      //     return;
      // }
      // if (!row.feename) {
      //     this.$message.warning('请选择费用名');
      //     return;
      // }
      // if (!row.price) {
      //     this.$message.warning('请输入价格');
      //     return;
      // }
      // if (!row.quantity) {
      //     this.$message.warning('请输入数量');
      //     return;
      // }
      // if (!row.moneyType) {
      //     this.$message.warning('请选择币种');
      //     return;
      // }
      if (!row.id) {
        this.$message.warning('请保存数据');
        return;
      }
      if ("1" === row.isCloseAccount) {
            this.$message.warning('费用已关账不允许操作');
            return;
        }
      this.$confirm(`确定锁定${row.feename}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.lock + "?id=" + row.id).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initBase();
        })
      }).catch(() => {

      })
    },
    unlock(row) {
      // if (!row.settlement) {
      //     this.$message.warning('请选择结算对象');
      //     return;
      // }
      // if (!row.feename) {
      //     this.$message.warning('请选择费用名');
      //     return;
      // }
      // if (!row.price) {
      //     this.$message.warning('请输入价格');
      //     return;
      // }
      // if (!row.quantity) {
      //     this.$message.warning('请输入数量');
      //     return;
      // }
      // if (!row.moneyType) {
      //     this.$message.warning('请选择币种');
      //     return;
      // }
      if (!row.id) {
        this.$message.warning('请保存数据');
        return;
      }
      if (['1','2','9'].indexOf(row.status)!==-1){
        this.$message.warning('费用在财务流程中不允许解锁');
        return;
      }
        if ("1" === row.isCloseAccount){
            this.$message.warning('费用已关账不允许操作');
            return;
        }
      this.$confirm(`确定解锁${row.feename}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.unlock + "?id=" + row.id).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    lockBatch() {
        let isAllDelete = true;
        let warningMsg = '';
        this.selectionRows2.forEach(s=>{
            if("1" === s.isCloseAccount){
                warningMsg = '勾选存在已关账的费用不允许锁定';
                isAllDelete = false
                return false;
            }
        })
        if(!isAllDelete){
            this.$message.warning(warningMsg);
            return
        }
      this.$confirm(`确定批量锁定${this.nameList.join(',')}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
          putAction(this.url.lockBatch + "?ids=" + this.selectedRowKeys2.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    unlockBatch() {
      let isAllDelete = true;
      let warningMsg = '';
      this.selectionRows2.forEach(s=>{
        if("1" === s.isCloseAccount){
            warningMsg = '费用已关账不允许解锁';
            isAllDelete = false
            return false;
        }
        if (['1','2','9'].indexOf(s.status) !== -1) {
          warningMsg = '费用在财务流程中不允许解锁';
          isAllDelete = false
          return false;
        }

      })
      if(!isAllDelete){
        this.$message.warning(warningMsg);
        return
      }

      this.$confirm(`确定批量解锁${this.nameList.join(',')}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.unlockBatch + "?ids=" + this.selectedRowKeys2.join(',')).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    lockOrder() {
      this.$confirm(`确定锁定${this.order.orderNo}应收?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.lockOrder + "?orderNo=" + this.order.orderNo + "&type=0").then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    lockIsOrder() {
      this.$confirm(`确定锁定${this.order.orderNo}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.lockIsOrder,{orderNo:this.order.orderNo}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    unLockIsOrder() {
      this.$confirm(`确定解锁${this.order.orderNo}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.unlockIsOrder,{orderNo:this.order.orderNo}).then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },
    unlockOrder() {
      let isAllDelete = true;
      let warningMsg = '';
      // this.form.osOrderSettleList.forEach(s=>{
      //   if (['1','2','9'].indexOf(s.status) !== -1) {
      //     warningMsg = '费用在财务流程中不允许解锁';
      //     isAllDelete = false
      //     return false;
      //   }
      // })
      // if(!isAllDelete){
      //   this.$message.warning(warningMsg);
      //   return
      // }

      this.$confirm(`确定解锁${this.order.orderNo}应收?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
        closeOnClickModal: false,
      }).then(() => {
        putAction(this.url.unlockOrder + "?orderNo=" + this.order.orderNo + "&type=0").then(res => {
          this.$message({
            type: 'success',
            message: res.message
          })
          this.initSettleList();
          this.init();
        })
      }).catch(() => {

      })
    },

      syncFee2CommissionBySingle(row) {
          if (!row.id) {
              this.$message.warning('请保存数据');
              return;
          }
          this.$confirm(`确定同步${row.feename}到成本账单吗?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'error',
              closeOnClickModal: false,
          }).then(() => {
              postAction(this.url.syncFee2CommissionBySingle, row).then(res => {
                  this.$message({
                      type: 'success',
                      message: res.message
                  })
                  this.initBase();
              })
          }).catch(() => {

          })
      },

    handleQuery2() {
      this.goodsloading = true
      let form = {...this.queryForm, ...this.filterForm}
      for (let item in form) {
        if (typeof form[item] === 'string') {
          form[item] = form[item].trim()
          if (form[item] === '') {
            form[item] = null
          }
        }
      }
      const {prop, order} = this.isorter
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf('asc') !== -1 ? 'asc' : 'desc',
      }
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === 'startTime') {
          this.$set(params, 'column', 'startTime')

        } else {
          this.$set(params, 'column', 'createTime')
          this.$set(params, 'order', 'asc')
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage
        params.pageSize = this.ipagination.pageSize
      }
      console.log("查询对象", params)

      getAction(this.url.getSettleList, params).then((res) => {
        if (res.success) {

          this.tableData = res.result.list;
          this.order=res.result.osOrder;
          console.log("表单数据", this.tableData);
          this.form.osOrderSettleList = this.tableData;
          //构建结算对象下拉框
          this.settlementList = res.result.settlementList;
          this.settlementSelectedList = [];
          for (let i=0;i<this.settlementList.length;i++) {
            let obj = this.settlementList[i];
            this.settlementSelectedList.push({value:obj.settleId,label:obj.settlement});
          }
          this.proMap = this.listToMap(this.settlementList, 'settleId');
          this.hyFeeList = res.result.bdFeeTypeList;
          this.feeTypeMap = this.listToMap(this.hyFeeList, "id");
          //构建费用名称下拉框
          this.hyFeeSelectedList = [];
          for (let i=0;i<this.hyFeeList.length;i++) {
            let obj = this.hyFeeList[i];
            this.hyFeeSelectedList.push({value:obj.id,label:obj.chineseName});
          }
          //个数
          this.tableCount = this.tableData.length;
          this.getSummaries2('');
        }
      }).finally(() => {
        this.goodsloading = false
      })
    },
    handleDel() {

    },


    handleReset() {
      this.queryForm = {}
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.queryForm.type = '0';
      this.ipagination.currentPage = 1
      this.handleQuery2();
    },

    initSettleList() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      this.queryForm.type = '0';
      this.handleQuery2();

    },

    initBase(){
      this.disableMixinMounted = true;
      console.log('自己查询', this.order);
      this.initSettleList();
      this.init();
    },
    getOrderPre(){
      let count = [];
      this.selectionRows.forEach(item => {
        let pre = this.deleteNum(item.orderNo)
        if(pre==='KD'){
          count.push(pre)
        }else{
          count.push('OS')
        }
        // count.push(this.deleteNum(item.orderNo))
      });
      console.log(Array.from(new Set(count)))
      let arr = Array.from(new Set(count));
      console.log('账单前缀',arr)

      return arr
    },
    curChangeBank(val){
      console.log(val)
      this.bankId = ''
      this.banks = []
      this.selectionRows2.forEach(settle=>{
        if(settle.locker==0){
          settle.exchangeRate = '';
        }
      })
      getAction(this.url.curGetBank,{cur:val, useType: 1}).then((res)=>{
        if (res.result.length === 0){
          this.$message.warning("该币种下无相应账号！")
          this.bankId = ''
          this.banks = []
        }else {
          this.banks = res.result
          this.bankId = ''
        }

      })
    },
    caculateProfit(){
      getAction(this.url.cacuProfit, {orderNo: this.$route.query.orderNo})
          .then(res => {
            if (res.success) {
              this.profit = res.result.profit;
              this.receiveList = res.result.receiveList;
              this.payList = res.result.payList;
              this.preList = res.result.preList;
              this.needMulti = res.resuit.needMulti;
            }

          })
    },
    setSettlesExchangCur(){
      if (this.selectionRows2.length === 0){
        this.$message.warning("请勾选费用")
        return
      }
      if (this.exchangeCur==='' || this.exchangeCur===undefined || this.exchangeCur===null){
        this.$message.warning("请选择币种")
        return
      }
      if (this.bankId==='' || this.bankId===undefined || this.bankId===null){
        this.$message.warning("请选择银行")
        return
      }
      let billTypeCount = [];
      let isCloseAccount = [];
      this.selectionRows2.forEach(item => {
        if('1'===item.locker){
          billTypeCount.push(item.id)
        }
        // if ('1' === item.isCloseAccount) {
        //   isCloseAccount.push(item.id)
        // }
      });
      console.log('billTypeArr',Array.from(new Set(billTypeCount)))
      let billTypeArr = Array.from(new Set(billTypeCount));
      let closeAccountArr = Array.from(new Set(isCloseAccount));
      if(billTypeArr.length > 0){
        this.$message.warning("已锁定的费用不能修改！")
        return
      }
      if(closeAccountArr.length > 0){
         this.$message.warning("已关账的费用不能修改！")
         return
      }
      this.selectionRows2.forEach(settle=>{
        settle.realMoneyType = this.exchangeCur;
        settle.bankId = this.bankId;
        console.log('exchangeCur',this.exchangeCur)
        console.log('bankId',this.bankId)
        settle.isExchange = '1'
      })

      this.updateBatch();
    },
    osDocPageDownloadBill(val){
      //1 非折合   2折合
      console.log("申请收款以下id的费用",this.selectionRows2)
      if (this.selectionRows2.length === 0){
        this.$message.warning("请勾选费用")
        return
      }
      let countOrderType = [];
      this.selectionRows2.forEach(item => {
        countOrderType.push(item.orderType)
      });
      let typeArr = Array.from(new Set(countOrderType));
      console.log('typeArr->',typeArr)
      if(typeArr.length > 1){
        this.$message.warning("头程和海外仓的账单不能一起收款")
        return
      }
      let orderType = typeArr[0]

      let preArr = this.getOrderPre()
      if(preArr.length > 1){
        this.$message.warning("请选择同一账单类型的费用进行开账单！")
        return
      }
      if(preArr[0]==='KD'){
        this.$message.warning("快递单费用请选择快递账单")
        return
      }
      let countCur = [];
      this.selectionRows2.forEach(item => {
        if(item.realMoneyType===undefined||item.realMoneyType===null||item.realMoneyType===""){
          item.realMoneyType = ""
        }
        countCur.push(item.realMoneyType)
      });
      // if(val !== '1'){
      //     let curArr = Array.from(new Set(countCur));
      //     console.log('curArr->',curArr)
      //     if(curArr.length > 1){
      //         this.$message.warning("所选费用的折合币种币种不一致！")
      //         return
      //     }
      // }

      let count = [];
      this.selectionRows2.forEach(item => {
        count.push(item.settleId)
      });
      console.log(Array.from(new Set(count)))
      let arr = Array.from(new Set(count));
      if(arr.length > 1){
        this.$message.warning("所选费用的结算对象不一致！")
        return
      }

      //已锁定的和未锁定的不能一起勾选
      // let billTypeCount = [];
      // this.selectionRows2.forEach(item => {
      //     billTypeCount.push(item.locker)
      // });
      // console.log('billTypeArr',Array.from(new Set(billTypeCount)))
      // let billTypeArr = Array.from(new Set(billTypeCount));
      // if(billTypeArr.length > 1){
      //     this.$message.warning("已锁定费用和未锁定费用不能同时开账单！")
      //     return
      // }

      //判断费用是否未锁，未被锁的直接开。
      // if(billTypeArr[0] === '0'){
      //     // this.$refs.convert.edit(this.selectedRowKeys,this.selectedNos,this.selectionRows[0].realAgent)
      //     let keys = []
      //     let orderNos = []
      //     this.selectionRows2.forEach(item => {
      //         keys.push(item.id)
      //         orderNos.push(item.orderNo)
      //     });
      //     // console.log('开账单')
      //     // console.log('keys',keys)
      //     // console.log('orderNos',orderNos)
      //     if(val==='1'){
      //         this.$refs.noConvert.edit(keys,orderNos,'')
      //     }else{
      //         this.$refs.convert.edit(keys,orderNos,'')
      //     }
      //
      // }

      //如果已锁，但是没有开账单就提示需要解锁，开过账单的只能开重复
      // if(billTypeArr[0] === '1'){
      //     //如果没开过账单又锁定了 则提示去解锁
      //     let billStatusCount = [];
      //     this.selectionRows2.forEach(item => {
      //         if(item.billStatus===''||item.billStatus===undefined|| item.billStatus===null)
      //             billStatusCount.push(item.id)
      //     });
      //     console.log('billStatusArr',Array.from(new Set(billStatusCount)))
      //     let billStatusCountArr = Array.from(new Set(billStatusCount));
      //
      //     if (billStatusCountArr.length > 0) {
      //
      //         this.$message.warning("存在未开账单但已锁定的费用，请解锁后再试！");
      //
      //     } else {
      //         console.log('重复开账单')
      //         // this.$refs.convert.openAgain(this.selectedRowKeys, this.selectedNos, this.selectionRows[0].realAgent, this.selectionRows[0].realMoneyType);
      //         let keys = []
      //         let orderNos = []
      //         this.selectionRows2.forEach(item => {
      //             keys.push(item.id)
      //             orderNos.push(item.orderNo)
      //         });
      //         console.log('keys',keys)
      //         console.log('orderNos',orderNos)
      //         if(val==='1'){
      //             this.$refs.noConvert.openAgain(keys, orderNos, '', '');
      //         }else{
      //             this.$refs.convert.openAgain(keys, orderNos, '', this.selectionRows2[0].realMoneyType);
      //         }
      //
      //     }
      // }
      let keys = []
      let orderNos = []
      this.selectionRows2.forEach(item => {
        keys.push(item.id)
        orderNos.push(item.orderNo)
      });
      console.log('keys',keys)
      console.log('orderNos',orderNos)
      let nos = Array.from(new Set(orderNos)).join('-')
      // let filename = this.selectionRows2[0].settlement +'-'+ this.getDateStr()+ '折合账单' + nos

      this.$refs.multi.openMulti(keys,orderNos,this.selectionRows2[0].settlement,orderType)
      // postAction(this.url.checkBill,keys).then((res) => {
      //     //检测是同一个结算对象 才进行下载
      //     console.log('res',res)
      //     if(res.success){
      //         postAction(this.url.openBill, {ids:keys,orderNos:orderNos,billType:'9'}, {responseType: 'blob'})
      //             .then(res => {
      //                 var binaryData = [];
      //                 binaryData.push(res);
      //                 let urlencoded = window.URL.createObjectURL(new Blob(binaryData,
      //                     {"type": "application/pdf"}));
      //                 window.open("/static/pdf/web/viewer.html?filename="+filename+"&file=" + encodeURIComponent(urlencoded));
      //
      //                 //以下为开账单，按需求关闭发送账单
      //                 let file = new Blob(binaryData)
      //                 file.lastModifiedDate = new Date();
      //                 file.name = "mailAtt.pdf"
      //                 let formData = new FormData();
      //                 formData.append("file", file);
      //                 formData.append("orderNo", orderNos[0])
      //                 //发送账单邮件给客户
      //                 postAction(this.url.sendBillMail,formData).then((res)=>{
      //                     console.log(res)
      //                 })
      //
      //                 this.visible=false
      //                 setTimeout(()=>{   //设置延迟执行
      //                     this.$message.success("开账单成功！")
      //                     // this.$emit('ok')
      //                     this.handleQuery2()
      //                 },3000)
      //             })
      //
      //
      //         // this.handleQuery();
      //     }
      // })

    },
    initHTML() {
      if(this.containerType == '0' && this.solicitation !=1){
              getAction(this.url.getProfitAndCommission + "?orderNo="+ this.$route.query.orderNo).then(res => {
                  if (res.success) {
                      // TypeError: Cannot read properties of null (reading 'newProfitTotal')
                      if (res.result != null && res.result.newProfitTotal != null) {
                          this.newProfitTotal = res.result.newProfitTotal || '';
                      }
                      if (res.result != null && res.result.formatNewProfitTotal != null) {
                          this.formatNewProfitTotal = res.result.formatNewProfitTotal || '';
                      }
                      if (res.result != null && res.result.commissionTotal != null) {
                          this.commissionTotal = res.result.commissionTotal || '';
                      }
                      if (res.result != null && res.result.totalEnd != null) {
                          if(res.result.totalEnd < 10) {
                              this.isLose = '1'
                          }
                      }
                  }
              }).catch(err => {
                  this.$message.error(err)
              })
      }else {
          this.caculateProfit()
      }
    }
  },

  created() {
      console.log(this.containerType)
      //&& this.solicitation !=1
      console.log(this.containerType == '0' && this.solicitation !=1)
    this.initHTML()
    this.initBase();
  },
  computed : {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('docpage/modules/RecModule','0','新增一行'),
        'plsc':this.$btnIsShow('docpage/modules/RecModule','0','批量删除'),
        'fzddfy':this.$btnIsShow('docpage/modules/RecModule','0','复制订单费用'),
        'plsdfy':this.$btnIsShow('docpage/modules/RecModule','0','批量锁定费用'),
        'pljsfy':this.$btnIsShow('docpage/modules/RecModule','0','批量解锁费用'),
        'sdddys':this.$btnIsShow('docpage/modules/RecModule','0','锁定订单应收'),
        'jsddys':this.$btnIsShow('docpage/modules/RecModule','0','解锁订单应收'),
        'tjfsfy':this.$btnIsShow('docpage/modules/RecModule','0','添加附属费用'),
        'xzhkyzdzh':this.$btnIsShow('hyFinanceManage/modules/receive/billed','0','下载海空运账单折合'),
        'ysdrxz':this.$btnIsShow('docpage/modules/RecModule','0','海运应收批量导入模板下载'),
        'ysdr':this.$btnIsShow('docpage/modules/RecModule','0','批量海运应收导入'),
        'pldrmbxz':this.$btnIsShow('docpage/modules/CostModule','0','批量导入模板下载'),
        'pldr':this.$btnIsShow('docpage/modules/CostModule','0','批量导入'),
        'sc':this.$btnIsShow('docpage/modules/RecModule','1','删除'),
        'js':this.$btnIsShow('docpage/modules/RecModule','1','解锁'),
        'sd':this.$btnIsShow('docpage/modules/RecModule','1','锁定'),
        'tb':this.$btnIsShow('docpage/modules/RecModule','1','同步'),
        'gz':this.$btnIsShow('docpage/modules/RecModule','1','关账'),
        'jsgz':this.$btnIsShow('docpage/modules/RecModule','1','解除关账'),
        'xghl':this.$btnIsShow('docpage/modules/RecModule','1','修改汇率'),
        'bc':this.$btnIsShow('docpage/modules/RecModule','0','保存'),
        'klr':this.$btnIsShow('docpage/modules/PayModule','0','是否可以查看利润'),
        'tjfsfy1':this.$btnIsShow('docpage/modules/RecModule','0','添加附属费用1'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__content .exchange {
  text-align: center;
}

.margin_5 {
  margin: 10px 20px 5px 0;
}
.flex_wrap_between2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center_content2 {
  display: flex;
  align-items: center;
}

.centered2 {
  justify-content: center;
  width: 100%;
}
</style>
