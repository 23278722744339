<!--基本信息-->
<template>
    <div class="main-full-content">
        <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="120px"
                size="medium"
        >
            <!-- <div class="f-p-b-15"><span class="lable_title">订单信息</span></div> -->
            <el-card class="box-card">
                <div slot="header" class="clearfix flex_wrap_between">
                    <span style="font-size: 16px">订单信息</span>
                    <div>
                        <el-button
                                type="primary"
                                size="medium"
                                @click="handleorderSubmit('form')"
                        >保存基本信息
                        </el-button>
                        <el-button
                        type="text"
                        class="f-m-l-10"
                        v-if="false"
                        @click="kdRucang">
                        入仓单PDF</el-button>
                        <el-dropdown @command="cmd => handleCommand(cmd)">
                        <span class="el-dropdown-link">
                            <el-button type="text" class="f-m-l-10">无LOGO唛头<i
                                    class="el-icon-arrow-down"></i></el-button>
                        </span>
                            <el-dropdown-menu slot="dropdown">

                                <el-dropdown-item command="RM"><a>唛头标签-热敏格式</a></el-dropdown-item>
                                <el-dropdown-item command="SIX"><a>唛头标签-一页六个</a></el-dropdown-item>
                                <el-dropdown-item command="ONE"><a>唛头标签-一页一个</a></el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                    <el-button  type="text" class="f-m-l-10"  @click="rucang" v-if="buttonShowList['入仓单文档']"
                      >入仓单PDF</el-button>
                    </div>
                </div>
                <el-row class="inputs_row">
                    <el-col :span="span">
                        <el-form-item
                                label="渠道名称"
                                :label-width="formLabelWidth"
                                prop="channel"
                        >
                            <!--<f-select-->
                                    <!--:disabled="isSetChannel"-->
                                    <!--v-model="form.channel"-->
                                    <!--:is-need="form.channel"-->
                                    <!--:data-list="kdOrderChannelSetList"-->
                                    <!--@changet="getCtnCmb"-->
                            <!--&gt;</f-select>-->

                            <el-select  v-model="form.channel" filterable :disabled="isSetChannel"  @change = "getCtnCmb"  placeholder="请选择对应的渠道名称" clearable>
                                <el-option
                                        v-for="item in kdOrderChannelSetList"
                                        :key="item.itemValue"
                                        :label="item.itemText"
                                        :value="item.itemValue"
                                        :disabled="item.isEnabled == '0' ? true :false"  >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item
                                label="进口商"
                                :label-width="formLabelWidth"
                                prop="importerId"
                        >
                            <el-select
                                    v-model="form.importerId"
                                    filterable
                                    clearable
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in importerlist"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                        :disabled="item.status === '0'"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="span">
                        <el-form-item
                                label="报关方式"
                                :label-width="formLabelWidth"
                                prop="declareType"
                        >
                            <f-select
                                    :is-need="form.declareType"
                                    :dict="'kd_declare_type'"
                                    v-model="form.declareType"
                            >
                            </f-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="span">
                        <el-form-item
                                label="服务商"
                                :label-width="formLabelWidth"
                                prop="supId"
                        >
                            <el-select
                                    v-model="form.supId"
                                    filterable
                                    clearable
                                    @change="getSupplierChannel"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in allSupplierList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item
                                label="服务商渠道"
                                :label-width="formLabelWidth"
                                prop="supChannelId"
                        >
                            <el-select
                                    v-model="form.supChannelId"
                                    filterable
                                    clearable
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in supplierChannelList"
                                        :value="item.value"
                                        :label="item.label"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!--新增单号 可以多个单号绑定 服务商以及渠道-->


                    <el-col :span="span">
                        <el-form-item
                                label="shipmentId"
                                :label-width="formLabelWidth"
                                prop="shipmentId"
                        >
                            <el-input v-model="form.shipmentId"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item
                                label="referenceId"
                                :label-width="formLabelWidth"
                                prop="referenceId"
                        >
                            <el-input v-model="form.referenceId"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item
                                label="运单号"
                                :label-width="formLabelWidth"
                                prop="runOrderNo"
                        >
                            <el-input v-model="form.runOrderNo"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item
                                label="是否购买保险"
                                :label-width="formLabelWidth"
                                prop="insurance"
                        >
                            <el-radio-group v-model="form.insurance">
                                <el-radio :label="'1'">是</el-radio>
                                <el-radio :label="'0'">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="form.insurance == '1'">
                        <el-form-item
                                label="保险金额"
                                :rules="form.insurance == '1' ? rules.insuranceFee : []"
                                :label-width="formLabelWidth"
                                prop="insuranceFee"
                        >
                            <el-input v-model="form.insuranceFee" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="form.insurance == '1'">
                        <el-form-item
                                label="保险币种"
                                :rules="form.insurance == '1' ? rules.cur : []"
                                :label-width="formLabelWidth"
                                prop="insuranceCur"
                        >
                            <f-select
                                    :is-need="form.insuranceCur"
                                    v-model="form.insuranceCur"
                                    :dict="'insurance_cur'"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="form.insurance == '1'">
                        <el-form-item
                                label="被保人"
                                :label-width="formLabelWidth"
                                :rules="form.insurance == '1' ? rules.insured : []"
                                prop="insured"
                        >
                            <el-select
                                    v-model="form.insured"
                                    placeholder="请选择"
                                    clearable
                                    filterable
                                    @click.native="initInsurance"
                                    style="width: 100%"
                            >
                                <el-option
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="(item, index) in insurancelist"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item
                                label="地址类型"
                                :label-width="formLabelWidth"
                                prop="receiveAddressType"
                        >
                            <f-select
                                    :is-need="form.receiveAddressType"
                                    v-model="form.receiveAddressType"
                                    :dict="'kd_shipment_type'"
                                    @change="initShouhuoConcat"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span" v-show="this.form.receiveAddressType == 3">
                        <el-form-item
                                label="公司名称"
                                :label-width="formLabelWidth"
                                prop="receiveCompany"
                        >
                            <el-input v-model="form.receiveCompany"></el-input>

                        </el-form-item>
                    </el-col>
                    <el-col :span="span" v-if="
                this.form.receiveAddressType == 2 ||
                this.form.receiveAddressType == 3
              ">
                        <el-form-item
                                label="收货联系人"
                                :label-width="formLabelWidth"
                                prop="receiveId"
                        >
                            <el-select
                                    v-model="form.receiveId"
                                    placeholder="请选择"
                                    clearable
                                    filterable
                                    @change="getAddress"
                                    @click.native="initShouhuoConcat"
                                    style="width: 100%"
                            >
                                <el-option
                                        :label="item.label"
                                        :value="item.value"
                                        v-for="(item, index) in getShouhuoConcatList"
                                        :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span" v-if="
                this.form.receiveAddressType == 2 ||
                this.form.receiveAddressType == 3
              ">
                        <el-form-item label="收货电话" :label-width="formLabelWidth" prop="receiveTel">
                            <el-input v-model="form.receiveTel"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="span" v-if="this.form.receiveAddressType == 1">
                        <el-form-item
                                label="FBA CODE"
                                :label-width="formLabelWidth"
                                prop="receiveFbaCode"
                                v-if="this.form.receiveAddressType == 1"
                        >
                            <el-select
                                    v-model="form.receiveFbaCode"
                                    placeholder="请选择"
                                    filterable
                                    clearable
                                    @change="getFbaCodeAdd"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in fbaCodeList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="span">
                        <el-form-item
                                label="派送国家"
                                :label-width="formLabelWidth"
                                prop="receiveCountry"
                        >
                            <f-select
                                    v-model="form.receiveCountry"
                                    :dict="'bd_country'"
                                    @change="getFbaCode"
                                    :is-need="form.receiveCountry"
                            ></f-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span" v-if="this.form.receiveAddressType == 4">
                        <el-form-item
                                label="zip code"
                                :label-width="formLabelWidth"
                                prop="receiveFbaCode"
                                v-if="this.form.receiveAddressType == 4"
                        >
                            <el-select
                                    v-model="form.receiveFbaCode"
                                    placeholder="请选择"
                                    filterable
                                    clearable
                                    @change="getFbaCodeAdd"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="(item, index) in fbawarehouseList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                                label="详细地址"
                                :label-width="formLabelWidth"
                                prop="receiveAddress"
                        >
                            <el-input
                                    type="textarea"
                                    v-model="form.receiveAddress" maxlength="255" show-word-limit
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                                label="客户备注"
                                :label-width="formLabelWidth"
                                prop="cusRemark"
                        >
                            <el-input
                                    type="textarea"
                                    v-model="form.cusRemark"
                                    :disabled="true"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                                label="内部备注"
                                :label-width="formLabelWidth"
                                prop="insideRemark"
                        >
                            <el-input type="textarea" v-model="form.insideRemark" maxlength="500"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                                label="账单备注"
                                :label-width="formLabelWidth"
                                prop="billRemark"
                        >
                            <el-input type="textarea" v-model="form.billRemark" maxlength="500"
                                      show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="是否包税" prop="hasTax">
                            <el-radio-group v-model="form.hasTax">
                                <el-radio :label='"1"'>是</el-radio>
                                <el-radio :label='"0"'>否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" v-show="form.hasTax =='1'">
                        <el-form-item label="运费单价" prop="yf" :rules="form.hasTax =='1'?rules.price:[]"
                                      :label-width="formLabelWidth">
                            <el-input type="number" v-model="form.yf"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" v-show="form.hasTax =='1'">
                        <el-form-item label="附加费" prop="fjf" :rules="form.hasTax =='1'?rules.price:[]"
                                      :label-width="formLabelWidth">
                            <el-input type="number" v-model="form.fjf"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="实际计费重" :label-width="formLabelWidth" prop="actualFeeWeight">
                            <el-input type="input" v-model="form.actualFeeWeight" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="编辑计费重" :label-width="formLabelWidth" prop="updateFeeWeight">
                            <el-input type="input" v-model="form.updateFeeWeight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否已经购买保险" :label-width="formLabelWidth" prop="isInsured">
                            <el-radio-group v-model="form.isInsured">
                                <el-radio :label="'1'">是</el-radio>
                                <el-radio :label="'0'">否</el-radio>
                            </el-radio-group>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="预计件数" :label-width="formLabelWidth" prop="ctn">
                            <el-input v-model="form.ctn" type="number"></el-input>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="提单号" :label-width="formLabelWidth"
                                      :rules="form.busType === '2' ? rules.busType : []">
                            <el-input v-model="form.tidanHao" maxlength="100" show-word-limit></el-input>
                        </el-form-item>


                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="空运单号" :label-width="formLabelWidth" prop="airNo">
                            <el-input v-model="form.airNo" maxlength="100" show-word-limit></el-input>
                        </el-form-item>

                    </el-col>
                    <el-form-item label="航司" :label-width="formLabelWidth" prop="airCompanyCode">
                        <f-select
                            v-model="form.airCompanyCode"
                            :isNeed="form.airCompanyCode"
                            :dict="'air_company'"
                        ></f-select>
                    </el-form-item>
                    <el-col :span="4">
                        <el-form-item label="跟踪单号" :label-width="formLabelWidth" prop="logNo">
                            <el-input v-model="form.logNo" maxlength="100" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item
                            label="国内报关放行时间"
                            :label-width="formLabelWidth"
                            prop="shipmentReleasedTime"
                        >
                            <el-date-picker
                                class="f-flex-1"
                                v-model="form.shipmentReleasedTime"
                                type="datetime"
                                size="small"
                                placeholder="国内报关放行时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                disabled
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item
                            label="国外清关放行时间"
                            :label-width="formLabelWidth"
                            prop="shipmentClearedTime"
                        >
                            <el-date-picker
                                class="f-flex-1"
                                v-model="form.shipmentClearedTime"
                                type="datetime"
                                size="small"
                                placeholder="国外清关放行时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                                disabled
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item
                            label="国外仓库收货时间"
                            :label-width="formLabelWidth"
                            prop="shouGoodsTime"
                        >
                            <el-date-picker
                                class="f-flex-1"
                                v-model="form.shouGoodsTime"
                                type="datetime"
                                size="small"
                                placeholder="国外仓库收货时间"
                                default-time="23:59:59"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                style="width: 100%"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                      <el-col :span="4">
                        <el-form-item
                                label="自主飞"
                                :label-width="formLabelWidth"
                                prop="isAutoFly"
                        >
                            <el-radio-group v-model="form.isAutoFly">
                                <el-radio :label="'1'">是</el-radio>
                                <el-radio :label="'0'">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>


                </el-row>
            </el-card>
        </el-form>
        <el-form
                ref="good"
                :model="good"
                :rules="rules"
                label-width="120px"
                size="medium"
        >
            <el-row>
                <!-- <div class="f-p-b-15"><span class="lable_title">交货信息</span></div> -->
                <el-card class="box-card">
                    <div slot="header" class="clearfix flex_wrap_between">
                        <span style="font-size: 16px">交货信息</span>
                        <el-button
                                type="primary"
                                size="medium"
                                @click="handleGoodsSubmit('good')"
                        >保存交货信息
                        </el-button>
                    </div>
                    <el-row>
                        <el-col :span="span">
                            <el-form-item
                                    label="交货方式"
                                    :label-width="formLabelWidth"
                                    prop="deliveryType"
                            >
                                <el-radio-group v-model="good.deliveryType">
                                    <el-radio :label="'0'">客户自送货</el-radio>
                                    <el-radio :label="'1'">大森林上门提货</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="span">
                            <el-form-item
                                    label="交货仓库"
                                    :label-width="formLabelWidth"
                                    prop="deliveryWarehouse"
                            >
                                <f-select
                                        v-model="good.deliveryWarehouse"
                                        :data-list="kdOrderDeliveryPointSetList"
                                        :is-need="form.deliveryWarehouse"
                                ></f-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="span" >
                            <el-form-item
                                    :label="this.good.deliveryType=='0'?'送货时间':'提货时间'"
                                    :label-width="formLabelWidth"
                                    prop="deliveryTime"
                            >
                                <el-date-picker
                                        class="f-flex-1"
                                        v-model="good.deliveryTime"
                                        type="datetime"
                                        size="small"
                                        :placeholder="this.good.deliveryType=='0'?'送货时间':'提货时间'"
                                        default-time="23:59:59"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        format="yyyy-MM-dd HH:mm:ss"
                                        style="width: 100%"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <!--   <el-col :span="span" v-if="this.good.deliveryType=='1'">
                                       <el-form-item label="件数" :label-width="formLabelWidth" prop="ctn"
                                                     v-if="this.good.deliveryType=='1'">
                                           <el-input v-model="good.ctn" type="number"></el-input>
                                       </el-form-item>
                                   </el-col>
                                   <el-col :span="span" v-if="this.good.deliveryType=='1'">
                                       <el-form-item label="重量" :label-width="formLabelWidth" prop="kg"
                                                     v-if="this.good.deliveryType=='1'">
                                           <el-input v-model="good.kg" type="number"></el-input>
                                       </el-form-item>
                                   </el-col>
                                   <el-col :span="span" v-if="this.good.deliveryType=='1'">
                                       <el-form-item label="体积" :label-width="formLabelWidth" prop="cmb"
                                                     v-if="this.good.deliveryType=='1'">
                                           <el-input v-model="good.cmb" type="number"></el-input>
                                       </el-form-item>
                                   </el-col>-->
                    </el-row>

                    <el-row>
                        <el-col :span="12" v-if="this.good.deliveryType == '0'">
                            <el-form-item
                                    label="自送货备注"
                                    :label-width="formLabelWidth"
                                    prop="deliveryRemrak"
                                    v-if="this.good.deliveryType == '0'"
                            >
                                <el-input
                                        type="textarea"
                                        v-model="good.deliveryRemrak"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-card>
            </el-row>
        </el-form>
        <el-dialog
                :title="tihuo.id ? '编辑提货' : '新增提货'"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                v-if="dialogFormVisible"
        >
            <el-form
                    ref="tihuo"
                    :model="tihuo"
                    :rules="rules"
                    :validate-on-rule-change="false"
            >
                <el-form-item
                        label="提货联系人"
                        :label-width="formLabelWidth"
                        prop="contact"
                >
                    <el-select
                            v-model="tihuo.contact"
                            placeholder="请选择"
                            filterable
                            clearable
                            @change="getTelAddress"
                            style="width: 100%"
                    >
                        <el-option
                                v-for="(item, index) in cusThSupplierList"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                        label="电话"
                        :label-width="formLabelWidth"
                        prop="contactTel"
                >
                    <el-input v-model="tihuo.contactTel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="提货地址"
                        :label-width="formLabelWidth"
                        prop="contactAdd"
                >
                    <el-input v-model="tihuo.contactAdd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item
                        label="提货时间"
                        :label-width="formLabelWidth"
                        prop="getsTime"
                >
                    <el-date-picker
                            class="f-flex-1"
                            v-model="tihuo.getsTime"
                            type="datetime"
                            size="small"
                            placeholder="提货时间"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="件数" :label-width="formLabelWidth" prop="ctn">
                    <el-input
                            v-model="tihuo.ctn"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="重量" :label-width="formLabelWidth" prop="kg">
                    <el-input
                            v-model="tihuo.kg"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="体积" :label-width="formLabelWidth" prop="cmb">
                    <el-input
                            v-model="tihuo.cmb"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="是否带尾板车"
                        :label-width="formLabelWidth"
                        prop="isWeiban"
                >
                    <el-radio-group v-model="tihuo.isWeiban">
                        <el-radio :label="'1'">是</el-radio>
                        <el-radio :label="'0'">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                        label="尾板车费用"
                        :label-width="formLabelWidth"
                        prop="weibanFee"
                        v-if="tihuo.isWeiban == '1'"
                >
                    <el-input
                            v-model="tihuo.weibanFee"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="是否需要搬运"
                        :label-width="formLabelWidth"
                        prop="isBanyun"
                >
                    <el-radio-group v-model="tihuo.isBanyun">
                        <el-radio :label="'1'">是</el-radio>
                        <el-radio :label="'0'">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                        label="搬运费用"
                        :label-width="formLabelWidth"
                        prop="banyunFee"
                        v-if="tihuo.isBanyun == '1'"
                >
                    <el-input
                            v-model="tihuo.banyunFee"
                            autocomplete="off"
                            type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                    <el-input v-model="tihuo.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogFormVisible = false"
                >取 消
                </el-button
                >
                <el-button size="medium" type="primary" @click="handleSave"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
        <div v-show="this.good.deliveryType == '1'">
            <el-card class="box-card last_card">
                <div slot="header" class="clearfix flex_wrap_between">
                    <span style="font-size: 16px">提货信息</span>
                    <div>
                        <el-button type="primary" size="medium" @click="saveTihuo()"
                        >新增提货
                        </el-button>
                        <el-button
                                type="primary"
                                size="medium"
                                @click="handleSubmitTihuo('tihuo')"
                                :disabled="selectionRows.length === 0"
                        >发起提货申请
                        </el-button>
                    </div>
                </div>
                <f-table
                        v-loading="loading"
                        :cell-class-name="checkboxClassName"
                        ref="GTable"
                        border
                        size="medium"
                        row-key="id"
                        class="c-th-has-bgcolor"
                        :columns="columns"
                        :data="tableData"
                        :cell-style="{ 'text-align': 'center' }"
                        @selection-change="handleSelectionChange"
                        @sort-change="handleTableChange"
                >
                    <template v-slot="scope">
                        <template v-if="scope.column.property === 'action'">
                            <el-button
                                    type="text"
                                    @click="editTihuo(scope.row)"
                                    :disabled="scope.row.thStatus != '0'"
                            >编辑
                            </el-button>
                            <el-button
                                    type="text"
                                    @click="deleteTihuo(scope.row)"
                                    :disabled="scope.row.thStatus != '0'"
                            >删除
                            </el-button>
                        </template>
                        <template v-else-if="scope.column.property === 'isWeiban'">
                            <span>{{ parseDict(scope.row.isWeiban, "is_default") }}</span>
                        </template>
                        <template v-else-if="scope.column.property === 'isBanyun'">
                            <span>{{ parseDict(scope.row.isBanyun, "is_default") }}</span>
                        </template>
                    </template>
                </f-table>
            </el-card>
        </div>
        <product-module :order="order" @getOrderData="getOrderData"></product-module>
    </div>
</template>

<script>
import QueryMixins from "../../../mixins/QueryMixins";
import {getAction, getAllSupplier, postAction, putAction} from "@/api/manage";
import {mobilePattern} from "@/utils/pattern";
import {zhengshuPattern, zhengshuxiaoshuPartten,} from "../../../utils/pattern";
import {validateCmb} from "../../../utils/validator";
import ProductModule from "./ProductModule";

export default {
    name: "Potential",
    mixins: [QueryMixins],
    components: {
        ProductModule,
    },
    props: {
        span: {
            type: Number,
            default: 6,
        },
        order: {
            type: Object,
            default: () => {
            },
        },
        declareType:{
            // type: ,
            default: ''
        }
    },
    data() {

        const feeValidator = (rule, value, callback) => {
            console.log("校验保险", this.form.insuranceCur, this.form.insuranceFee);
            // value.toString().trim() === "" ||
            if (this.form.insuranceFee == null) {
                callback(new Error("请输入保额"));
            } else if (!this.form.insuranceCur) {
                callback(new Error("请选择币种"));
            } else if (this.form.insuranceCur == "CNY" && value < 100) {
                callback(new Error("最小100CNY"));
            } else if (this.form.insuranceCur == "USD" && value < 20) {
                callback(new Error("最小20USD"));
            }
            callback();
        };
        return {
            //是否可以编辑渠道名称的权限
            isSetChannel: true,
            // 当前时间之前的日期置灰
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            dictCodes: ["is_default"],
            rules: {
                channel: [
                    {required: true, message: "请选择渠道名称", trigger: "change"},
                ],
                importerId: [
                    {required: true, message: "请选择进口商", trigger: "change"},
                ],
                declareType: [
                    {required: true, message: "请选择报关类型", trigger: "change"},
                ],
                insurance: [
                    {required: true, message: "请选择是否购买保险", trigger: "change"},
                ],
                insuranceFee: [{validator: feeValidator, trigger: "blur"}],
                insuranceCur: [
                    {required: true, message: "请选择保险币种", trigger: "change"},
                ],
                insured: [
                    {required: true, message: "请选择被保人", trigger: "change"},
                ],
                receiveAddressType: [
                    {required: true, message: "请选择地址类型", trigger: "change"},
                ],
                receiveId: [
                    {required: true, message: "请选择收货联系人", trigger: "change"},
                ],
                receiveCountry: [
                    {required: true, message: "请选择国家", trigger: "change"},
                ],
                deliveryType: [
                    {required: true, message: "请选择交货方式", trigger: "change"},
                ],
                deliveryWarehouse: [
                    {required: true, message: "请选择交货仓库", trigger: "change"},
                ],
                deliveryTime: [
                    {required: true, message: "请选择送货时间", trigger: "change"},
                ],
                ctn: [
                    {required: true, message: "请输入件数", trigger: "change"},
                    {
                        pattern: zhengshuPattern,
                        message: "请输入正整数",
                        trigger: "change",
                    },
                ],
                kg: [
                    {required: true, message: "请输入重量", trigger: "change"},
                    {
                        pattern: zhengshuxiaoshuPartten,
                        message: "请输入正整数或最多三位小数",
                        trigger: "change",
                    },
                ],
                cmb: [
                    {required: true, message: "请输入体积", trigger: "change"},
                    {
                        pattern: zhengshuxiaoshuPartten,
                        message: "请输入正整数或最多三位小数",
                        trigger: "change",
                    },
                ],
                receiveCompany: [
                {required: true, message: "请输入公司名称", trigger: "change"},
                ],
                contact: [
                    {required: true, message: "请输入提货联系人", trigger: "change"},
                ],
                getsTime: [
                    {required: true, message: "请选择提货时间", trigger: "change"},
                ],
                isWeiban: [
                    {required: true, message: "请选择是否带尾班车", trigger: "change"},
                ],
                weibanFee: [
                    {required: true, message: "请输入尾班车费用", trigger: "change"},
                    {
                        pattern: zhengshuxiaoshuPartten,
                        message: "请输入正整数或最多三位小数",
                        trigger: "change",
                    },
                ],
                isBanyun: [
                    {required: true, message: "请选择是否需要搬运", trigger: "change"},
                ],
                banyunFee: [
                    {required: true, message: "请输入搬运费用", trigger: "change"},
                    {
                        pattern: zhengshuxiaoshuPartten,
                        message: "请输入正整数或最多三位小数",
                        trigger: "change",
                    },
                ],
                receiveFbaCode: [
                    {required: true, message: "请选择FBA CODE", trigger: "change"},
                ],
                contactAdd: [
                    {required: true, message: "请输入提货地址", trigger: "change"},
                    {max: 255, message: "长度不能超过255", trigger: "change"},
                ],
                contactTel: [
                    {required: true, message: "请输入电话", trigger: "change"},
                    // {
                    //     pattern: mobilePattern,
                    //     message: "请输入正确的手机号",
                    //     trigger: "change",
                    // },
                ],
                remark: [{max: 255, message: "长度不能超过255", trigger: "change"}],
                hasTax: [
                    {required: true, message: '请选择是否包税', trigger: 'change'},
                ],
                price: [
                    {validator: validateCmb, trigger: 'change'},
                ],
                receiveTel: [{max: 50, message: "长度不能超过50", trigger: "change"}],
            },
            ids: [],
            row: {},
            form: {}, //订单信息
            good: {}, //交货信息
            tihuo: {
                isWeiban: "0",
                isBanyun: "1",
            }, //提货信息
            formLabelWidth: "110px",
            //控制弹框显示
            dialogFormVisible: false,
            columns: [
                {
                    type: "selection",
                    width: 50,
                },
                {
                    prop: "action",
                    label: "操作",
                    width: 95,
                },
                {
                    prop: "contact",
                    label: "提货联系人",
                    sortable: "custom",
                },
                {
                    prop: "contactAdd",
                    label: "提货地址",
                    sortable: "custom",
                },
                {
                    prop: "contactTel",
                    label: "电话",
                    sortable: "custom",
                },
                {
                    prop: "getsTime",
                    label: "提货时间",
                    sortable: "custom",
                },
                {
                    prop: "ctn",
                    label: "件数",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "kg",
                    label: "重量",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "cmb",
                    label: "体积",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "isWeiban",
                    label: "是否带尾板车",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "weibanFee",
                    label: "尾板车费用",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "isBanyun",
                    label: "是否需要搬运",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "banyunFee",
                    label: "搬运费用",
                    sortable: "custom",
                    width: 70,
                },
                {
                    prop: "remark",
                    label: "提货备注",
                    sortable: "custom",
                },
            ],
            kdOrderChannelSetList: [],
            url: {
                getKdOrderChannelSelectList: '/order/KdOrderChannel/getChannelSetSelectList',
                getDeliveryPointSelectList: '/order/KdOrderChannel/getDeliveryPointSelectList',
                getImporterList: "/customer/importer/getList", //获取客户的进口商
                getSysImporterList: "/sys/importer/getAll", //获取系统进口商
                queryBysupId: "/sys/supplierChannel/queryBysupId", // 根据供应商ID 获取供应商渠道
                getInsuranceList: "/customer/insurance/getList", //获取客户的被保人
                getCusReceivingAddressByCusId: "/customer/receivingAddress/getByCusId", //获取客户的收货地址
                queryFbaByCountry: "/sys/fbacode/queryByCountry", //根据国家获取FBACODE
                queryFbaWareByCountry: "/sys/fbacode/queryFbaWareByCountry", //根据国家获取FBACODE
                edit: "/order/kdOrder/edit", //快递订单编辑
                addKdYihuo: "/order/os/trailer/addKdYihuo", //新增快递提货单
                list: "/order/os/trailer/list", //查询快递提货单
                editKdYihuo: "/order/os/trailer/edit", //编辑快递提货单
                deleteKdYihuo: "/order/os/trailer/delete", //编辑快递提货单
                sendTihuo: "/order/os/trailer/sendTihuo", //发起提货申请
                queryByOrderNo: "/order/kdOrder/queryByOrderNo",
                getDictItems: "/sys/dict/getDictItems/", //根据字典编码key获取值
                getCusThSupplier: "/customer/supplier/getList/", //查询当前客户的提货供应商
                markdown: '/file/jasper/downloadKdSixMark',
                downloadKdRucang: "/file/jasper/download/kdRucang",
                markOne: '/file/jasper/downloadKdOneMark',
                markth: '/file/jasper/downloadKdTwoMark',
                getCountryByNum: "/sys/bdCountry/getCountryByNum", //获取国家
                ctnCmbList: '/order/KdOrderCtnCmb/list',  //获取材积列表
                queryById: "/order/KdOrderChannel/queryById", //获取渠道
                ctnCmbEdit: "/order/KdOrderCtnCmb/edit",  //材积信息每行编辑
                sureCtnCmb: "/order/KdOrderCtnCmb/updateSureCtnCmb", //确认材积
                downloadRucang:"/file/jasper/download/kdRucang"
            },
            allSupplierList: [], //快递供应商
            supplierChannelList: [], //快递供应商的渠道
            importerlist: [], //进口商
            insurancelist: [], //被保人
            getShouhuoConcatList: [], //客户的收货联系人
            recMap: {}, //客户的收货地址
            fbaCodeList: [], //fba code
            fbawarehouseList: [], //海外仓 code
            fbawarehouseMap: [], //海外仓 code
            fbaCodeMap: {}, //fba code
            cusThSupplierList: [], //客户的提货供应商
            cusThSupplierMap: {},
            kdChannel: {}, //快递渠道
            shijifeeWeight: 0, // 材积实际计费重
            shijicmbWeight: 0, // 材积实际体积重
            shijiWeight: 0, // 材积实重

            lowShijiWeight: 0,    //算上最低重的 实重
            lowShijicmbWeight: 0, // 算上最低重的 体积重
            kdOrderDeliveryPointSetList: [],
        };

    },
    methods: {
        getKdOrderChannelSelectList() {
            getAction(this.url.getKdOrderChannelSelectList, {val: this.$route.query.orderNo})
                .then(res => {
                    this.kdOrderChannelSetList = res.result;
                })
        },
        getOrderData() {
            this.$emit('getOrderData');
        },
        kdRucang() {
            let fileName = "快递" + this.form.orderNo + "入仓单-" +  this.form.shipmentId
            let params = { orderNo: this.form.orderNo, t:'pdf' };
            this.preview(this.url.downloadKdRucang, params, fileName);
         },
        handleCommand(cmd) {
            let params = {orderNo: this.$route.query.orderNo};
            if (cmd === 'RM') {
                this.preview(this.url.markth, params, params.orderNo + "-快递热敏唛头");
            } else if (cmd === 'SIX') {
                this.preview(this.url.markdown, params, params.orderNo + "-快递唛头标签-一页六个");
            } else if (cmd === 'ONE') {
                this.preview(this.url.markOne, params, params.orderNo + "-快递唛头标签-一页一个");
            }
            // console.log('父亲，交给你了',this.$emit('ok'));
        },
        checkboxClassName({row, column, rowIndex, columnIndex}) {
            if (row.thStatus !== "0") {
                return "tb_cell";
            }
        },
        //根据收货联系人匹配详细地址
        getAddress(val) {
            if (this.recMap[val]) {
                this.form.receiveAddress = this.recMap[val]["address"];
                this.form.receiveId = this.recMap[val]["id"];
                this.form.zipCode = this.recMap[val]["zipcode"];
                this.form.receiveTel = this.recMap[val]["tel"];
            }
        },
        async getFbaCodeAdd(val) {

            if (val && this.form.receiveAddressType === '1') {
                /*   this.$set(this.form, "receiveAddress", val.split("-")[1]);
                   this.$set(this.form, "zipCode", val.split("-")[2]);*/
                let fbaAdd = this.fbaCodeMap[val];
                let towCode = '';
                //根据 country_num  获取国家二字码
                await getAction(`${this.url.getCountryByNum}?countryNum=${fbaAdd["countryNum"]}`)
                    .then((res) => {
                        if (res.success) {
                            towCode = res.result.twoCode;
                        }
                    });
                this.$set(this.form, "receiveAddress", 'Amazon.com ' + fbaAdd["address"] + ' ' + fbaAdd["address2"] + ' ' + fbaAdd["city"] + ' ' + fbaAdd["state"]
                    + ' ' + fbaAdd["zip"] + towCode + ' ( ' + fbaAdd["fbaCode"] + ' )');
                this.$set(this.form, "zipCode", fbaAdd["zip"]);
            }
            if (val && this.form.receiveAddressType === '4') {
                /*   this.$set(this.form, "receiveAddress", val.split("-")[1]);
                   this.$set(this.form, "zipCode", val.split("-")[2]);*/
                let fbaAdd = this.fbawarehouseMap[val];
                let towCode = '';
                //根据 country_num  获取国家二字码
                await getAction(`${this.url.getCountryByNum}?countryNum=${fbaAdd["country"]}`)
                    .then((res) => {
                        if (res.success) {
                            towCode = res.result.twoCode;
                        }
                    });
                this.$set(this.form, "receiveAddress", fbaAdd["company"] + ', ' + fbaAdd["address"] + ', ' + fbaAdd['zipcode']
                    + towCode + ',CONTACT：' + fbaAdd["contact"] + ',TEL:' + fbaAdd["tel"]);
                this.$set(this.form, "zipCode", fbaAdd["zip"]);
            }

        },

        //获取客户的进口商
        initImporter() {
            this.importerlist = [];
            getAction(`${this.url.getImporterList}?cusId=${this.form.cusId}&val=${this.form.importerId}`)
                .then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            let value = list[i].id;
                            // let label = list[i].name + "--客户进口商";
                            let vat = list[i].vat ? list[i].vat + ' ' : '';
                            let country = list[i].country ? ' (' + list[i].country + ') ' + ' ' : '';
                            let label = list[i].name + country + vat + "--客户进口商";
                            let status = list[i].status;
                            this.importerlist.push({value, label, status});
                        }
                        this.importerlist.push({value: '1', label: '大森林进口商'});
                    } else {
                        this.$message.warning("获取客户进口商列表失败");
                    }
                })
            //获取系统进口商
            getAction(this.url.getSysImporterList, {val: this.form.importerId})
                .then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            let value = list[i].id;
                            // let label = list[i].name + "--大森林进口商";
                            let vat = list[i].vat ? list[i].vat + ' ' : '';
                            let country = list[i].country ? ' (' + list[i].country + ') ' + ' ' : '';
                            let label = list[i].name + country + vat + "--大森林进口商";
                            this.importerlist.push({value, label});
                        }
                    } else {
                        this.$message.warning("获取大森林进口商列表失败");
                    }
                })
        },
        cellClass(row) {
            if (row.columnIndex === 0 && row.row.orderStatus != "0") {
                // 根据某个属性，添加样式，隐藏复选框
                console.log("这一行", row);
                return "myCell";
            }
        },
        //获取所有的 快递供应商
        initAllSupplier() {
            this.allSupplierList = [];
            getAllSupplier("2")
                .then((res) => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].id; //供应商ID
                            obj.label = res.result[i].chinaseName; //供应商中文名
                            this.allSupplierList.push(obj);
                            console.log("allSupplierList", this.allSupplierList);
                        }
                    }
                })
                .catch((err) => {
                    this.$message.error(err);
                });
        },
        //获取供应商的渠道
        getSupplierChannel(code) {
            this.supplierChannelList = [];
            getAction(this.url.queryBysupId + "?supId=" + code + "&val=" + this.form.supChannelId).then((res) => {
                if (res.success) {
                    for (var i = 0; i < res.result.length; i++) {
                        var obj = {};
                        obj.value = res.result[i].id; //供应商ID
                        obj.label = res.result[i].channelName; //供应商中文名
                        this.supplierChannelList.push(obj);
                    }
                }
            });
        },
        //根据国家获取FBACODE
        getFbaCode(val) {
            this.fbaCodeList = [];
            this.fbawarehouseList = [];
            this.fbaCodeMap = {};
            this.fbawarehouseMap = {};
            if (this.form.receiveAddressType === '1') {
                /* console.log("fbaCodeList", this.fbaCodeList);*/
                /* this.$set(this.form, "receiveAddress", "");*/
                /*          this.$set(this.form, "receiveFbaCode", "");*/
                getAction(this.url.queryFbaByCountry + "?country=" + val).then((res) => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].fbaCode;
                            obj.label = res.result[i].fbaCode;
                            res.result.index = i;
                            this.fbaCodeList.push(obj);
                        }
                        this.fbaCodeMap = this.listToMap(res.result, 'fbaCode');
                    }
                });
            }
            if (this.form.receiveAddressType === '4') {
                /* console.log("fbaCodeList", this.fbaCodeList);*/
                /* this.$set(this.form, "receiveAddress", "");*/
                /*          this.$set(this.form, "receiveFbaCode", "");*/
                getAction(this.url.queryFbaWareByCountry + "?country=" + val).then((res) => {
                    if (res.success) {
                        for (var i = 0; i < res.result.length; i++) {
                            var obj = {};
                            obj.value = res.result[i].zipcode;
                            obj.label = res.result[i].zipcode;
                            res.result.index = i;
                            this.fbawarehouseList.push(obj);
                        }
                        this.fbawarehouseMap = this.listToMap(res.result, 'zipcode');
                    }
                });
            }

        },
        //保存订单基本信息
        handleorderSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if(!this.declareType) {
                        this.$message.error('请选择报关方式');
                        return;
                    }
                  this.form.declareType=this.declareType;
                    let obj = this.getObjByValue(this.importerlist, this.form.importerId);
                    if (obj) {
                        console.log("进口商的label", obj.label);
                        if (obj.label && obj.label.indexOf("--") != -1) {
                            if (obj.label.split("--")[1].indexOf("客户") != -1) {
                                this.form.importerType = "0";
                            } else {
                                this.form.importerType = "1";
                            }
                            this.form.importer = obj.label.split("--")[0];
                        } else {
                            if (obj.label && obj.label.indexOf("大森林") != -1) {
                                this.form.importerType = "1";
                                this.form.importer = obj.label;
                            }
                        }
                    }
                    this.form.receiveFbaCode = this.form.receiveFbaCode.split("-")[0];
                    console.log("选择的fbacode", this.form.receiveFbaCode);
                    putAction(this.url.edit, this.form).then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            getAction(
                                this.url.queryByOrderNo + "?orderNo=" + this.form.orderNo
                            ).then((res) => {
                                //更新父组件的order
                                this.$emit("update", res.result);
                            });
                        }
                    });
                }
            });
        },
        //保存交货信息
        handleGoodsSubmit() {
            this.$refs.good.validate((valid) => {
                if (valid) {
                    this.good.id = this.form.id;
                    putAction(this.url.edit, this.good).then((res) => {
                        if (res.success) {
                            this.$message.success(res.message);
                            //再次查询
                            getAction(
                                this.url.queryByOrderNo + "?orderNo=" + this.form.orderNo
                            ).then((res) => {
                                //更新父组件的order
                                this.$emit("update", res.result);
                            });
                        }
                    });
                }
            });
        },
        //获取客户的被保人
        initInsurance() {
            if (this.insurancelist.length >= 1) {
                return;
            } else {
                //获取发货人列表
                getAction(`${this.url.getInsuranceList}?cusId=${this.form.cusId}`)
                    .then((res) => {
                        if (res.success) {
                            let list = res.result;
                            for (var i = 0; i < list.length; i++) {
                                let value = list[i].name;
                                let label = list[i].name;
                                this.insurancelist.push({value, label});
                            }
                        } else {
                            this.$message.warning("获取被保人列表失败");
                        }
                    })
            }
        },
        //获取客户的收货联系人
        initShouhuoConcat() {
            this.getShouhuoConcatList = [];
            // 清除公司名称必填规则
            this.rules["receiveCompany"]  = [];
            this.recMap = {};
            let addressType = '';
            if (this.form.receiveAddressType === '2') {
                addressType = '0'  //非商业地址
            }
            if (this.form.receiveAddressType === '3') {
                addressType = '1'  //是商业地址
                this.rules["receiveCompany"] = [
                    {required: true, message: "请输入公司名称", trigger: "change"},
                ]
            }else{
                this.form.receiveCompany =''
            }
            getAction(`${this.url.getCusReceivingAddressByCusId}?cusId=${this.form.cusId}` + '&addressType=' + addressType)
                .then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            let value = list[i].id;
                            let label = list[i].contact;
                            this.getShouhuoConcatList.push({value, label});
                        }
                        this.recMap = this.listToMap(res.result, "contact");
                        if (this.form.receiveName) {
                            if (!this.getShouhuoConcatList.find(ss => {
                                return ss.value === this.form.receiveId;
                            })) {
                                this.$set(this.form, 'receiveName', '');
                            }
                        }
                    } else {
                        this.$message.warning("获取客户的收货联系人列表失败");
                    }
                })
        },
        //新增提货
        saveTihuo() {
            this.tihuo = {
                isWeiban: this.form.isWeiban,
                isBanyun: this.form.isBanyun,
            };
            //初始化尾板车和搬运的费用
            //根据数据字典key获取value
            getAction(this.url.getDictItems + "/base_fee")
                .then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            if (list[i].text.indexOf("尾板车") !== -1) {
                                this.$set(this.tihuo, "weibanFee", list[i].value);
                            }
                            if (list[i].text.indexOf("搬运") !== -1) {
                                this.$set(this.tihuo, "banyunFee", list[i].value);
                            }
                        }
                    }
                })
            this.getTihuoConcat();
            this.dialogFormVisible = true;
        },
        //获取该客户的提货联系人
        getTihuoConcat() {
            this.cusThSupplierList = [];
            this.cusThSupplierMap = {};
            //初始化该客户的提货联系人等信息
            getAction(this.url.getCusThSupplier + "?cusId=" + this.form.cusId)
                .then((res) => {
                    if (res.success) {
                        let list = res.result;
                        for (var i = 0; i < list.length; i++) {
                            let value = list[i].contact;
                            let label = list[i].contact;
                            this.cusThSupplierList.push({value, label});
                        }
                        this.cusThSupplierMap = this.listToMap(res.result, "contact");
                    }
                })
        },
        //根据提货联系人获取手机号地址
        getTelAddress(val) {
            if (this.cusThSupplierMap[val]) {
                this.$set(this.tihuo, "contactTel", this.cusThSupplierMap[val]["tel"]);
                this.$set(
                    this.tihuo,
                    "contactAdd",
                    this.cusThSupplierMap[val]["address"]
                );
            }
        },
        //编辑提货
        editTihuo(row) {
            console.log("scope", row);
            this.tihuo = JSON.parse(JSON.stringify(row));
            this.getTihuoConcat();
            this.dialogFormVisible = true;
        },
        //保存提货
        handleSave() {
            this.$refs.tihuo.validate((valid) => {
                if (valid) {
                    if (this.tihuo.isBanyun === "0") {
                        this.tihuo.banyunFee = "";
                    }
                    if (this.tihuo.isWeiban === "0") {
                        this.tihuo.weibanFee = "";
                    }
                    this.tihuo.orderNo = this.form.orderNo;
                    this.tihuo.doc = this.form.doc;
                    this.tihuo.docId = this.form.docId;
                    this.tihuo.lclPlace = this.form.deliveryWarehouse;
                    this.tihuo.orderType = "KD";
                    if (this.tihuo.id) {
                        putAction(this.url.editKdYihuo, this.tihuo).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                this.handleQuery();
                            }
                        });
                    } else {
                        postAction(this.url.addKdYihuo, this.tihuo).then((res) => {
                            if (res.success) {
                                this.$message.success(res.message);
                                //关闭弹窗
                                this.dialogFormVisible = false;
                                this.handleQuery();
                            }
                        });
                    }
                }
            });
        },
        //删除提货信息
        deleteTihuo(row) {
            this.$confirm(`确定删除`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                closeOnClickModal: false,
            })
                .then(() => {
                    putAction(this.url.deleteKdYihuo + "/" + row.id).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        this.handleQuery();
                    });
                })
                .catch(() => {
                });
        },
        handleSubmitTihuo() {
            putAction(this.url.sendTihuo + "?ids=" + this.selectedRowKeys.join(","))
                .then((res) => {
                    if (res.success) {
                        this.$message.success(res.message);
                        this.handleQuery();
                    }
                })
        },
        initKdChannelSet() {
            // let list = ['张久留','高蒙蒙','徐芳','王圣杰','吴翔','管理员'];
            // let realname = JSON.parse(localStorage.getItem('USERINFO'))['realname'];
            let flag = this.buttonShowList.add;
            if (flag) {
                this.isSetChannel = false;
            } else {
                this.isSetChannel = true;
            }
        },
        // 重新计算材积
        getCtnCmb() {
            if(this.order.channel){
                return;
            }
            if (this.order.channel == this.form.channel) {
                return
            }
            getAction(this.url.queryById + '?id=' + this.form.channel).then((res) => {
                if (res.success) {
                    this.kdChannel = res.result;
                    this.$set(this.form, 'hasTax', this.kdChannel.tax);
                    this.$set(this.form, 'busType', this.kdChannel.busType);
                    getAction(this.url.ctnCmbList, {orderNo: this.form.orderNo})
                        .then((res) => {
                            if (res.success) {
                                const {records} = res.result;
                                this.form.volumeList = records || res.result;
                                //先计算总实重（加最低） ，总体积重（加最低）
                                if (this.kdChannel.feeType === "1") {
                                    this.getLowWeight();
                                }
                                this.form.volumeList.forEach((item, index) => {
                                    this.jiSuan2(item);
                                })

                                this.getSummaries();
                            }
                        })
                }
            })
        },
        // 根据渠道信息重新计算材积
        jiSuan2(row) {
            console.log("row", row);
            if (
                !row.actualLong ||
                !row.actualWide ||
                !row.actualKg ||
                !row.actualHigh
            ) {
                row.feeWeight = "";
                row.cmbWeight = "";
            } else {
                let cheng = (row.actualLong * row.actualWide * row.actualHigh) /
                    this.kdChannel.cmbBase;
                //体积重
                this.$set(row, "cmbWeight", cheng.toFixed(3));
                if (this.kdChannel.feeType === "1") {
                    if (Number(this.lowShijiWeight) > Number(this.lowShijicmbWeight)) {
                        if (Number(row.actualKg) < Number(this.kdChannel.minFeeKg)) {  //实重小于最低计费重
                            this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                        } else {
                            this.$set(row, "feeWeight", row.actualKg); // 用实重
                        }

                    } else {
                        if (Number(row.cmbWeight) < Number(this.kdChannel.minFeeKg)) {  //实重小于最低计费重
                            this.$set(row, "feeWeight", this.kdChannel.minFeeKg);
                        } else {
                            this.$set(row, "feeWeight", row.cmbWeight); // 用体积重
                        }
                    }
                }
                if (this.kdChannel.feeType === "0") {
                    //单票
                    if (Number(cheng) < Number(this.kdChannel.minFeeKg) && Number(row.actualKg) < Number(this.kdChannel.minFeeKg)
                    ) {
                        //判断最低标准是否超重，超重则按照进1方式计算
                        if (Number(this.kdChannel.minFeeKg) > Number(this.kdChannel.overweight)) {
                            this.$set(row, "feeWeight", this.overcountJin(this.kdChannel.minFeeKg));
                        } else {
                            this.$set(row, "feeWeight", this.countJin(this.kdChannel.minFeeKg));
                        }
                    } else if (Number(row.actualKg) > Number(row.cmbWeight)) {
                        if (Number(row.actualKg) > Number(this.kdChannel.overweight)) {
                            this.$set(row, "feeWeight", this.overcountJin(row.actualKg));
                        } else {
                            this.$set(row, "feeWeight", this.countJin(row.actualKg));
                        }
                    } else if (Number(row.cmbWeight) > Number(row.actualKg)) {
                        if (row.cmbWeight > this.kdChannel.overweight) {
                            this.$set(row, "feeWeight", this.overcountJin(row.cmbWeight));
                        } else {
                            this.$set(row, "feeWeight", this.countJin(row.cmbWeight));
                        }
                    } else {
                        if (row.actualKg > this.kdChannel.overweight) {
                            this.$set(row, "feeWeight", this.overcountJin(row.actualKg));
                        } else {
                            this.$set(row, "feeWeight", this.countJin(row.actualKg));
                        }
                    }
                }
            }
        },
        //未超重
        countJin(val) {
            let zheng = parseInt(val);
            let xiao = val - zheng;
            if (0 == xiao) {
                return zheng;
            }
            console.log(xiao);
            if (this.kdChannel.feeMode == 0.5) {
                //进0.5
                if (xiao < 0.5) {
                    return zheng + 0.5;
                } else if (xiao == 0.5) {
                    return val;
                } else {
                    return zheng + 1;
                }
            }
            if (this.kdChannel.feeMode == 1) {
                //进1
                return zheng + 1;
            }
        }
        ,
        //超重进1
        overcountJin(val) {
            console.log(val);
            let zheng = parseInt(val);
            console.log(zheng);
            let xiao = val - zheng;
            if (0 === xiao) {
                return zheng;
            }
            return zheng + 1;
        },
        //保存材积信息总数据
        async sureInvoice() {
            //确认材积 实重，体积重，以及计费重
            await getAction(this.url.sureCtnCmb + "?orderNo=" + this.order.orderNo +
                "&feeWeight=" + this.shijifeeWeight +
                "&cmbWeight=" + this.shijicmbWeight)
                .then((res) => {
                    if (res.success) {
                        //再次查询
                        getAction(this.url.queryByOrderNo + "?orderNo=" + this.form.orderNo
                        ).then((res) => {
                            //更新父组件的order
                            this.$emit("update", res.result);
                        });
                    }
                })


        },
        // 计算材积总数
        getSummaries() {
            const valuesKg = this.form.volumeList.map((item) => Number(item['actualKg']));
            const valuesCmb = this.form.volumeList.map((item) => Number(item['cmbWeight']));
            const valuesFee = this.form.volumeList.map((item) => Number(item['feeWeight']));
            let sumsKg = valuesKg.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                    return prev + curr;
                } else {
                    return prev;
                }
            }, 0);
            let sumsCmb = valuesCmb.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                    return prev + curr;
                } else {
                    return prev;
                }
            }, 0);
            let sumsFee = valuesFee.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                    return prev + curr;
                } else {
                    return prev;
                }
            }, 0);
            this.shijiWeight = sumsKg;
            this.shijicmbWeight = this.countJin(sumsCmb);
            this.shijifeeWeight = this.overcountJin(sumsFee);
        },
        // 计算 加上最低的实重和 体积重的值（不满最低按最低）
        getLowWeight() {
            const valuesKg = this.form.volumeList.map((item) => Number(item['actualKg']));
            const valuesCmb = this.form.volumeList.map((item) => Number(item['cmbWeight']));

            let sumsKg = valuesKg.reduce((prev, curr) => {
                //整票
                if (this.kdChannel.feeType === "1") {
                    if (Number(curr) > 0) {
                        if (Number(curr) < this.kdChannel.minFeeKg) {
                            curr = this.kdChannel.minFeeKg;
                        }
                        return prev + curr;
                    } else {
                        return prev;
                    }
                } else {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }
            }, 0);
            let sumsCmb = valuesCmb.reduce((prev, curr) => {
                //整票
                if (this.kdChannel.feeType === "1") {
                    if (Number(curr) > 0) {
                        if (Number(curr) < this.kdChannel.minFeeKg) {
                            curr = this.kdChannel.minFeeKg;
                        }
                        return prev + curr;
                    } else {
                        return prev;
                    }
                } else {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }
            }, 0);
            this.lowShijiWeight = sumsKg;
            this.lowShijicmbWeight = sumsCmb;
        },
        //下载入仓单
    rucang() {
      let fileName = this.form.orderNo + '-入仓单';
      let params = { orderNo: this.form.orderNo,t:'pdf' };
      this.preview(this.url.downloadRucang, params, fileName);
    },
    },
    created() {
        //回显服务商渠道
        this.getKdOrderChannelSelectList()
        if (this.order) {
            this.initKdChannelSet();
            // this.initAllSupplier(); //获取所有的快递供应商
            // this.initImporter(); //获取进口商
            // this.kdOrderChannelSetList = this.order['channelSetDictList'];
            this.kdOrderDeliveryPointSetList = this.order['deliveryPointDictList'];
            this.importerlist = this.order['importerList'];
            this.allSupplierList = this.order['allSupList'];
            this.supplierChannelList = this.order['supplierChannelList'];
            for (var name in this.order) {
                this.$set(this.form, name, this.order[name]);
                this.$set(this.good, name, this.order[name]);
                this.queryForm.orderNo = this.form.orderNo;
                /* this.queryForm.thStatus = '0';   //查询未提货*/
            }
            this.$emit('setDeclareType',this.form.declareType)
            this.initShouhuoConcat(); //初始化收获联系人
        }
        // if (this.form.supId) {
        //     this.getSupplierChannel(this.form.supId);
        // }
    },
    watch: {
        'form.receiveAddressType': {
            handler:function(oldV,newV) {
                if (newV != undefined  && oldV != newV){
                    this.form.receiveId = null
                }

            },
            deep: true,
            // immediate: true
        }
    },
  computed:{
    buttonShowList(){
      return {
        'add':this.$btnIsShow('kdorder/Edit', '0', '是否可以修改渠道名称'),
        '入仓单文档':this.$btnIsShow('kdorder/Edit','0','入仓单PDF'),

      }
    }
  }

};
</script>

<style lang="scss" scoped>
.DisableSelection > .cell {
  display: none !important;
}

.myCell .el-checkbox__input {
  display: none;
}
</style>
