<template>
    <el-dialog
            title="合并审核"
            :visible="visible"
            width="95%"
            custom-class="c-custom-dialog"
            append-to-body
            :before-close="handleClose"
            :close-on-click-modal="false">
        <template slot="title">
            <div style="width:100%;display: flex;flex-direction: row; justify-content: space-between">
                <span style="margin: 10px 10px">
                    合并审核
                </span>
<!--                <div style="width:20vw;display: flex;flex-direction: row; justify-content: space-evenly;margin: 10px 25px">-->
<!--                    <el-form-->

<!--                            :model="taxForm"-->
<!--                            :rules="rules"-->
<!--                            ref="taxForm"-->
<!--                            label-width="80px"-->
<!--                            :validate-on-rule-change="false"-->
<!--                    >-->
<!--                        <el-form-item label="附加费" prop="fjf" :rules="rules.fjf">-->
<!--                            <el-input type="number" v-model="taxForm.fjf"></el-input>-->
<!--                        </el-form-item>-->
<!--                    </el-form>-->
<!--                    <el-button-->
<!--                            type="primary"-->
<!--                            size="small"-->
<!--                            style="margin-left: 15px"-->
<!--                            @click="saveTax()"-->
<!--                            v-if="$btnIsShow('docpage/modules/Invoice', '0', '保存包税费用')"-->
<!--                    >保存包税费用-->
<!--                    </el-button>-->
<!--                </div>-->


            </div>
        </template>
        <el-form class="widthFull" :model="form" ref="goodref" :rules="rules">
            <div ref="topScroll" class="top-scroll2" @scroll="handleScrollTop">
                <div class="top-scroll-content2" :style="{ width: topScrollWidth }"></div>
            </div>
            <el-table
                    ref="goodsTable"
                    key="goodsTable"
                    :header-cell-style="{ 'text-align': 'center' }"
                    :data="form.osOrderGoodsList"
                    style="width: 99%"
                    class="customTable c-th-has-bgcolor"
                    show-summary
                    :summary-method="getSummaries2"
                    v-loading="goodsloading"
                    @selection-change="handleSelectionChange2"
                    :cell-style="{ padding: 2 + 'px' }"
                    :cell-class-name="changeCellStyle"
            >
                <el-table-column type="selection" width="30"></el-table-column>
<!--                <el-table-column-->
<!--                        prop="tag"-->
<!--                        label="是否备案"-->
<!--                        minWidth="75"-->
<!--                >-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ getTag(scope.row.tag) }}-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="action" label="操作" minWidth="80">
<!--                    <template slot-scope="scope">-->
<!--                        <el-button-->
<!--                                type="text"-->
<!--                                v-if="-->
<!--                  $btnIsShow('docpage/modules/Invoice', '1', '编辑') &&-->
<!--                  scope.row.orderNo"-->
<!--                        >编辑-->
<!--                        </el-button>-->
<!--                    </template>-->
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                v-if="buttonShowList['保存'] &&scope.row.orderNo"
                                @click="updateMergeGoods(scope.row,scope.$index)"
                        >保存
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="订单号" minWidth="130">
                    <!-- 标头需要加必填标识 -->
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.name'"
                                :rules="rules.ename"
                                class="flex_form"
                                style="width: 90%"
                        >
                            <span class="is_require_form">订单号</span>
                        </el-form-item>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.ename'"
                                class="flex_form"
                                style="width: 100%"
                        >
                        <span v-for="(item,index) in scope.row.recordList" :key="index" :class="{'bg_class': item.hasGuanwu == '1'}" @click="lockRecord(item.orderNo)">
                            {{item.orderNo}}
                             <i class="el-icon-question" @click="lockRecord(scope.row.orderNo)" v-if="item.hasGuanwu == '1'"></i>
                        </span>
                            <!-- <span>{{scope.row.orderNo}}</span> -->
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="中文品名" minWidth="120">
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">中文品名</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.name'"
                                :rules="rules.name"
                                class="flex_form"
                                style="width: 100%"
                        >
                            <el-input
                                    v-if="scope.row.hasTax =='1'"
                                    type="textarea"
                                    v-model="scope.row.name"
                                    maxlength="50"
                            ></el-input>
                            <span v-else>{{scope.row.name}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="英文品名" minWidth="130">
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">英文品名</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.ename'"
                                :rules="rules.ename"
                                class="flex_form"
                                style="width: 100%"
                        >
                            <el-input
                                    v-if="scope.row.hasTax =='1'"
                                    type="textarea"
                                    v-model="scope.row.ename"
                                    maxlength="50"
                            ></el-input>
                            <span v-else>{{scope.row.ename}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="country"
                        label="国家"
                        minWidth="80"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span >国家</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.country'"
                                style="width: 100%"
                        >
                            {{scope.row.country}}
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="material"
                        label="材质"
                        minWidth="90"
                        key="material"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">材质</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.material'"
                        >
                            <el-input v-if="scope.row.hasTax =='1'" v-model="scope.row.material"/>
                            <span v-else>{{scope.row.material}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="purpose"
                        label="用途"
                        minWidth="90"
                        key="purpose"
                >
                    <template slot="header">
                        <template>
                            <span class="is_require_form">用途</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.purpose'"
                        >
                            <el-input v-if="scope.row.hasTax =='1'" v-model="scope.row.purpose"/>
                            <span v-else>{{scope.row.purpose}}</span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="hscode"
                        label="税号"
                        minWidth="90"
                        key="hscode"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">税号</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.hscode'"
                                :rules="
                  scope.row.shipmentCountry === '美国' ? rules.mgHsCode : rules.hscode
                "
                        >
                            <el-input
                                    v-if="scope.row.hasTax =='1'"
                                    type="textarea"
                                    v-model="scope.row.hscode"
                                    maxlength="50"
                                    @input="
                    updateGoods33(
                      scope.row.hscode,
                      scope.$index,
                      scope.row.country
                    )
                  "
                            ></el-input>
                            <span v-else>{{scope.row.hscode}}</span>
                        </el-form-item>
                        <!--                          <span>{{scope.row.hscode}}</span>-->
                    </template>
                </el-table-column>
                <el-table-column
                        prop="addtionalCode"
                        label="301编码"
                        minWidth="65"
                        key="addtionalCode"
                >
                    <!-- 标头需要加必填标识 -->
                    <!--                        <template slot="header">-->
                    <!--                            <template>-->
                    <!--                                <span class="is_require_form">税号</span>-->
                    <!--                            </template>-->
                    <!--                        </template>-->
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.addtionalCode'"
                        >
                            <!--                                <el-input  v-model="scope.row.addtionalCode" maxlength="10" show-word-limit></el-input>-->
                            <span>{{scope.row.addtionalCode}}</span>
                        </el-form-item>
                        <!--                          <span>{{scope.row.hscode}}</span>-->
                    </template>
                </el-table-column>
                <el-table-column
                        prop="taxRate"
                        label="税率"
                        minWidth="65"
                        key="taxRate"
                >
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.taxRate'">
                            {{scope.row.taxRate}}
                        </el-form-item>
                    </template>
                </el-table-column>


                <el-table-column
                        prop="unitCost"
                        label="成本单价"
                        minWidth="65"
                        key="unitCost"
                >
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.unitCost'"
                                :rules="rules.un"
                        >
                            <span>
                                {{scope.row.unitCost}}
                            </span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unitValue"
                        label="申报单价"
                        minWidth="55"
                        key="price"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">申报单价</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.unitValue'"
                                :rules="rules.unitValue"
                        >
                            <el-input v-if="scope.row.hasTax =='1'"
                                      v-model="scope.row.unitValue"
                                      type="number"
                            ></el-input >
                            <span v-else>{{scope.row.unitValue}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="qty"
                        label="数量"
                        minWidth="55"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">数量</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.qty'"
                                :rules="rules.qty"
                        >
                            <span>{{scope.row.qty}}</span>

                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="totalValue"
                        label="总价"
                        minWidth="60"
                        key="totalValue"
                >
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.totalValue'"
                        >
                            <span>{{ scope.row.totalValue }}</span>
                        </el-form-item>
                    </template>
                </el-table-column>

                <el-table-column prop="ctn" label="箱数" minWidth="50">
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">箱数</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.ctn'"
                                :rules="rules.ctn"
                        >
                        <span>
                            {{scope.row.ctn}}
                        </span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="kg" label="毛重" minWidth="60">
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">毛重</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.kg'"
                                :rules="rules.kg"
                        >
                            <span class="is_require_form">{{scope.row.kg}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="nkg"
                        label="净重"
                        minWidth="55"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span >净重</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.nkg'"
                                :rules="rules.nkg2"
                        >
                            <span >{{scope.row.nkg}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="cmb" label="体积" minWidth="55">
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">体积</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.cmb'"
                                :rules="rules.cmb"
                        >
                            <span >{{scope.row.cmb}}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <!--          销售链接-->
<!--                <el-table-column prop="saleLink" label="销售链接" minWidth="100">-->

<!--                    <template slot-scope="scope">-->
<!--                        <el-form-item-->
<!--                                :prop="'osOrderGoodsList.' + scope.$index + '.saleLink'"-->
<!--                                :key="'osOrderGoodsList.' + scope.$index + '.saleLink'"-->

<!--                        >-->
<!--                            <span>{{scope.row.saleLink}}</span>-->
<!--                        </el-form-item>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="preTariff"
                        label="预算关税"
                        minWidth="75"
                        key="preTariff"
                >
                    <template slot="header">
                        <span>预算关税</span>
                        <el-popover
                                placement="top"
                                trigger="hover"
                                width="400"
                                content=""
                        >
                            <p>
                                计算美国预收关税 <br/>
                                税号 （税号匹配基础数据-美国海关编码 匹配的上的才有数据）<br/>
                                1、根据海关编码匹配 基础数据里的海关编码里的关税税率计算公式
                                按照公式计算 <br/>
                                2、附加税率：.301-15 --> 7.5% 301-01,301-02,301-03,301-04 -->
                                25% 301-67,301-68 --> 0<br/>
                                3、499 - Merchandise Processing Fee 产品货值 * 0.3464% <br/>
                                4、501 - Harbor Maintenance Fee 产品货值 * 0.1250% <br/>
                                <br/>
                                计算加拿大预收关税 <br/>
                                关税+增值税 <br/>
                                关税=货值*税率 <br/>
                                增值税=（关税+货值）*0.05 <br/>
                                <!--                                    税单加起来的金额-->
                            </p>
                            <i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item>
                            <!--                                <el-input disabled v-model="scope.row.preTariff"></el-input>-->
                            <span v-if="order.hasTaxStr === '0'" style="color: red">{{
                    scope.row.preTariff
                  }}</span>
                            <span v-else="scope.row.preTariff">{{
                    scope.row.preTariff
                  }}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="invoiceTag"
                        label="包税价格"
                        minWidth="75"
                >
                    <template slot="header">
                        <span>包税价格</span>
                        <el-popover
                                placement="top"
                                trigger="hover"
                                width="400"
                                content=""
                        >
                            <p>
                                计算包税价格 <br/>
                                附加费*计费数量/汇率（USD转CNY）<br/>
                                1.如果所有产品的预算关税之和大于计算出来的包税价格 显示红色
                                <br/>
                                2.如果所有的产品的预算关税之和小于计算出来的包税价格 显示绿色
                                <br/>
                            </p>
                            <i class="el-icon-question" slot="reference"></i>
                        </el-popover>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item>
                            <!--                                <el-input-->
                            <!--                                        :class="getInputClass(scope)"-->
                            <!--                                        disabled-->
                            <!--                                        v-model="scope.row.invoiceTag"-->
                            <!--                                ></el-input>-->
                            <span :class="getInputClass(scope)">{{
                    scope.row.invoiceTag
                  }}</span>
                        </el-form-item>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="unit" label="包装类型">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-form-item-->
<!--                                :prop="'osOrderGoodsList.' + scope.$index + '.unit'"-->
<!--                        >-->
<!--                            <span>-->
<!--                                {{scope.row.unit}}-->
<!--                            </span>-->
<!--                        </el-form-item>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="mergeCountFeeNum"
                        label="计费数量"
                        minWidth="65"
                        key="mergeCountFeeNum"
                >
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.mergeCountFeeNum'"
                        >
                            <span>
                                {{scope.row.mergeCountFeeNum}}
                            </span>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="fjf"
                        label="附加费"
                        minWidth="60"
                        key="fjf"
                >
                    <!-- 标头需要加必填标识 -->
                    <template slot="header">
                        <template>
                            <span class="is_require_form">附加费</span>
                        </template>
                    </template>
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.fjf'"
                        >
                            <el-input v-model="scope.row.fjf"/>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" :label="'备注'" minWidth="120">
                    <template slot-scope="scope">
                        <el-form-item
                                :prop="'osOrderGoodsList.' + scope.$index + '.remark'"
                        >
                            <el-input
                                    type="textarea"
                                    v-model="scope.row.remark"
                                    maxlength="130"
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
            <el-row class="f-text-right f-p-v-8">
                <el-pagination
                        v-bind="ipagination"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                </el-pagination>
            </el-row>
        </el-form>

  <customs-record-dialog :visible="recordVisible" :orderNo='lockOrderNo'  @update:visible="
        (val) => {
          recordVisible = val;
        }
      "/>
    </el-dialog>
</template>
<script>
    import {postAction, getAction} from "@/api/manage";
    import {caculateSishewuru} from "@/utils/caculate";
    import {noZhongwenValidator, validateCtn, validateQty, validateUnitValue4,} from "@/utils/validator";
    import {baifenshuPattren, sanweixiaoshu, shiweishuPattern,} from "@/utils/pattern";

    export default {
        name: 'mergeGoodsDialog',
        props: {
            visible: {
                type: Boolean
            },
            containerId: {
                type: String,
                default: ''
            },
        },
        data() {
            const validateCmb = (rule, value, callback) => {
                if (value) {
                    if (isNaN(value)) {
                        callback(new Error("必须为数字"));
                    } else {
                        if (value < 0) {
                            callback(new Error("不能为负数"));
                        } else {
                            let str = value.toString().split(".");
                            if (str[0].length > 10) {
                                callback(new Error("最多10位"));
                            } else if (str[1] && str[1].length > 3) {
                                callback(new Error("最多3位小数"));
                            } else if (str[2]) {
                                callback(new Error("小数不对"));
                            } else {
                                callback();
                            }
                        }
                    }
                } else {
                    callback();
                }
            };
            return {
                ipagination: {
                    currentPage: 1,
                    pageSize: 10,
                    total: 0
                },
                selectionRows2: [],
                selectedRowKeys2: [],
                countryList: [],
                proMap: {},
                prolist: [],
                coloading: false,
                order: {},
                topScrollWidth: 0,
                goodsloading: false,
                mergeGoodsList: [],
                taxForm: {
                    fjf: null
                },
                form: {
                    osOrderGoodsList:[]
                },
                //校验规则
                rules: {
                    mgHsCode: [
                        {required: true, message: "请填写税号", trigger: "change"},
                        {
                            pattern: shiweishuPattern,
                            message: "美国税号只能十位数字",
                            trigger: "change",
                        },
                    ],
                    hscode: [{required: true, message: "请填写税号", trigger: "change"}],
                    material: [{validator: noZhongwenValidator, trigger: "change"}],
                    purpose: [{validator: noZhongwenValidator, trigger: "change"}],
                    name: [{required: true, message: "请输入中文品名", trigger: "change"}],
                    ename: [
                        {required: true, message: "请输入英文品名", trigger: "change"},
                        {
                            validator: noZhongwenValidator,
                            trigger: "change",
                        },
                    ],
                    fjf: [
                        {required: true, message: "请输入附加费", trigger: "change"},
                        {
                            validator: validateCmb,
                            trigger: "change",
                        },
                    ],
                },
                url: {
                    get301binamByHscode: "/interface/ttOrder/get301binamByHscode",
                    list: '/operation/containerApi/getCabinetMergeReviewList',
                    editMergeGoods: '/interface/ttOrder/editMergeGoods',
                    saveTax: "/interface/ttOrder/saveTax",



                },
                recordVisible:false,
      lockOrderNo:''
            }
        },
        watch: {
            visible(val) {
                if (val && val == true) {
                    this.initMergeGoodsList();
                }
            },
        },
        methods: {
            handleSizeChange(val) {
                this.ipagination.currentPage = 1
                this.ipagination.pageSize = val
                this.initMergeGoodsList()
            },
            handleCurrentChange(val) {
                this.ipagination.currentPage = val
                this.initMergeGoodsList()
            },
            updateMergeGoods(row,index){

                const  params = ['osOrderGoodsList.'+index+'.name','osOrderGoodsList.'+index+ '.ename','osOrderGoodsList.'+index+'.material','osOrderGoodsList.'+index+'.purpose','osOrderGoodsList.'+index+'.remark','osOrderGoodsList.'+index+ '.hscode']
                let that = this
                const validList = []
                this.$refs["goodref"].validateField(params, valid => {
                    validList.push(valid)
                    if (!valid) {
                        if (validList.length === params.length) {
                            this.$refs['goodref'].clearValidate()

                            postAction(this.url.editMergeGoods, row).then((res) => {
                                this.$message({
                                    type: 'success',
                                    message: res.message
                                })
                                this.initMergeGoodsList()
                            })

                        } else {
                            return
                        }// that.$refs["goodref"].clearValidate()
                    }
                });
                console.log(validList)
            },
            getInputClass(scope) {
                if (Number(scope.row.totalPreTariff) < Number(scope.row.invoiceTag)) {
                    return "inputGreen;";
                }
                if (Number(scope.row.totalPreTariff) > Number(scope.row.invoiceTag)) {
                    return "inputRed;";
                }
            },
            getAllPrice(item) {
                if (!isNaN(parseFloat(item.qty)) && !isNaN(parseFloat(item.unitValue))) {
                    // item.totalValue = (
                    //     parseFloat(item.qty).toFixed(0) * parseFloat(item.unitValue)
                    // ).toFixed(3);
                    if (item.country === "美国") {
                        item.totalValue = caculateSishewuru(item.qty, item.unitValue).toFixed(
                            0
                        );
                    } else {
                        item.totalValue = caculateSishewuru(item.qty, item.unitValue);
                    }
                } else {
                    item.totalValue = 0;
                }
            },
            initCountryList() {
                this.coloading = true;
                if (this.countrylist.length <= 0) {
                    this.countrylist = [];
                    getallcountries().then((res) => {
                        if (res.success) {
                            for (var i = 0; i < res.result.length; i++) {
                                let obj = {};
                                obj.value = res.result[i].cnName;
                                obj.label = res.result[i].twoCode + "-" + res.result[i].cnName;
                                this.countrylist.push(obj);
                            }
                            this.coloading = false;
                        }
                    });
                } else {
                    this.coloading = false;
                }
            },
            parseProduct(list) {
                this.prolist = [];
                for (var i = 0; i < list.length; i++) {
                    let value = list[i].id;
                    let label = list[i].cname + "/ " + list[i].ename;
                    this.prolist.push({value, label});
                    list[i].name = value;
                }
                this.proMap = this.listToMap(list, "id");
                ////console.log('获取的产品数据',this.prolist,this.proMap)
            },
            changeCellStyle(row, column, rowIndex, columnIndex) {
                if (row.row.isMingan === "1") {
                    return "table_red_bg2";
                }
            },
            updateGoods33(val, index, country) {
                if (val && country === "美国") {
                    getAction(this.url.get301binamByHscode, {hscode: val}).then((res) => {
                        let obj = res.result;
                        if (obj) {
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "addtionalCode",
                                obj["addtionalDutyText"]
                            );
                            this.$set(
                                this.form.osOrderGoodsList[index],
                                "taxRate",
                                obj["dutyRate"]
                            );
                        }
                    });
                }
            },
            handleSelectionChange2(selection) {
                // 多选
                let arr = [];
                for (let i = 0; i < selection.length; i++) {
                    arr.push(selection[i]["orderNo"]);
                }
                this.selectionRows2 = selection;
                this.selectedRowKeys2 = [...arr];
                ////console.log("选中的集合", this.selectionRows2);
            },
            getSummaries2(param) {
                const {columns, data} = param;
                ////console.log('产品params',param);
                ////console.log('表格数据',columns);
                ////console.log('表格数据',data);
                const sums = [];
                let mixlist = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = "总计";
                        return;
                    }
                    if (data) {
                        const values = data.map((item) => Number(item[column.property]));
                        let map = {};
                        let flag = true;
                        // console.log('混装编码集合', mixlist)
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                // //console.log('合计',value);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);

                            if (column.property == "ctn") {
                                this.totalGoodCtn = sums[index].toFixed(0);
                                sums[index] = "总箱数：" + sums[index].toFixed(0);
                            } else if (column.property == "kg") {
                                this.totalGoodKg = sums[index].toFixed(3);
                                sums[index] = "总毛重：" + sums[index].toFixed(3);
                            } else if (column.property == "cmb") {
                                this.totalGoodCmb = sums[index].toFixed(3);
                                sums[index] = "总体积：" + sums[index].toFixed(3);
                            } else if (column.property == "totalValue") {
                                this.totalGoodPrice = sums[index].toFixed(3);
                                sums[index] = "总额：" + sums[index].toFixed(3);
                            } else if (column.property == "qty") {
                                sums[index] = "总数：" + sums[index].toFixed(0);
                            } else {
                                sums[index] = "";
                            }
                        } else {
                            sums[index] = "";
                        }
                    }
                });
                //移除表格合计行
                const table = document.querySelector(".customTable");
                const footer = document.querySelector(
                    ".customTable .el-table__footer-wrapper"
                );
                if (table && footer) {
                    table.removeChild(footer); // 移除表格最下方的合计行
                }
                return sums;
            },
            getTag(item) {
                return item == "1" ? "已备案" : "未备案";
            },
            handleScrollTop() {
                if (this.$refs.topScroll) {
                    let scrollLeft = this.$refs.topScroll.scrollLeft
                    this.$refs.goodsTable.bodyWrapper.scrollTo(scrollLeft, 0);
                }
            },
            saveTax() {
                let that = this
                this.$refs["taxForm"].validate((valid) => {
                    if (valid) {
                        console.log()
                        if(!this.selectedRowKeys2.join(',')){
                            this.$message.error("请勾选要保存附加费的产品")
                            return
                        }
                        this.taxForm.orderNo = this.selectedRowKeys2.join(',')
                        postAction(this.url.saveTax, this.taxForm).then((res) => {
                            that.$message.success("操作成功");
                            that.taxForm.fjf = null
                            that.initMergeGoodsList()
                            this.$refs['taxForm'].resetFields();

                        });
                    }
                });
            },
            initMergeGoodsList() {
                let that = this
                getAction(this.url.list, {
                    id: this.containerId,
                    pageNo:this.ipagination.currentPage,
                    pageSize:this.ipagination.pageSize
                }).then((res) => {
                    //这里做上传之后的操作
                    if (res.success) {
                        that.form.osOrderGoodsList = res.result
                        that.ipagination.total = res.result.total
                        this.$set(that.form, "osOrderGoodsList", res.result.records)
                    }
                })
            },
            handleClose() {
                this.$emit('update:visible', false);
            },
             lockRecord(orderNo) {
              this.lockOrderNo = orderNo;
              this.recordVisible = true
            }
        },
        computed: {
            buttonShowList() {
                return {
                    '保存': this.$btnIsShow('docpage/modules/Invoice', '1', '保存')
                }
            }
        },
        created() {
            this.initMergeGoodsList()
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep
    .el-dialog__body {
        padding: 20px 40px 20px 6px;
    }

    .addEditBox {
        max-height: 615px;
        overflow: scroll;
    }
    .bg_class {
        background-color: yellow;
    }
</style>
