<template>
  <div class="main-full-content">
    <el-form class="input_top" ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
        <el-row class="inputs_row">
          <el-col :span="6">
            <el-form-item label="订单号" label-width="100px">
              <el-input v-model="queryForm.orderNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="柜号" label-width="100px">
              <el-input v-model="queryForm.containerNo" @keyup.enter.native="handleSearch" type="textarea"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="文件类型" label-width="100px">
              <f-select
                  :isNeed="queryForm.fileTypeList"
                  v-model="queryForm.fileTypeList"
                  :multiple="true"
                  :dict="'staff_cus_order_file_type_list'"
              ></f-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="报关行" label-width="100px">
              <f-select
                  :isNeed="queryForm.declareAgentId"
                  v-model="queryForm.declareAgentId"
                  :dict="'declareAgent_id'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="国家" label-width="100px">
              <f-select
                  :isNeed="queryForm.shipmentCountry"
                  v-model="queryForm.shipmentCountry"
                  :dict="'bd_country'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="航线名称" label-width="100px">
              <f-select
                  :isNeed="queryForm.solicitation"
                  v-model="queryForm.solicitation"
                  :dict="'fba_solicitation'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否派送" label-width="100px">
              <f-select
                  :isNeed="queryForm.isDelivery"
                  v-model="queryForm.isDelivery"
                  :dict="'unit_whether'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报关方式" label-width="100px">
              <f-select
                  :isNeed="queryForm.declareType"
                  v-model="queryForm.declareType"
                  :dict="'os_declare_type'"
              ></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="派送开始时间" label-width="100px">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.deliveryTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="派送结束时间" label-width="100px">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.deliveryTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下单开始时间" label-width="100px">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeStart"
                  type="datetime"
                  size="small"
                  placeholder="开始时间"
                  default-time="00:00:00"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下单结束时间" label-width="100px">
              <el-date-picker
                  class="f-flex-1"
                  v-model="queryForm.createTimeEnd"
                  type="datetime"
                  size="small"
                  placeholder="结束时间"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button plain @click="handleReset">重置</el-button>
              <el-button type="primary" icon="el-icon-search" @click="downloadFile"
              v-if="buttonShowList.down">下载</el-button>

            </el-form-item>
          </el-col>
        </el-row>

      </el-card>

    </el-form>


    <el-card class="box-card last_card">


      <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="58vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange">
        <template v-slot="scope">
          <template v-if="scope.column.property === 'orderNo'">
            <a style="color: #00a0e9" @click.prevent="$intoDocPage(scope.row.orderNo)"
            >{{ scope.row.orderNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'containerNo'">
            <a style="color: #00a0e9" @click.prevent="infomation(scope.row.containerId)"
            >{{ scope.row.containerNo }}</a>
          </template>
          <template v-else-if="scope.column.property === 'declareType'">
            <span>{{parseDict(scope.row.declareType,'os_declare_type')}}</span>
          </template>
          <template v-else-if="scope.column.property === 'declareAgentId'">
            <span>{{parseDict(scope.row.declareAgentId,'declareAgent_id')}}</span>
          </template>
        </template>

      </f-table>
      <el-row class="f-text-right f-p-v-8">
        <el-pagination
            v-bind="ipagination"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </el-row>
    </el-card>

  </div>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js"
import {setUpRouteUtil} from "../../../utils/util";
import {postAction} from "@/api/manage";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "@/utils/downloadJasper";

export default {
  name: 'OsOrderModule',
  mixins: [QueryMixins],
  components: {},
  data() {
    return {
      hasCustomerBrokerFlag:false,
        isUsePost:true,
      dictCodes:['os_declare_type','declareAgent_id'],
      columns: [
        {
          prop: 'orderNo',
          label: '订单号',
        },
        {
          prop: 'containerNo',
          label: '柜号',
        },
        {
          prop: 'realCtn',
          label: '实件',
        },
        {
          prop: 'realCmb',
          label: '实体',
        },
        {
          prop: 'podEnd',
          label: '目的港',
        },
        {
          prop: 'etd',
          label: 'ETD',
        },
        {
          prop: 'eta',
          label: 'ETA',
        },
        {
          prop: 'createTime',
          label: '下单时间',
        },
        {
          prop: 'shipmentCountry',
          label: '国家',
        },
        {
          prop: 'solicitationText',
          label: '航线名称',
        },
        {
          prop: 'delivered',
          label: '派送时间',
        },
      ],
      url: {
        list: '/customer/osOrder/list',
        downloadFile:'/file/export/excel/zip/cusFile',
      },
      downloadFileForm:{},
    }
  },
  methods: {
    infomation(id) {
         let flag = this.$btnIsShow('customer/Normal','0','查看柜子详情');
            // 对于路由不在左侧菜单中的选中菜单处理
            if (flag) {
      if (id) {
        setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
        this.$router.push("/operation/cabinet/Edit?id=" + id);
      }
            }else {
                this.$message.warning('您没有权限查看柜子详情');
            }
      
    },
    handleReset() {
      this.queryForm = {}
      this.queryForm.cusId = this.$route.query.id
      this.ipagination.currentPage = 1
      this.handleQuery()
    },
    downloadFile(){
      this.downloadFileForm.cusId = this.$route.query.id;
      this.queryForm.timeTyp = "1";
      downloadJasperExcelByMethodAndByUrlAndParamsAndFileName("POST",this.url.downloadFile,
      this.queryForm,"客户文件.zip",this);
    },
    isHasCustomBrokerPermission(){
      let permissionFlag = this.buttonShowList.dec;
      if(permissionFlag){
          this.columns.splice(8,0,{
            prop: 'declareAgentId',
            label: '报关行',
          })
      }
    }
  },
  created() {
    this.isHasCustomBrokerPermission()
    this.queryForm.cusId = this.$route.query.id
  },
  computed : {
    buttonShowList() {
      return {
        'dec':this.$btnIsShow('customer/Normal','0','报关行'),
        'down':this.$btnIsShow('customer/modules/FileModule','0','下载订单文件'),
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
