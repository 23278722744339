<template>
  <el-card class="box-card">
    <div class="title_tab_text">物流信息</div>
    <el-table :data="tableData" class="c-th-has-bgcolor" style="width: 100%">
      <el-table-column label="创建时间" width="180px">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="">
        <template slot-scope="scope">
          <span v-html="scope.row.description"></span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.createBy }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-card> -->
  </el-card>
</template>

<script>
import QueryMixins from "@/mixins/QueryMixins.js";
import {getAction,deleteAction} from "@/api/manage";

export default {
  name: "StatusModule",
  mixins: [QueryMixins],
  components: {},
  props: {
    shipRes: {
      type: Object
    },
  },
  data() {
    return {
      disableMixinMounted: true,
      visible: false,
      url: {
        list: "/interface/ttOrder/getStatusList",
        exportXlsUrl: "/file/export/excel/downloadOsOrderStatusExcel",
        trigger17Track : 'interface/17track/triggerRegisterAndQuery17TrackByOrderNo'
      },
      tableData: [],
      trackNo: '',
      showMore: false,
      queryCount: 1,
    };
  },
  methods: {

    sync17tractDataInStatusList() {
      this.queryCount = this.queryCount + 1;
      if (!this.shipRes) {
        this.handleQuery();
      }
      // getAction(this.url.getTrackInfoList + "?orderNo=" + this.queryForm.orderNo
      //     + '&queryCount=' + this.queryCount).then((res) => {
      //   this.initTrackNoList();
      //
      // });
    },


    initVue() {
      this.queryForm.orderNo = this.$route.query.orderNo;
      console.log("created,status初始化....");
      this.handleQuery();
    },

    trigger17Track() {
      getAction(this.url.trigger17Track,{orderNo : this.$route.query.orderNo})
          .then(res => {

          })
    }
  },
  created() {
    // this.disableMixinMounted = true;
    this.queryForm.orderNo = this.$route.query.orderNo;
    this.queryForm.queryCount = this.queryCount;
    this.trigger17Track();
    this.sync17tractDataInStatusList();
    // this.initStatusList();
  },
  watch: {
    'shipRes': {
      handler(newValue, oldValue) {
        // 这里处理变化逻辑
        if (newValue) {
          console.log("父亲传递过来status的数据：", newValue);

          this.tableData = newValue.osOrderStatusList;

        } else {
          // this.handleQuery();
        }
      },
      deep: true,
      immediate: true // 初始化时也触发一次
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 800;
}

.item {
  display: flex;
  margin-bottom: 10px;
}

.time {
  margin-right: 50px;
}

.bb {
  position: absolute;
  max-width: 800px;
  width: 1000px;
  max-height: 100px;
  bottom: 39px;
  overflow-y: auto;
  border: 1px solid;
  border-radius: 4px;
  background-color: #fff;
}
</style>
