<template>
  <div>
    <el-card class="box-card">
      <el-form :model="form" :rules="rules" ref="form" :label-width="formLabelWidth" size="medium">
        <div class="title_tab_text">基本信息
          <span style=" margin-left: 40px;
          font-size: 12px
          !important;color: red;">
            入仓单客户端是否已下载：{{ basicForm.isCustomerDownload && basicForm.isCustomerDownload == '1' ? '是' : '否' }}
          </span>
          <div style="margin-left: 40px; font-size: 12px !important;">
            能否排柜：
            <span
                :style="{ color: (basicForm.isCanArrangeContainer === '1' && basicForm.isTemporaryStorage === '0') ? 'green' : 'red' }">
              {{ (basicForm.isCanArrangeContainer === '1' && basicForm.isTemporaryStorage === '0') ? '可排柜' : '不可排柜' }}
            </span>
          </div>


     <el-form-item prop="orderTypeTwo" label="订单类型2" :label-width="formLabelWidth">
               <f-select
                      :data-list="queryDictCodeListMap['order_type_two']"
                      v-model="basicForm.orderTypeTwo"
                      :is-need="basicForm.orderTypeTwo"
                  ></f-select>
            </el-form-item>
              <el-button  type="primary" @click="sendOrderTypeTwo" style="margin-left: 10px;height: 24px;"
                      v-if="buttonShowList['保存订单类型2']">保存订单类型2</el-button>
    </div>

        <!--    按钮-->
        <div class="f-p-b-15">
          <el-button  type="primary" size="medium" @click="handleSubmit('form')"
                      v-if="buttonShowList['保存']" :disabled="getIsSaveBtnDisabled()">保存</el-button>
          <el-button  type="primary" @click="sendEmail"
                      v-if="buttonShowList['发送邮件']">发送邮件</el-button>
          <el-button
              type="primary" size="medium"
              @click="handleExpenseConfiguration" v-if="buttonShowList['计算费用配置']">计算费用配置</el-button>
          <el-button  type="primary" size="medium" @click="changeToDead"
                      v-if="buttonShowList['快速完成订单']" >快速完成订单</el-button>
          <el-button  type="primary" size="medium" @click="changeToLive"
                      v-if="buttonShowList['取消快速完成订单']" >取消快速完成订单</el-button>
          <el-button
              type="primary"
              size="medium"
              @click=changeIsCanArrangeContainer
              >
            {{basicForm.isCanArrangeContainer === '1' ? '不可排柜' : '恢复可排柜'}}
          </el-button>
          <el-button
              type="primary"
              size="medium"
              @click=changeIsTemporaryStorage
              >
            {{basicForm.isTemporaryStorage === '1' ? '取消国内暂存' : '国内暂存'}}
          </el-button>
            <el-button  type="primary" size="medium" @click="openStickDialog"
                        v-if="buttonShowList['转为贴标订单'] && basicForm.transferNo && basicForm.isStick === '0'" >转为贴标订单</el-button>
          <el-button  type="text" class="f-m-l-10"  @click="rucang"
                      v-if="buttonShowList['入仓单文档']">入仓单PDF</el-button>
          <el-button  type="text" class="f-m-l-10" v-if="buttonShowList['入仓单表格']" @click="rucangexcel">入仓单EXCEL</el-button>
        <!-- <span class="el-dropdown-link">
          <el-button
              type="text" class="f-m-l-10" v-if="$btnIsShow('docpage/modules/BasicForm','0','LOGO唛头')">LOGO唛头<i class="el-icon-arrow-down"></i></el-button>
        </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="RMLOGO">唛头标签-热敏格式-logo</el-dropdown-item>
              <el-dropdown-item command="SIXLOGO">唛头标签 six-logo</el-dropdown-item>
              <el-dropdown-item command="TWOLOGO">唛头标签 two-logo</el-dropdown-item>
              <el-dropdown-item command="FOURLOGO">唛头标签 four-logo</el-dropdown-item>
              <el-dropdown-item command="CTNLOGO">唛头标签 箱号-logo</el-dropdown-item>
              <el-dropdown-item command="CTNLOGOSIX">唛头标签-six 箱号-logo</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="(cmd) => handleCommand(cmd)"> -->
        <!-- <span class="el-dropdown-link">
          <el-button

              type="text" class="f-m-l-10" v-if="$btnIsShow('docpage/modules/BasicForm','0','无LOGO唛头')">无LOGO唛头<i class="el-icon-arrow-down"></i></el-button>
        </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="RM">唛头标签-热敏格式</el-dropdown-item>
              <el-dropdown-item command="SLYC">唛头标签-森林云仓</el-dropdown-item>
              <el-dropdown-item command="SIX">唛头标签 six</el-dropdown-item>
              <el-dropdown-item command="TWO">唛头标签 two</el-dropdown-item>
              <el-dropdown-item command="FOUR">唛头标签 four</el-dropdown-item>
              <el-dropdown-item command="CTN">唛头标签 箱号</el-dropdown-item>
              <el-dropdown-item command="CTNSIX">唛头标签-six 箱号</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="(cmd) => handleCommand(cmd)"> -->
          <el-dropdown @command="(cmd) => handleCommand(cmd)">
            <span class="el-dropdown-link">
              <el-button type="text" class="f-m-l-10" v-if="buttonShowList['唛头']">单号唛头PDF<i class="el-icon-arrow-down"></i></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="RM">唛头标签100x100mm</el-dropdown-item>
              <el-dropdown-item command="RM">唛头标签A4-1</el-dropdown-item>
              <el-dropdown-item command="TWO">唛头标签A4-2</el-dropdown-item>
              <el-dropdown-item command="FOUR">唛头标签A4-4</el-dropdown-item>
              <el-dropdown-item command="SIX">唛头标签A4-6</el-dropdown-item>
              <el-dropdown-item command="CTN">唛头标签100x100mm-箱号</el-dropdown-item>
              <el-dropdown-item command="Japan">日本唛头-固税不贴标</el-dropdown-item>
              <el-dropdown-item command="JapanFBA">唛头含FBA号</el-dropdown-item>
              <!-- <el-dropdown-item command="CTNSIX">唛头标签100x100mm-箱号 x 6</el-dropdown-item>
              <el-dropdown-item command="SLYC">唛头标签-森林云仓</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="(cmd) => handleCommand(cmd)">
            <span class="el-dropdown-link">
              <el-button type="text" class="f-m-l-10">单号唛头EXCEL<i class="el-icon-arrow-down"></i></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="RMEXCEL">唛头标签100x100mm</el-dropdown-item>
              <el-dropdown-item command="RMEXCEL">唛头标签A4-1</el-dropdown-item>
              <el-dropdown-item command="TWOEXCEL">唛头标签A4-2</el-dropdown-item>
              <el-dropdown-item command="FOUREXCEL">唛头标签A4-4</el-dropdown-item>
              <el-dropdown-item command="SIXEXCEL">唛头标签A4-6</el-dropdown-item>
              <el-dropdown-item command="CTNEXCEL">唛头标签100x100mm-箱号</el-dropdown-item>
              <!-- <el-dropdown-item command="SLYCEXCEL">唛头标签-森林云仓</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>

          <el-button  type="text" class="f-m-l-10" @click="booking" v-if="buttonShowList['订舱单']">订舱单</el-button>
          <el-button  type="text" class="f-m-l-10" @click="bookingExcel" v-if="buttonShowList['订舱单表格']">订舱单excel</el-button>
          <el-button  type="text" class="f-m-l-10" @click="workExcel" v-if="buttonShowList['工作单']">工作单</el-button>
          <el-dropdown @command="(cmd) => handleCommandTd(cmd)">
            <span class="el-dropdown-link">
              <el-button type="text" class="f-m-l-10">提单下载<i class="el-icon-arrow-down"></i></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="提单PDF" v-if="buttonShowList['提单']">提单PDF</el-dropdown-item>
              <el-dropdown-item command="提单EXCEL" v-if="buttonShowList['提单表格']">提单EXCEL</el-dropdown-item>
              <el-dropdown-item command="电放提单PDF" v-if="buttonShowList['电放提单']">电放提单PDF</el-dropdown-item>
              <el-dropdown-item command="电放条形章PDF" v-if="buttonShowList['电放条形章']">电放条形章PDF</el-dropdown-item>
              <el-dropdown-item command="电放+条形章PDF" v-if="buttonShowList['电放条形章文档']">电放+条形章PDF</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!--
           <el-button  type="text" class="f-m-l-10" @click="hblone" v-if="buttonShowList['提单']">提单PDF</el-button>
          <el-button  type="text" class="f-m-l-10" @click="hbloneexcel" v-if="buttonShowList['提单表格']">提单EXCEL</el-button>
          <el-button  type="text" class="f-m-l-10" @click="dian" v-if="buttonShowList['电放提单']">电放提单PDF</el-button>
          <el-button  type="text" class="f-m-l-10" @click="tiao" v-if="buttonShowList['电放条形章']">电放条形章PDF</el-button>
          <el-button  type="text" class="f-m-l-10" @click="mergeDianTiao" v-if="buttonShowList['电放条形章文档']">电放+条形章PDF</el-button> -->
          <el-button  type="text" class="f-m-l-10" @click="tuicang" v-if="buttonShowList['退仓单']">退仓单</el-button>
        </div>
        <email-group-dialog
            :visible.sync="emailGroupVis"
            @ok="emailGroupVis = false"
            :row="emailModel"
        ></email-group-dialog>
        <!-- 计算费用配置弹窗 -->
        <expense-configuration-dialog :visible="expenseVisible" :row="form" @update:visible="(value) => { expenseVisible = value; }">
        </expense-configuration-dialog>

        <el-row class="inputs_row">
          <!--      第一行-->
          <el-col style="width: 20%;">
            <el-form-item label="派送国家" :label-width="formLabelWidth" key="shipmentCountry">
              <!-- <el-input v-model="shipmentCountry" disabled></el-input> -->
              {{ shipmentCountry }}
            </el-form-item>
          </el-col>


          <el-row style="width: 20%;">
            <el-col :span="14">
              <el-form-item prop="declareType" label="报关方式" label-width="70px" key="declareType">
                <f-select :is-need="form.declareType" :data-list="queryDictCodeListMap['os_declare_type']" v-model="form.declareType" :disabled="isSales"></f-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="退税份数" label-width="70px" :rules="['1','2'].indexOf(form.declareType) !== -1 ? rules.blNumber2 : rules.blNumber" prop="blNumber" key="blNumber">
                <el-input v-model="form.blNumber" type="number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col style="width: 30%;">
              <el-col :span="8">
                  <el-form-item prop="shangJianType" label="商检" label-width="50px" key="shangJianType">
                      <f-select :is-need="form.shangJianType" :data-list="queryDictCodeListMap['shang_jian_type']" v-model="form.shangJianType" :disabled="isSales"></f-select>
                  </el-form-item>
              </el-col>
            <el-col :span="8">
              <el-form-item label="核对报关草单" label-width="50px"  prop="needCheckCao" key="needCheckCao">
<!--                <el-radio  v-model="form.needCheckCao" :label="'0'" @click.native.prevent="radioClickCao('0')">否</el-radio>
                <el-radio  v-model="form.needCheckCao" :label="'1'" @click.native.prevent="radioClickCao('1')">是</el-radio>-->
                <el-radio  v-model="form.needCheckCao" :label="'0'">否</el-radio>
                <el-radio  v-model="form.needCheckCao" :label="'1'">是</el-radio>
              </el-form-item>
            </el-col>
              <el-col :span="8">
                  <el-form-item label="销售" :label-width="formLabelWidth" key="salesman">
                      <!-- <el-input v-model="form.salesman" disabled></el-input> -->
                      <span v-if="form.oldSalesman && form.oldSalesman!=null && form.oldSalesman !=''"> {{ form.oldSalesman }}-></span>
                      <span> {{ form.salesman }}</span>
                  </el-form-item>
              </el-col>
          </el-col>

          <el-col style="width: 10%;">
            <el-form-item label="下单时间" :label-width="formLabelWidth" key="createTime">
              <!-- <el-input v-model="form.createTime" disabled></el-input> -->
              {{ form.createTime }}
            </el-form-item>
          </el-col>
          <el-row style="width: 20%; display: flex;" v-if="checkIsCt()">
            <!-- <el-col :span="14"> -->
              <!-- <el-col :span="10"> -->
                <el-form-item style="width: 120px;" label="是否包税" prop="hasTax" label-width="70px" key="hasTax">
                  <el-radio-group v-model="form.hasTax" :disabled="order.invoiceStatus==='1'" @change="getFjfUnit">
                    <el-radio :label="'1'">是</el-radio>
                    <el-radio :label="'0'">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              <!-- </el-col> -->
            <!-- </el-col> -->
            <!-- <el-col :span="2" v-show="form.hasTax === '1'">
              <el-form-item label="运费" prop="yf" label-width="50px">
                <el-input type="number" v-model="form.yf" :disabled="order.invoiceStatus==='1'"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="10"> -->
              <el-form-item style="width: 100px;" v-if="form.hasTax === '1'" label="附加费" prop="fjf" label-width="50px" key="fjf" :rules="rules.fjf2">
                <el-input type="number" v-model="form.fjf" :disabled="order.invoiceStatus==='1'"></el-input>
              </el-form-item>
            <!-- </el-col> -->
            <el-tooltip v-if="buttonShowList['保存包税信息']" class="item" effect="dark" content="保存包税信息" placement="top-start">
              <el-button class="no_margin"  type="text" size="medium" @click="saveTax()"
                       :disabled="order.invoiceCheck === '1' || (Number(order.OrderStatus)>8 && order.canEditInfo === '0')"><img class="save_img" src="@/assets/save.png" alt=""></el-button>
            </el-tooltip>
          </el-row>

          <!--      第二行-->
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="transportStatus" label="渠道名称" :label-width="formLabelWidth" key="transportStatus">
              <el-select v-model="form.transportStatus"  @change="getOperations" style="width:100%" filterable clearable>
                <el-option v-for="(item, index) in channelSetList" :value="item.id" :label="item.channelName" :key="index" :disabled="item.isEnabled === '0'"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="docId" label="客服" :label-width="formLabelWidth" key="docId">
              <f-select v-model="form.docId" :isNeed="form.docId" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="billmanId" label="收款人" :label-width="formLabelWidth" key="billmanId">
              <f-select :isNeed="form.billmanId" v-model="form.billmanId" :disabled="isSales" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <!--      文件操作员-->
          <el-col style="width: 20%;">
            <el-form-item prop="docOperateId" label="文件操作" :label-width="formLabelWidth" key="docOperateId">
              <f-select :is-need="form.docOperateId" v-model="form.docOperateId" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;" >
            <el-form-item label="计费方式" prop="fjfUnit" key="fjfUnit">
            <el-select v-model="form.fjfUnit" disabled  clearable filterable>
                <el-option value="KG" label="KG"></el-option>
                <el-option value="CBM" label="CBM"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--      第三行-->
          <el-col style="width: 20%;">
            <el-form-item prop="deliveryPoint" label="交货仓库" :rules="form.solicitation!=='1'?rules.deliveryPoint:[]" :label-width="formLabelWidth" key="deliveryPoint">
<!--              <f-select :isNeed="form.deliveryPoint" v-model="form.deliveryPoint" :dict="'delivery_point'"></f-select>-->
              <el-select filterable clearable v-model="form.deliveryPoint">
                <el-option v-for="(i,d) in deliveryPointList" :key="d" :value="i.itemValue" :label="i.itemText"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <!--        操作员-->
            <el-form-item prop="operateId" label="排柜操作" :label-width="formLabelWidth" key="operateId">
              <f-select v-model="form.operateId" :is-need="form.operateId" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item label="入仓费记账" prop="isRucang" :label-width="formLabelWidth" :rules="rules.isRucang" key="isRucang">
              <el-radio-group v-model="form.isRucang" >
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col style="width: 10%" v-if="(form.isCo !== null && form.isCo !== '' && form.shipmentCountry =='澳大利亚') || (order.isFast == '1' && form.shipmentCountry =='澳大利亚')">
            <el-form-item
                label="办产地证"
                prop="isCo"
                :label-width="formLabelWidth"
                :rules="(order.isFast == '1' && form.shipmentCountry =='澳大利亚') ? rules.isCo : []"
                key="isCo"
            >
                <el-radio-group v-model="form.isCo">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
          </el-col>
          <!--      派送操作员-->
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="endOperatorId" label="派送操作" :label-width="formLabelWidth" key="endOperatorId">
              <f-select :is-need="form.endOperatorId" v-model="form.endOperatorId" :disabled="isSales" :data-list="queryDictCodeListMap['sys_user_id']"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 10%;">
            <el-form-item prop="tradeClause" label="贸易条款" :label-width="formLabelWidth" key="tradeClause">
              <f-select :is-need="form.tradeClause" :data-list="queryDictCodeListMap['freight_clause']" v-model="form.tradeClause" :disabled="isSales"></f-select>
            </el-form-item>
          </el-col>
          <!--      第四行-->
          <el-col style="width: 20%;">
            <el-form-item prop="lclPlace" :rules="form.solicitation!='1'?rules.lclPlace:[]" label="拼柜仓库" :label-width="formLabelWidth" key="lclPlace">
<!--              <f-select :dict="'lclPlace'" v-model="form.lclPlace" :is-need="form.lclPlace" @changet="getOperations"></f-select>-->
              <el-select filterable clearable v-model="form.lclPlace" @change="getOperations">
                <el-option v-for="(i,d) in lclPlaceList" :key="d" :value="i.itemValue" :label="i.itemText"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col style="width: 40%" v-if="!checkIsCt()">
                <el-col :span="8">
                    <el-form-item label="是否需要拖车" :label-width="formLabelWidth" v-if="form.containerType === '1'" prop="isTrailer" key="isTrailer">
                        <el-radio  v-model="form.isTrailer" :label="'0'" @click.native.prevent="radioClickT('0')">否</el-radio>
                        <el-radio  v-model="form.isTrailer" :label="'1'" @click.native.prevent="radioClickT('1')">是</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="是否需要代清关" :label-width="formLabelWidth" prop="isAgencyClearance" key="isAgencyClearance">
                        <el-radio  v-model="form.isAgencyClearance" :label="'0'" @click.native.prevent="radioClickC('0')">否</el-radio>
                        <el-radio  v-model="form.isAgencyClearance" :label="'1'" @click.native.prevent="radioClickC('1')">是</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="是否需要派送" :label-width="formLabelWidth" prop="isDelivered" key="isDelivered">
                        <el-radio  v-model="form.isDelivered" :label="'0'" @click.native.prevent="radioClickD('0')">否</el-radio>
                        <el-radio  v-model="form.isDelivered" :label="'1'" @click.native.prevent="radioClickD('1')">是</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="solicitation" label="航线名称" :label-width="formLabelWidth" key="solicitation">
              <f-select :is-need="form.solicitation" :data-list="queryDictCodeListMap['fba_solicitation']" v-model="form.solicitation"></f-select>
            </el-form-item>
          </el-col>
            <el-col style="width: 40%;" v-if="!checkIsCt()"></el-col>
          <el-col style="width: 20%;">
            <!-- <el-col :span="6"> -->
              <el-form-item prop="insurance" label="是否保险" :label-width="formLabelWidth" :class="form.insurance == '1' ? 'color_green_radio' : ''" key="insurance">
                <el-radio-group v-model="form.insurance" :disabled="form.isInsured == '1'">
                  <el-radio :label="'1'">是</el-radio>
                  <el-radio :label="'0'">否</el-radio>
                </el-radio-group>
              </el-form-item>
            <!-- </el-col> -->
          </el-col>
          <el-row style="width: 20%;">
            <el-col :span="16" v-show="form.insurance === '1'">
              <el-form-item prop="insuranceFee" label="保险金额" :rules="form.insurance == '1' ? rules.insuranceFee : []" :label-width="formLabelWidth" key="insuranceFee">
                <el-input v-model="form.insuranceFee" type="number" :disabled="form.isInsured == '1'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-show="form.insurance === '1'">
              <el-form-item class="no_label" prop="insuranceCur" label=" " :rules="form.insurance == '1' ? rules.cur : []" label-width="9px" key="insuranceCur">
                <el-select :is-need="form.insuranceCur" v-model="form.insuranceCur" :disabled="form.isInsured == '1'" placeholder="币种">
                  <el-option :label="item.label" :value="item.value" v-for="(item, index) in insuranceCurs" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col style="width: 20%;">
            <el-form-item v-show="form.insurance === '1'" prop="insured" label="被保人" :label-width="formLabelWidth" :rules="form.insurance == '1' ? rules.insured : []" key="insured">
              <el-select class="full_width" :disabled="form.isInsured == '1'" :loading="inloading" v-model="form.insured" placeholder="请选择" clearable filterable @click.native="initInsurance">
                <el-option :label="item.label" :value="item.value" v-for="(item, index) in insurancelist" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--    第五行-->
          <div style="width: 40%;">
              <el-col>
                  <el-form-item label="发货人" prop="shipper" :label-width="formLabelWidth" key="shipper">
                      <el-input  type="textarea" v-model="form.shipper"
                                style="padding: 0 55px 0 0"
                                :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']">
                  <el-form-item label="发货人公司名称" prop="shipperCompany" :label-width="formLabelWidth" key="shipperCompany">
                      <el-input  v-model="form.shipperCompany"
                                :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']">
                  <el-form-item label="发货人国家" prop="shipperCountry" :label-width="formLabelWidth" key="shipperCountry">
                      <f-select
                              v-model="form.shipperCountry"
                              :isNeed="form.shipperCountry"
                              :data-list="queryDictCodeListMap['ams_customer_country']">
                      </f-select>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']">
                  <el-form-item label="发货人地址" prop="shipperAddress" :label-width="formLabelWidth" key="shipperAddress">
                      <el-input  v-model="form.shipperAddress"
                                 :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']" >
                  <el-form-item label="进口商公司名称" prop="consigneeCompany" :label-width="formLabelWidth" key="consigneeCompany">
                      <el-input  v-model="form.consigneeCompany"
                                 :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']">
                  <el-form-item label="进口商国家" prop="consigneeCountry" :label-width="formLabelWidth" key="consigneeCountry">
                      <f-select
                              v-model="form.consigneeCountry"
                              :isNeed="form.consigneeCountry"
                              :data-list="queryDictCodeListMap['ams_customer_country']">
                      </f-select>
                  </el-form-item>
              </el-col>
              <el-col  v-if="buttonShowList['编辑发货人']">
                  <el-form-item label="进口商地址" prop="consigneeAddress" :label-width="formLabelWidth" key="consigneeAddress">
                      <el-input  v-model="form.consigneeAddress"
                                 :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                  </el-form-item>
              </el-col>
            <el-col :span="24" v-if="checkIsCt()">
              <el-form-item prop="importerId" label="进口商" :label-width="formLabelWidth" key="importerId">
                <el-select class="full_width" v-model="form.importerId" filterable clearable  :loading="imloading" @change="getImporter"  :class="textRed ? 'text_red' : ''">
                  <el-option-group v-for="group in importerlist" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.voList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
            <div style="width: 60%;" v-if="!checkIsCt()">
                <div>
                    <el-col :span="12">
                        <el-form-item prop="withinOrderRemark" label="客户单号" :label-width="formLabelWidth" key="withinOrderRemark">
                            <el-input v-model="form.withinOrderRemark"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="货好" label-width="40px" key="goodsTime" class="f-m-l-5">
                            <span >{{order.goodsTime}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7">
                        <el-form-item label="交货方式" :label-width="formLabelWidth" prop="whType" key="whType">
                            <el-select v-model="form.whType" clearable filterable>
                                <el-option :value="'0'" :label="'自送货'"></el-option>
                                <el-option :value="'1'" :label="'上门提货'"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                </div>
            </div>
            <el-col style="width: 40%;" v-if="!checkIsCt()">
                <el-form-item prop="froRemark" label="客户备注" :label-width="formLabelWidth" key="froRemark">
                    <el-input :disabled="isSales" type="textarea" v-model="form.froRemark"
                              style="padding: 0 55px 0 0"
                              :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
                </el-form-item>
            </el-col>
          <div style="width: 40%;" v-if="checkIsCt()">
            <div>
              <el-col :span="12">
                <el-form-item prop="withinOrderRemark" label="客户单号" :label-width="formLabelWidth" key="withinOrderRemark">
                  <el-input v-model="form.withinOrderRemark"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="货好" label-width="40px" key="expetTime" class="f-m-l-5">
                  <span v-if="order.whType === '0'">{{order.expetTime}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="交货方式" :label-width="formLabelWidth" prop="whType" key="whType2">
                  <el-select v-model="form.whType" clearable filterable>
                    <el-option :value="'0'" :label="'自送货'"></el-option>
                    <el-option :value="'1'" :label="'上门提货'"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </div>
            <el-col :span="24">
              <el-form-item prop="froRemark" label="客户备注" :label-width="formLabelWidth" key="froRemark2">
                <el-input :disabled="isSales" type="textarea" v-model="form.froRemark"
                          style="padding: 0 55px 0 0"
                          :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="500" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
                  <el-form-item prop="insuranceRemark" label="保险备注" :label-width="formLabelWidth" key="insuranceRemark">
                      <el-input type="textarea" v-model="form.insuranceRemark"
                                style="padding: 0 55px 0 0"
                                :autosize="{ minRows: 2, maxRows: 2}" :rows="2" maxlength="250"
                                show-word-limit></el-input>
                  </el-form-item>
            </el-col>
            <el-col :span="24" v-if="basicForm.isFast == '1'">
              <el-form-item prop="fastProductNames" label="快速下单品名" :label-width="formLabelWidth" key="fastProductNames">
                <el-input :disabled="osOrderGoodsList && osOrderGoodsList.length > 0" v-model="form.fastProductNames"
                          style="padding: 0 55px 0 0"
                          :autosize="{ minRows: 2, maxRows: 4}"  type="textarea" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </div>
          <div style="width: 20%;" v-if="checkIsCt()">
<!--            <el-col :span="24">-->
<!--              <el-form-item label="锁仓租柜租" :label-width="formLabelWidth">-->
<!--                <el-radio v-model="form.lockCangzu" :label="'0'" @click.native.prevent="radioClick('0')">否</el-radio>-->
<!--                <el-radio v-model="form.lockCangzu" :label="'1'" @click.native.prevent="radioClick('1')">是</el-radio>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="24" v-show="(form.shipmentCountry === '美国' || form.shipmentCountry === '加拿大' || form.shipmentContinent ==='OC') && form.containerType === '0' && form.solicitation !=='1' ">
              <el-form-item label="锁价服务(美/加/澳)" label-width="120px" key="lockChayan">
                <el-radio  v-model="form.lockChayan" :label="'0'" @click.native.prevent="radioClick1('0')">否</el-radio>
                <el-radio  v-model="form.lockChayan" :label="'1'" @click.native.prevent="radioClick1('1')">是</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="24" >
              <el-form-item label="包装类型" :label-width="formLabelWidth" key="packageType">
                <el-radio v-model="form.packageType" label="CTNS" @click.native.prevent="radioClick3('CTNS')">箱</el-radio>
                <el-radio v-model="form.packageType" label="PALLETS" @click.native.prevent="radioClick3('PALLETS')">托盘</el-radio>
                <el-radio v-model="form.packageType" label="PALL_PACK" @click.native.prevent="radioClick3('PALL_PACK')">托盘+包装</el-radio>
              </el-form-item>
            </el-col>
          </div>

          <!--      第六行-->


          <el-col style="width: 40%;" v-if="checkIsCt()">
            <el-form-item label="进口商信息" prop="importer" :label-width="formLabelWidth" key="importer">
              <el-input :disabled="isSales" type="textarea" v-model="form.importer" :autosize="{ minRows: 2, maxRows: 4}"  :rows="2" maxlength="500" show-word-limit style="padding: 0 55px 0 0"  :class="textRed ? 'text_red' : ''"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 40%;">
            <el-form-item prop="storehouseRemark" label="仓库备注" :label-width="formLabelWidth" key="storehouseRemark">
              <el-input :disabled="isSales" v-model="form.storehouseRemark"
                        style="padding: 0 55px 0 0"
                        :autosize="{ minRows: 2, maxRows: 4}"  type="textarea" maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;" v-if="checkIsCt()" v-show="form.shipmentContinent === 'EU' && form.containerType === '0' && form.solicitation !=='1'  ">
            <el-form-item label="欧洲三锁" :label-width="formLabelWidth" key="sanLock">
              <el-radio v-model="form.sanLock" :label="'0'" @click.native.prevent="radioClick2('0')">否</el-radio>
              <el-radio v-model="form.sanLock" :label="'1'" @click.native.prevent="radioClick2('1')">是</el-radio>
            </el-form-item>
          </el-col>

          <!--      第七行-->
          <el-col style="width: 40%;">
            <el-form-item label="内部备注" prop="remark" :label-width="formLabelWidth" key="remark">
              <el-input type="textarea" v-model="form.remark" :autosize="{ minRows: 2, maxRows: 2}" style="padding: 0 55px 0 0" maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
           <el-col style="width: 40%;">
            <el-form-item label="排柜备注" prop="pgRemark" :label-width="formLabelWidth" key="pgRemark">
              <el-input type="textarea" v-model="form.pgRemark" show-word-limit maxlength="500" style="padding: 0 55px 0 0" :autosize="{ minRows: 2, maxRows: 2}"></el-input>
            </el-form-item>
          </el-col>
            <el-col style="width: 40%;" v-if="!checkIsCt()"></el-col>
            <el-col style="width: 20%;" v-if="checkIsCt()" v-show="(form.shipmentCountry === '美国' || form.shipmentCountry === '加拿大') && form.containerType === '0' && form.solicitation !=='1'  ">
                <el-form-item label="销售锁价服务(美/加)" :label-width="formLabelWidth" key="saleLockPrice" >
                    <el-radio v-model="form.saleLockPrice" :label="'1'" @click.native.prevent="radioClick4('1')">是</el-radio>
<!--                    <el-radio v-model="form.saleLockPrice" :label="'2'" @click.native.prevent="radioClick4('2')">是+分</el-radio>-->
                    <el-radio v-model="form.saleLockPrice" :label="'0'" @click.native.prevent="radioClick4('0')">否</el-radio>
                </el-form-item>
            </el-col>
            <el-col style="width: 20%;" v-show="checkIsCtCopy()">
                <el-form-item label="销售三锁(欧洲)" :label-width="formLabelWidth" key="saleLockPrice" >
                    <el-radio v-model="form.saleLockPrice" :label="'1'" @click.native.prevent="radioClick4('1')">是</el-radio>
<!--                    <el-radio v-model="form.saleLockPrice" :label="'2'" @click.native.prevent="radioClick4('2')">是+分</el-radio>-->
                    <el-radio v-model="form.saleLockPrice" :label="'0'" @click.native.prevent="radioClick4('0')">否</el-radio>
                </el-form-item>
            </el-col>


           <el-col style="width: 40%;">
            <el-form-item label="国内增值服务" prop="innerValueAddService" :label-width="formLabelWidth" key="innerValueAddService">
              <el-input type="textarea" v-model="form.innerValueAddService" :autosize="{ minRows: 2, maxRows: 2}" style="padding: 0 55px 0 0" maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="hyDeliveryType" label="渠道派送方式" :label-width="formLabelWidth" key="hyDeliveryType">
              <f-select :is-need="form.hyDeliveryType" :data-list="queryDictCodeListMap['lcl_delivery_type']" v-model="form.hyDeliveryType" :disabled="true"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="actualHyDeliveryType" label="实际派送方式" :label-width="formLabelWidth" key="actualHyDeliveryType">
              <f-select :is-need="form.actualHyDeliveryType" :data-list="queryDictCodeListMap['actual_delivery_type']" v-model="form.actualHyDeliveryType" :disabled="!(form.hyDeliveryType == '4')"></f-select>
            </el-form-item>
          </el-col>
           <el-col style="width: 20%;"></el-col>
          <el-col style="width: 20%;"></el-col>
          <el-col style="width: 20%;"></el-col>
          <!--      <el-col :span="span">-->
          <!--        <el-form-item label="交货方式" :label-width="formLabelWidth">-->

          <!--        </el-form-item>-->
          <!--      </el-col>-->
          <!--  第八行    -->
          <el-col style="width: 20%;">
            <el-form-item prop="pol" label="起运港" :label-width="formLabelWidth" key="pol">
              <!-- <el-input disabled v-model="form.pol" ></el-input> -->
              {{ form.pol }}
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="declEndTime" label="截关日" :label-width="formLabelWidth" key="declEndTime">
              <!-- <el-input disabled v-model="declEndTime"></el-input> -->
              {{ declEndTime }}
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="shipCompany" label="船公司" :label-width="formLabelWidth" key="shipCompany">
              <!-- <el-input v-model="form.shipCompany" disabled></el-input> -->
              {{ form.shipCompany }}
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="containerNo" label="柜号" :key="'containerNo'" :label-width="formLabelWidth" >
              <!-- <el-input v-model="form.containerNo" v-show="showContainer" disabled></el-input> -->
              <span v-show="showContainer">{{ form.containerNo }}</span>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="preferenceType" label="优惠类型" :label-width="formLabelWidth" key="preferenceType">
              <f-select :is-need="form.preferenceType" :data-list="queryDictCodeListMap['os_order_preference _type']" v-model="form.preferenceType" :disabled="isSales"></f-select>
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;">
            <el-form-item prop="pod" label="卸货港" :label-width="formLabelWidth" key="pod">
              <!-- <el-input disabled v-model="form.pod" ></el-input> -->
              {{ form.pod }}
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item prop="etd" label="ETD" :label-width="formLabelWidth" key="etd">
              <!-- <el-input disabled v-model="etd"></el-input> -->
              {{ etd }}
            </el-form-item>
          </el-col>


          <el-col style="width: 20%;">
            <el-form-item prop="shipNo" label="船名航次" :label-width="formLabelWidth" key="shipNo">
              <!-- <el-input v-model="form.shipNo" disabled></el-input> -->
              {{ form.shipNo }}
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item prop="primaryNo" label="封条" :label-width="formLabelWidth" key="primaryNo">
              <!-- <el-input v-show="showprimaryNo" v-model="form.primaryNo" disabled></el-input> -->
              {{ form.primaryNo }}
            </el-form-item>
          </el-col>
          <el-col style="width: 20%;" v-if="checkIsCt()">
            <el-form-item prop="specialType" label="特批类型" :label-width="formLabelWidth" key="specialType">
              <f-select :is-need="form.specialType" :data-list="queryDictCodeListMap['discount_special_type']" v-model="form.specialType" :disabled="isSales"></f-select>
            </el-form-item>
          </el-col>
          <el-col></el-col>

          <el-col style="width: 20%;">
            <el-form-item prop="podEnd" label="目的港" :label-width="formLabelWidth" key="podEnd">
              <!-- <el-input disabled v-model="form.podEnd"></el-input> -->
              {{ form.podEnd }}
            </el-form-item>
          </el-col>


          <el-col style="width: 20%;">
            <el-form-item prop="eta" label="ETA" :label-width="formLabelWidth" key="eta">
              <!-- <el-input disabled v-model="eta"></el-input> -->
              {{ eta }}
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item prop="hbl" label="HBL提单号" :label-width="formLabelWidth" key="hbl">
              <!-- <el-input v-model="form.hbl" disabled></el-input> -->
              {{ form.hbl }}
            </el-form-item>
          </el-col>

          <el-col style="width: 20%;">
            <el-form-item prop="bookNo" label="订舱号" :label-width="formLabelWidth" key="bookNo">
              <!-- <el-input v-show="showBookNo" v-model="form.bookNo" disabled></el-input> -->
              {{ form.bookNo }}
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
    </el-card>

    <!-- <el-card class="box-card"> -->
    <shipment-and-invoice-module
        :order="basicForm"
        :stepMap="stepMap"
        :table-list2="osOrderGoodsList"
        :ship-res="shipmentObj"
        @init="initVue"
        :goodsImportExcelUrl="goodsImportExcelUrl"
        :orderStatusFlag='orderStatusFlag'
        :father-query-dict-list-map="queryDictCodeListMap"
    ></shipment-and-invoice-module>
    <!-- </el-card> -->
<!--    提货-->
    <el-card class="box-card">
    <tihuo-module :ship-res="shipmentObj" :order="basicForm" @init="initVue"></tihuo-module>
    </el-card>
<!--    物流信息 -->
      <!-- <el-card class="box-card"> -->
      <status-module :ship-res="shipmentObj" @init="initVue"></status-module>
      <!-- </el-card> -->
<!--    操作记录-->
      <!-- <el-card class="box-card"> -->
      <log-module :ship-res="shipmentObj" @init="initVue"></log-module>
      <!-- </el-card> -->
      <el-dialog
              :title="`变更${basicForm.orderNo}为贴标订单`"
              :visible.sync="stickVisible"
              width="30%"
              center
      >
          <span>此操作会将该订单{{basicForm.orderNo}} 变更为贴标订单</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="stickVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeToStick" :disabled="stickDisabled">{{stickBtnTitle}}</el-button>
      </span>
      </el-dialog>

      <el-dialog
              title="快速完成原因"
              :visible.sync="reasonVisible"
              width="30%"
              center
      >
      <el-form ref="reasonForm" :model="reasonForm" label-width="auto" size="medium" :rules="rules">
              <el-form-item label="快速完成原因" prop="reason" style="width: 300px;">
                <f-select
            v-model="reasonForm.reason"
            :isNeed="reasonForm.reason"
            dict="fast_finish_reason"
          ></f-select>
              </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="reasonClose">取消</el-button>
        <el-button type="primary" size="small" @click="submitToDead">
          确定
        </el-button>
      </template>
      </el-dialog>

  </div>
</template>

<script>
import { commission, grade, payType, regType, userType } from "@/utils/options";
import { getAction, postAction } from "@/api/manage";
import { validateCtn2,validateCmb} from "@/utils/validator";
import QueryMixins from "@/mixins/QueryMixins";
import EmailGroupDialog from "../../customer/modules/EmailGroupDialog";
import {formatDate, setUpRouteUtil} from '@/utils/util';
import ExpenseConfigurationDialog from './ExpenseConfigurationDialog';
// import ShipmentAndInvoiceModule from "@/views/docpage/modules/ShipmentAndInvoiceModule";
import ShipmentAndInvoiceModule from "@/views/docpage/modules/ShipmentAndInvoiceModule1";
import TihuoModule from "@/views/docpage/modules/TihuoModule";
import StatusModule from "@/views/docpage/modules/StatusModule";
import LogModule from "@/views/docpage/modules/LogModule";
import {downloadJasperExcelByMethodAndByUrlAndParamsAndFileName} from "../../../utils/downloadJasper";

export default {
  name: "DocPageBasicForm",
  mixins: [QueryMixins],
  components: { EmailGroupDialog, ExpenseConfigurationDialog,ShipmentAndInvoiceModule,TihuoModule,StatusModule,LogModule},

  props: {
    span: {
      type: Number,
      default: 4,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    order:{
      type:Object,
      default:()=>{}
    },
    // 进度条
    stepMap: {
      type: Object,
    },
     orderStatusFlag:{
      type: String,
      default: '',
    }
  },
  data: function () {
      // const validateIsRucang = (rule,value,callback) => {
      //     console.log("校验的入仓费记账的值",value);
      //     if (value) {
      //         console.log("校验的入仓费记账的值成功");
      //         callback();
      //     }else {
      //         console.log("校验的入仓费记账的值失败")
      //         callback(new Error('请选择入仓费记账'));
      //     }
      // }
    const feeValidator = (rule, value, callback) => {
      // console.log("校验保险", this.form.insuranceCur, this.form.insuranceFee);
      if (!value || (value && value.toString().trim() === "")) {
        callback(new Error("请输入保额"));
      } else if (!this.form.insuranceCur) {
        callback(new Error("请选择币种"));
      } else if (this.form.insuranceCur === "CNY" && value < 100) {
        callback(new Error("最小100CNY"));
      } else if (this.form.insuranceCur === "USD" && value < 20) {
        callback(new Error("最小20USD"));
      }
      callback();
    };
    // const validateNum = (rule, value, callback) => {
    //   // console.log("额度", value);
    //   if (value <= 0) {
    //     callback(new Error("要大于0"));
    //   } else if (
    //     value.toString().indexOf(".") !== -1 &&
    //     value.toString().split(".")[1].length > 3
    //   ) {
    //     callback(new Error("最多三位小数"));
    //   } else if (value.toString().split(".")[0].length > 10) {
    //     callback(new Error("最多十位数"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
        seconds:5,
      stickDisabled:true,
      stickVisible:false,
        stickBtnTitle:'5秒后可以点击',
      shipmentObj:{},
      basicForm:{},
      osOrderShipmentList:[],
      proSelectedList:[],
      osOrderGoodsList:[],
      osOrderTrailerList:[],
      osOrderLogIpage:{},
      osOrderStatusList:[],
      expenseVisible: false,
      emailModel: {},
      emailGroupVis: false,
      fsee: false,
      importer: "",
      insurancelist: [],
      insuranceCurs: [
        {
          value: "USD",
          label: "USD-美元",
        },
        {
          value: "CNY",
          label: "CNY-人民币",
        },
      ],
      inloading: false,
      shipmentCountry: "",
      form: {},
      isUpload: "",
      formLabelWidth: "100px",
      importerList: [],
      showContainer: true,
      showBookNo: true,
      showprimaryNo: true,
      showContainerText: "隐藏",
      showBookNoText: "隐藏",
      showprimaryNoText: "隐藏",
      fileList: [],
      grade,
      payType,
      commission,
      regType,
      userType,
      rules: {
        fjf2: [
          { required: true, message: "不能为空",trigger: 'change' },
          { validator: validateCmb, trigger: "change" }
        ],
        declareType: [
          { required: true, message: "请选择报关方式", trigger: "change" },
        ],
        transportStatus: [
          { required: true, message: "请选择渠道名称", trigger: "change" },
        ],
        deliveryPoint: [
          { required: true, message: "请选择交货地点", trigger: "change" },
        ],
        docId: [
          { required: true, message: "请选择客服", trigger: "change" },
        ],
        operateId: [
          {
            type: "string",
            required: true,
            message: "请选择操作员",
            trigger: "change",
          },
        ],
        billmanId: [
            {required: true, message: '请选择收款财务', trigger: 'change'},
        ],
        lclPlace: [
          { required: true, message: "请选择拼柜仓库", trigger: "change" },
        ],
        solicitation: [
          { required: true, message: "请选择航线名称", trigger: "change" },
        ],
        insurance: [
          { required: true, message: "请选择是否需要保险", trigger: "change" },
        ],
        insuranceFee: [{ validator: feeValidator, trigger: "blur" }],
        blNumber2: [
          { required: true, message: "不能为空",trigger: 'change' },
            { validator: validateCtn2, trigger: "change" }
        ],
        blNumber: [{ validator: validateCtn2, trigger: "change" }],
        // fjf: [ { required: true, message: "请输入附加费", trigger: "change" },{ validator: validateCmb, trigger: "change" }],
        // yf: [ { required: true, message: "请输入运费", trigger: "change" },{ validator: validateCmb, trigger: "change" }],
        cur: [{ required: true, message: "请选择币种", trigger: "change" }],
        insured: [
          { required: true, message: "请选择被保人", trigger: "change" },
        ],
        isRucang: [
          { required: true, message: "请选择入仓费记账", trigger: "change" },
        ],
        importerId: [
          { required: true, message: "请选择进口商", trigger: "change" },
        ],
        // hasTax: [
        //   { required: true, message: "请选择是否包税", trigger: "change" },
        // ],
        fjfUnit: [
          // { required: true, message: "请选择计费方式", trigger: "change" },
        ],
        shipper:[
          // {max: 250, message: "长度不能超过250", trigger: "change"},
          // {validator:noZhongwenValidator,trigger:'change'}
        ],
        isCo: [
          { required: true, message: "请选择办产地证", trigger: "change" },
        ],
        reason:[
        { required: true, message: "请选择快速完成原因", trigger: "change" },
        ]
      },

      countrylist: [],

      payTypeList: [
        { value: "0", label: "信用付款" },
        { value: "1", label: "单票送货前结算" },
        { value: "2", label: "单票送货后结算" },
      ],

      //港前操作
      docList: [],
      //业务员
      //提成类型 0:自行开发1:公司客户2:团体客户，3:扶持区域

      //客户来源 0在线咨询 1电话咨询 2营销QQ 3邮件咨询 4客户介绍 5展会资源 6独立开发 7注册会员，8fbashipping ,9support 10 forest+（目前如果修改就是23，不用10了）
      //城市基础数据
      citylist: [],
      //州基础数据
      statelist: [],
      importerMap: {},
      //
      url: {
          changeToStick: '/interface/fbaToOlc/stick/updateOrderToStick',
        updateDocVo: "/interface/ttOrder/updateDocPageVo",
        getByOrderNo: "/interface/ttOrder/addOneTtOrder/getDocPageVoInfo",
        //下载入仓单
        downloadRucang: "/file/jasper/download/rucang",
        //下载HBL
        downloadHBL: "/file/jasper/downloadHBL",
        //下载订舱单
        downloadBooking: "/file/jasper/downloadBooking",
        //下载工作单
        downloadWork: "/file/jasper/downloadWork",
        //下载退仓单
        downloadTuicang: "/file/jasper/downloadTuicang",
        //下载唛头
        downloadMark:'/file/jasper/downloadMark',
        getDownloadMarkInfo:'/file/jasper/downloadMark/getInfo',
        getOperations: "/interface/ttOrder/getOperate",
        getDictOperations: "/sys/dict/getDictItems/operationsId",
        getInsuranceList: "/customer/insurance/getList",
        getEmailModel: "/interface/ttOrder/getEmailSendAndCpEmail",
        getDefaultFjfUnit:'/interface/ttOrder/getDefaultFjfUnit',
        queryBaseFormVueInfo:'/interface/ttOrder/getDocPageBaseInfo',
        updateTax:'/interface/ttOrder/updateTaxInfo',
        updateOrderToDead:'/interface/ttOrder/updateOrderToDead',
        updateOrderToLive:'/interface/ttOrder/updateOrderToLive',
        updateOrderTypeTwo:'/interface/ttOrder/updateOrderTypeTwo',
        updateIsArrangeContainer: '/interface/ttOrder/batchUpdateIsArrangeContainer',
        updateIsTemporaryStorage: '/interface/ttOrder/batchUpdateIsTemporaryStorage',
      },
      isRucang: false,
      disableMixinMounted: true,
      imloading: false,
      importerlist: [],
      operationslist: [],
      oldOperationslist: [],
      mark: {},
      declEndTime: "",
      createTime: "",
      eta: "",
      etd: "",
      channelSetList: [],
      lclPlaceList: [],
      deliveryPointList: [],
      oldLclPlaceList: [],
      isSales:false,
      shipCountry:'',

 textRed: true,
      goodsImportExcelUrl: {},
      needQueryDictCodes:[
          "order_type_two",
          "os_declare_type",
          "shang_jian_type",
          "sys_user_id",
          "freight_clause",
          "fba_solicitation",
          "ams_customer_country",
          "os_order_preference _type",
          "hs_code_301",
          "packaging_type",
          "mixed",
          "bd_country",
          "third_address_type",
          "third_address_type_eng",
          "discount_special_type",
          "actual_delivery_type",
          "lcl_delivery_type"
      ],
      reasonVisible:false,
      reasonForm:{},

    };
  },
  computed: {
    declareType() {
      return this.form.declareType;
    },
    operateId() {
      return this.form.operateId;
    },
    docId(){
      return this.form.docId;
    },
    buttonShowList() {
      return {
        '保存':this.$btnIsShow('docpage/modules/BasicForm','0','保存'),
        '发送邮件':this.$btnIsShow('docpage/modules/BasicForm','0','发送邮件'),
        '计算费用配置':this.$btnIsShow('docpage/modules/BasicForm','0','计算费用配置'),
        '快速完成订单':this.$btnIsShow('docpage/modules/BasicForm','0','快速完成订单'),
        '取消快速完成订单':this.$btnIsShow('docpage/modules/BasicForm','0','取消快速完成订单'),
        '转为贴标订单':this.$btnIsShow('docpage/modules/BasicForm','0','转为贴标订单'),
        '入仓单文档':this.$btnIsShow('docpage/modules/BasicForm','0','入仓单PDF'),
        '入仓单表格':this.$btnIsShow('docpage/modules/BasicForm','0','入仓单EXCEL'),
        '唛头':this.$btnIsShow('docpage/modules/BasicForm','0','单号唛头pdf'),
        '订舱单':this.$btnIsShow('docpage/modules/BasicForm','0','订舱单'),
        '订舱单表格':this.$btnIsShow('docpage/modules/BasicForm','0','订舱单excel'),
        '工作单':this.$btnIsShow('docpage/modules/BasicForm','0','工作单'),
        '提单':this.$btnIsShow('docpage/modules/BasicForm','0','提单PDF'),
        '提单表格':this.$btnIsShow('docpage/modules/BasicForm','0','提单EXCEL'),
        '电放提单':this.$btnIsShow('docpage/modules/BasicForm','0','电放提单PDF'),
        '电放条形章':this.$btnIsShow('docpage/modules/BasicForm','0','电放条形章PDF'),
        '电放条形章文档':this.$btnIsShow('docpage/modules/BasicForm','0','电放+条形章PDF'),
        '退仓单':this.$btnIsShow('docpage/modules/BasicForm','0','退仓单'),
        '保存包税信息':this.$btnIsShow('docpage/modules/BasicForm','0','保存包税信息'),
        '编辑发货人':this.$btnIsShow('docpage/modules/BasicForm','0','ams发货人进口商编辑'),
        '已装柜可修改信息':this.$btnIsShow('isystem/RoleManage','0','已装柜可修改信息'),
        '已装柜有清关信息修改产品权限':this.$btnIsShow('isystem/RoleManage','0','已装柜有清关信息修改产品权限'),
        '查看柜子详情':this.$btnIsShow('isystem/RoleManage','0','客服页-点击周数跳转到柜子详情页'),
        '客服编辑':this.$btnIsShow('docpage/modules/BasicForm','0','是否拥有和客服一样的编辑权限'),
        '保存订单类型2':this.$btnIsShow('docpage/modules/BasicForm','0','保存订单类型2'),
        '循环是否暂存':this.$btnIsShow('docpage/modules/BasicForm','0','循环是否暂存'),
        '循环是否排柜':this.$btnIsShow('docpage/modules/BasicForm','0','循环是否排柜'),
      }
    }
  },
  watch: {
    declareType(val) {
      //测试柜号隐藏
      if (val) {
        if (val.indexOf("0") !== -1) {
          this.isUpload = this.form.isMaidan === "1" ? "已上传" : "未上传";
        }
        if (val.indexOf("1") !== -1) {
          this.isUpload = this.form.isTuishui === "1" ? "已上传" : "未上传";
        }
        if (val.indexOf("2") !== -1) {
          this.isUpload =
            this.form.isMaidan === "1" && this.form.isTuishui === "1"
              ? "已上传"
              : "未上传";
        }
      } else {
        this.isUpload = "未上传";
      }
    },
     "form.importerId": {
      immediate: true,
      handler() {
        if (this.importerlist && this.importerlist.length > 0) {
          var list = [];
          var listSys = [];
          var isSys = false;
          this.importerlist.forEach((item, index) => {
            if(item.label == '客户进口商') {
              list = item.voList;
            } else if(item.label == '系统进口商') {
              listSys = item.voList;
            }
          })
          listSys.forEach((item, index) => {
            if(item.id == this.form.importerId) {
              isSys = true;
              this.textRed = false;
            }
          })
          console.log(isSys,'isSys是否是系统进口商')
          !isSys && list.forEach((item) => {
            if (item.id == this.form.importerId) {
              console.log(item, "item");
              if (item.status == "0" || !item.status) {
                this.$nextTick(() => {
                  this.textRed = true;
                });
              } else {
                // this.$nextTick(() => {
                this.textRed = false;
                // });
              }
            } else {
              // this.$nextTick(() => {
              this.textRed = false;
              // });
            }
          });
        }
      },
    },
  },
  methods: {
      isShowByrTadeClause(val) {
          let flag = false
          if (['CIF', 'CNF/CFR', 'DDR', 'DAP', 'DDU'].indexOf(val) !== -1) {
              flag =  true
          }
          return flag
      },
      checkIsCt(){
          if (this.form.orderType === '1'){
              return false;
          }else {
              return true;
          }
      },
      openStickDialog() {
          this.stickVisible = true;
          this.stickDisabled = true;
          this.stickBtnTitle = '5秒后可以点击';
          var interval = setInterval(() => {
              this.seconds--;
              if (this.seconds <= 0) {
                  this.stickBtnTitle = '确定';
                  this.stickDisabled = false;
                  clearInterval(interval);
              }else {
                  this.stickBtnTitle = this.seconds + "秒后可以点击";
              }
          }, 1000);
          this.seconds = 5;
      },
      changeToStick(){
          let list = [{orderNo:this.basicForm.orderNo,transferNo:this.basicForm.transferNo}];
          postAction(this.url.changeToStick,list)
              .then(res=>{
                  this.$message.success(res.message);
                  this.stickVisible = false;
                  this.initAllInfo();
                  this.$emit("ok");
              })
      },
      async submitToDead() {
        let isValid = true;
        let that = this;
        if(this.basicForm.orderTypeTwo == '0' && this.reasonVisible) {
          await this.$refs.reasonForm.validate((valid) => {
          isValid = valid;
          })
        }
        if(!isValid) return;
        that.$confirm(`此操作将会把订单状态扭转到已完成`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(()=>{
        this.reasonVisible = false;
        getAction(that.url.updateOrderToDead,{no:that.$route.query.orderNo,reason:that.reasonForm.reason})
          .then(res=>{
            that.$message({type:'success',message:res.message});
            that.$emit("ok");
            // that.initAllInfo();
          })
      })
      },
    changeToDead(){
      if(!this.basicForm || this.basicForm.orderTypeTwo == null) {
        this.$message.warning('订单类型2为空不允许进行快速完成');
        return false;
      }
      if(this.basicForm.orderTypeTwo == '0') {
        this.reasonVisible=true;
        return;
      }
      this.submitToDead()
    },
    changeToLive(){
      let that = this;
      that.$confirm(`此操作将会把订单已完成状态取消`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(()=>{
        postAction(that.url.updateOrderToLive,[that.$route.query.orderNo])
            .then(res=>{
              that.$message({type:'success',message:res.message});
              that.$emit("ok");
              // that.initAllInfo();
            })
      })
    },
    changeIsCanArrangeContainer() {
      let that = this;
      let arrangeContainerFlag = this.basicForm.isCanArrangeContainer;
      let msg = "此操作将会把订单修改为";
      if (arrangeContainerFlag === '1') {
        msg = msg += "不可排柜";
      } else {
        msg += "可排柜"
      }
      msg = msg += "状态";

      that.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        let orderNoList = [that.$route.query.orderNo]
        const dto = {
          orderNoList: orderNoList,
          isCanArrangeContainer: arrangeContainerFlag === '0' ? '1' : '0'
        }
        postAction(that.url.updateIsArrangeContainer, dto)
            .then(res => {
              that.$message({type: 'success', message: res.message});
              that.$emit("ok");
              this.initAllInfo()
              this.getUserIsSales()
            })
      })
    },
    changeIsTemporaryStorage() {
      let that = this;
      let temporaryStorage = this.basicForm.isTemporaryStorage;
      let msg = "此操作将会进行";
      if (temporaryStorage === '1') {
        msg = msg += "取消暂存";
      } else {
        msg += "暂存"
      }
      msg = msg += "的变更";

      that.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        let orderNoList = [that.$route.query.orderNo]
        let temporaryStorageStartTime;
        let temporaryStorageEndTime;
        let trueIsTemporaryStorage;
        if (temporaryStorage === '1') {
          // 如果是取消暂存，需要更新暂存结束时间
          trueIsTemporaryStorage = '0';
          temporaryStorageEndTime = new Date().getTime();
        } else {
          // 如果是暂存，需要更新暂存开始时间
          trueIsTemporaryStorage = '1'
          temporaryStorageStartTime = new Date().getTime();
        }

        const dto = {
          orderNoList: orderNoList,
          isTemporaryStorage: trueIsTemporaryStorage,
          temporaryStorageStartTime: temporaryStorageStartTime,
          temporaryStorageEndTime: temporaryStorageEndTime
        }
        postAction(that.url.updateIsTemporaryStorage, dto)
            .then(res => {
              that.$message({type: 'success', message: res.message});
              that.$emit("ok");
              this.initAllInfo()
              this.getUserIsSales()
            })
      })
    },
    saveTax(){
      if (!this.form.hasTax) {
        this.$message.warning('请选择是否包税');
        return false;
      }

      if (!this.form.fjfUnit) {
        this.$message.warning('请选择计费单位');
        return false;
      }
        postAction(this.url.updateTax, this.form)
            .then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                // location.reload();
                //console.log("父亲，交给你了", this.$emit("ok"));
                this.initAllInfo();
                this.$emit("ok");
              }
            })


    },
    initVue(){
      this.$emit('init',"basic1");
      this.initAllInfo();
    },
    getCountry(){
      let ss = "";
      ss +=" " + this.getNullStr(this.form.shipmentCountry);
      ss +=" " + this.getNullStr(this.form.shipmentCode);
      ss +=" " + this.getNullStr(this.form.shipmentZip);
      this.shipCountry = ss;
    },
    getNullStr(s){
      if (!s || s === 'null' || s=== null) {
        return '';
      }else {
        return s;
      }
    },
    // 计算费用配置弹窗
    handleExpenseConfiguration() {
      this.expenseVisible = true;
    },
    radioClickCao(e){
      e === this.form.needCheckCao? (this.form.needCheckCao ='') :(this.form.needCheckCao = e)
      // 填写了 核对报关草单 排柜备注 自动带出
      let pgRemark = this.form.pgRemark || '';
      if (this.form.needCheckCao === '1') {
        if (pgRemark.indexOf('核对报关草单') === -1) {
          pgRemark = pgRemark + "核对报关草单";
          this.form.pgRemark = pgRemark;
        }
      }else {
        if (pgRemark.indexOf('核对报关草单') !== -1) {
          pgRemark = pgRemark.replace("核对报关草单","");
          this.form.pgRemark = pgRemark;
        }
      }
    },
      radioClickT(e){
          e === this.form.isTrailer? (this.form.isTrailer ='') :(this.form.isTrailer = e)
      },
      radioClickC(e){
          e === this.form.isAgencyClearance? (this.form.isAgencyClearance ='') :(this.form.isAgencyClearance = e)
      },
      radioClickD(e){
          e === this.form.isDelivered? (this.form.isDelivered ='') :(this.form.isDelivered = e)
      },
    radioClick(e){
      e === this.form.lockCangzu? (this.form.lockCangzu ='') :(this.form.lockCangzu = e)
    },
    radioClick1(e){
      e === this.form.lockChayan? (this.form.lockChayan ='') :(this.form.lockChayan = e)
    },
    radioClick2(e){
      e === this.form.sanLock? (this.form.sanLock ='') :(this.form.sanLock = e)
    },
    radioClick3(e){
      e === this.form.packageType? (this.form.packageType ='') :(this.form.packageType = e)
    },
    radioClick4(e){
      e === this.form.saleLockPrice? (this.form.saleLockPrice ='') :(this.form.saleLockPrice = e)
    },
    //判断是否可以点击保存按钮
    getIsSaveBtnDisabled(){
      //在判断 是否发票确认
      let flag = this.buttonShowList['已装柜可修改信息'];
      if (flag){
        return false;
      }
      let flag2 = this.buttonShowList['已装柜有清关信息修改产品权限'];
      if (flag2){
        return false;
      }

      let orderStatus=this.order.orderStatus;
      //合规审核
      if (Number(orderStatus)>=8 || this.order.containerLoadStr){
        //已装柜订单 ，判断canEditInfo
        if (this.order.canEditInfo==='1'){
         return false;
        }
        return true;
      }
      return false;
    },
    //根据客服id 找到对应绑定的收款人

    infomation(id) {
      //拦截销售
      // 对于路由不在左侧菜单中的选中菜单处理
      let flag = this.buttonShowList['查看柜子详情'];
      if (flag) {
        if(id) {
          setUpRouteUtil(this.$route.path, '/operation/cabinet/Edit');
          this.$router.push("/operation/cabinet/Edit?id=" + id);
        }
      }


      // console.log(id);
    },
    getUserIsSales(){
      let flag = this.buttonShowList['客服编辑'];
      // console.log('是否拥有和客服一样的编辑权限',flag);
      if (flag){
        this.isSales=false;
      }else {
        this.isSales=true;
      }
    },
    getImporter(val) {
      // console.log("选择的进口商信息", val, this.importerMap[val]);
      if (this.importerMap[val]) {
        this.$set(this.form,'importer',this.importerMap[val]["importer"])
        this.$set(this.form,'importerType',this.importerMap[val]["type"])
      }
    },
    initInsurance() {
      this.inloading = true;
      if (this.insurancelist.length >= 1) {
        this.inloading = false;
        return;
      } else {
        //获取发货人列表
        getAction(`${this.url.getInsuranceList}?cusId=${this.form.cusId}`)
          .then((res) => {
            if (res.success) {
              let list = res.result;
              for (var i = 0; i < list.length; i++) {
                let value = list[i].name;
                let label = list[i].name;
                this.insurancelist.push({ value, label });
              }
              this.inloading = false;
            } else {
              this.$message.warning("获取被保人列表失败");
              this.inloading = false;
            }
          })
          .catch(() => {
            this.inloading = false;
          });
      }
    },
    clearOpp() {
      // if (this.$refs["opp"]) {
      //   console.log("操作员校验", this.$refs["opp"]);
      //   this.$refs["opp"].clearValidate();
      // }
    },

    getFjfUnit(){
        if (this.checkIsCt()) {
            getAction(this.url.getDefaultFjfUnit,{channelId:this.form.transportStatus,hasTax:this.form.hasTax,orderNo:this.$route.query.orderNo}).then(res=>{
                if (res.result) {
                    this.$set(this.form,'fjfUnit',res.result)
                }
            })
        }
    },
    getOperations(){
      // if (val && val === '东莞凤岗仓库') {
      //   this.$set(this.form,'isRucang','1');
      // }
      // if (this.form.solicitation ==='1'){
      //   return;
      // }

      //操作员为空
      getAction(this.url.getOperations,{code:this.order.shipmentZip,state:this.order.shipmentState,country:this.order.shipmentCountry,
        lclPlaceId:this.form.lclPlace,channelId:this.form.transportStatus,fbaCode:this.order.shipmentCode}).then(res=>{
        if (res.result && res.result.isMatchMore === '0') {
          this.$set(this.form,'operateId',res.result.operateId)
          this.$set(this.form,'solicitation',res.result.solicitation)
        }else if (res.result && res.result.isMatchMore === '1') {
          //匹配到多条 弹出提示
          this.$confirm(`符合绑定配置存在多条，请联系主管修正操作绑定配置！`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
          }).then(()=>{

          }).catch(()=>{

          })
        }else{
          this.form.operateId=null
          this.form.solicitation=null
        }
      });
      if (this.checkIsCt()) {
        getAction(this.url.getDefaultFjfUnit,{channelId:this.form.transportStatus,hasTax:this.form.hasTax,orderNo:this.$route.query.orderNo}).then(res=>{
          if (res.result) {
            this.$set(this.form,'fjfUnit',res.result)
          }
        })
      }
    },
      saveOrderInfo(that){
          postAction(that.url.updateDocVo, that.form)
              .then((res) => {
                  if (res.success) {
                      that.$message.success(res.message);
                      // location.reload();
                      //console.log("父亲，交给你了", this.$emit("ok"));
                      that.initAllInfo();
                      that.$emit('init', 'basic2');
                  }
              })
      },

      handleSubmit(formName) {
          if (this.form.shipmentCountry !== '美国' && this.form.shipmentCountry !== '加拿大' && this.form.hasTax === '1' && this.form.importerType === '0') {
              this.$message.error("包税和进口商冲突");
              return;
          }
          // if (this.form.declareType ==='1'  && !this.form.blNumber){
          //   this.$message.error("报关方式为退税请填一下退税份数");
          //   return;
          // }
          // if (this.form.declareType ==='2'  && !this.form.blNumber){
          //   this.$message.error("报关方式为买单+退税请填一下退税份数");
          //   return;
          // }
          console.log("将要提交修改的订单基本信息", this.form);
          let that = this
          // this.$refs["form"].clearValidate();
          this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.insuranceFeeMsg().then(resInsuranceFee => {
                      //操作员为空
                      getAction(that.url.getOperations,{code:that.order.shipmentZip,state:that.order.shipmentState,country:that.order.shipmentCountry,
                          lclPlaceId:that.form.lclPlace,channelId:that.form.transportStatus,fbaCode:that.order.shipmentCode}).then(res=>{
                          if (res.result) {
                              that.saveOrderInfo(that)
                          }else{
                              //匹配到多条 弹出提示
                              that.$confirm(`订单派送地址和订单渠道可能不匹配，如正常放舱需向业务确认该地址报价！`, '提示', {
                                  confirmButtonText: '是',
                                  cancelButtonText: '否',
                                  type: 'warning',
                                  closeOnClickModal: false,
                              }).then(()=>{
                                  that.saveOrderInfo(that)
                              }).catch(()=>{
                              })
                          }
                      });
                  })
              }
          });
      },

    // 投保金额校验
    // 投保金额大于10万USD或70万CNY 投保金额提示：投保金额较大，请核实投保金额是否正确!
    // 投保金额小于和等于100USD或100CNY 投保金额提示：投保金额较小，请核实投保金额是否正确!
    insuranceFeeMsg() {
      return new Promise((resolve, reject) => {
        let message = '';
        if(this.form.insurance == '1' && this.form.insuranceFee) {
          let insuranceFee = (parseFloat(this.form.insuranceFee)).toFixed(3);
          if(this.form.insuranceCur == 'USD'){
            if(insuranceFee > 100000) {
              message = '投保金额较大，请核实投保金额是否正确!';
            } else if(insuranceFee <= 100) {
              message = '投保金额较小，请核实投保金额是否正确!';
            }
          } else if(this.form.insuranceCur == 'CNY') {
            if(insuranceFee > 700000) {
              message = '投保金额较大，请核实投保金额是否正确!';
            } else if(insuranceFee <= 100) {
              message = '投保金额较小，请核实投保金额是否正确!';
            }
          }
        }
        if(message) {
          this.$confirm(message, "投保金额提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            resolve(true);
          })
        } else {
          resolve(true);
        }
      })
    },
    //下载入仓单
    rucang() {
      let fileName = this.form.orderNo;
      if (!this.form.deliveryPoint) {
        //没有选择交货仓库 提示 要选择
        this.$message.warning('请选择交货仓库之后在下载');
        return false;
      }
      if(this.form.deliveryPoint) {
          let fbaSt = this.shipmentObj.osOrderShipmentList && this.shipmentObj.osOrderShipmentList.length && this.shipmentObj.osOrderShipmentList[0].shipmentId ? this.shipmentObj.osOrderShipmentList[0].shipmentId:''
        let deliveryPoint = null;
        this.deliveryPointList.forEach(element => {
          if(this.form.deliveryPoint === element.itemValue) {
            fileName = this.form.shipmentCode + '-' +this.form.orderNo + '-入仓单-'+ element.itemText + fbaSt;
            deliveryPoint = element.itemText;
          }
        });
        fileName = this.getFileName2("入仓单PDF", deliveryPoint);
      }

      let params = { orderNo: this.form.orderNo,deliveryPoint:this.form.deliveryPoint,t:'pdf' };
      this.preview(this.url.downloadRucang, params, fileName);
      // 直接打印后台返回的pdf
      // this.printPdfDoc(this.url.downloadRucang, params);
    },
    //下载入仓单excel
    rucangexcel() {
      if (!this.form.deliveryPoint) {
        //没有选择拼柜仓库 提示 要选择
        this.$message.warning('请选择拼柜仓库之后在下载');
        return false;
      }
      let params = {deliveryPoint:this.form.deliveryPoint,t:'excel',orderNo:this.$route.query.orderNo};
      var lclplaces ;
      for (var i = 0; i < this.deliveryPointList.length; i++) {
        if (this.deliveryPointList[i].itemValue === this.form.deliveryPoint){
          lclplaces=this.deliveryPointList[i].itemText;
        }
      }
      //文件名
        let fbaSt = this.shipmentObj.osOrderShipmentList && this.shipmentObj.osOrderShipmentList.length && this.shipmentObj.osOrderShipmentList[0].shipmentId ? this.shipmentObj.osOrderShipmentList[0].shipmentId:'';
      let fileName = lclplaces?this.form.shipmentCode + '-' + this.form.orderNo + (this.form.hasTax == 0 ? "-不包税" :"-包税")  + "-入仓单-"+ lclplaces+ fbaSt:this.form.shipmentCode + "-"+this.form.orderNo + fbaSt;

      fileName = this.getFileName2("入仓单Excel", lclplaces);

      this.downloadExcel(this.url.downloadRucang,fileName, params);
      this.$emit("ok");
    },
    //下载唛头
    handleCommand(cmd) {
      let type='1';
      //let orderNo = this.$route.query.orderNo;
      let deliveryPoint
      for (var i = 0; i < this.deliveryPointList.length; i++) {
         if (this.deliveryPointList[i].itemValue === this.form.deliveryPoint) {
             deliveryPoint = this.deliveryPointList[i].itemText;
        }
      }

      //let lclPlaceName = deliveryPoint || '';
      //let channelName = this.order.channelName || '';
      // let fileName;
      // if (cmd && cmd.indexOf('EXCEL')!==-1){
      //  fileName = orderNo + (this.form.hasTax == 0 ? "-不包税" :"-包税") + '-唛头-' + lclPlaceName + "-" + channelName + "-";
      //  } else {
       //let fileName = this.form.shipmentCode + '-' + orderNo + '-唛头-' + lclPlaceName + "-" + channelName + "-";
      let fileName = this.getFileName2("唛头", deliveryPoint) + "-";
      //  }
      let params = { orderNo: this.$route.query.orderNo,t:'pdf'};
      if (cmd === "RMLOGO") {
        type='1';
        fileName += '热敏logo';
      }else if (cmd === "RMLOGOEXCEL") {
        type='1';
        fileName += '热敏logoEXCEL';
      }
      else if (cmd === "SIXLOGO") {
        type='2';
        fileName += '六logo';
      } else if (cmd === "SIXLOGOEXCEL") {
        type='2';
        fileName += '六logo';
      }else if (cmd === "FOURLOGO") {
        type='4';
        fileName += '四logo';
      } else if (cmd === "FOURLOGOEXCEL") {
        type='4';
        fileName += '四logo';
      } else if (cmd === "TWOLOGO") {
        type='3';
        fileName += '两logo';
      }  else if (cmd === "TWOLOGOEXCEL") {
        type='3';
        fileName += '两logo';
      } else if (cmd === "TWO") {
        type='10';
        fileName += '两';
      } else if (cmd === "TWOEXCEL") {
        type='10';
        fileName += '两';
      } else if (cmd === "SIX") {
        type='9';
        fileName += '六';
      } else if (cmd === "SIXEXCEL") {
        type='9';
        fileName += '六';
      } else if (cmd === "FOUR") {
        type='11';
        fileName += '四';
      }  else if (cmd === "FOUREXCEL") {
        type='11';
        fileName += '四';
      } else if (cmd === "SLYC") {
        type='8';
        fileName += '森林云仓';
      } else if (cmd === "SLYCEXCEL") {
        type='8';
        fileName += '森林云仓';
      } else if (cmd === "RM") {
        type='7';
        fileName += '热敏';
      } else if (cmd === "RMEXCEL") {
        type='7';
        fileName += '热敏';
      } else if (cmd === "CTN") {
        type='12';
        fileName += '箱单';
      }else if (cmd === "CTNEXCEL") {
        type='12';
        fileName += '箱单';
      }else if (cmd === "CTNLOGO") {
        type='5';
        fileName += '箱单LOGO';
      }else if (cmd === "CTNLOGOEXCEL") {
        type='5';
        fileName += '箱单LOGO';
      }else if (cmd === "CTNSIX") {
          type='14';
          fileName += '箱单SIX';
      }else if (cmd === "CTNSIXEXCEL") {
          type='14';
          fileName += '箱单SIX';
      }else if (cmd === "CTNLOGOSIX") {
          type='13';
          fileName += '箱单SIX';
      }else if (cmd === "CTNLOGOSIXEXCEL") {
          type='13';
          fileName += '箱单SIX';
      } else if(cmd === 'Japan') {
          type='15';
          fileName += '日本唛头';
          if(this.basicForm.shipmentCountry !== '日本') {
            this.$message.warning('非日本派送国家不可以下载')
            return;
          }
      } else if(cmd === 'JapanFBA') {
        console.log(this.shipmentObj,'shipmentObj');
        let fbaSt = this.shipmentObj.osOrderShipmentList && this.shipmentObj.osOrderShipmentList.length && this.shipmentObj.osOrderShipmentList[0].shipmentId ? this.shipmentObj.osOrderShipmentList[0].shipmentId:''
          type='16';
          fileName += `含FBA号${fbaSt}`;
      }

      //拼接好参数 执行下载方法
      params.type=type;
      if (cmd && cmd.indexOf('EXCEL')!==-1){
        params.t='excel';
        //下载excel
        // 先请求 获取 数据 然后 在转发到 jasper服务器
        // fileName += ".xlsx";
        this.downloadExcel(this.url.downloadMark,fileName, params);
      }else {
        //下载pdf
        this.preview(this.url.downloadMark, params,fileName);
        // fileName += ".pdf";
      }
      // getAction(this.url.getDownloadMarkInfo,params)
      //     .then(res => {
      //       downloadJasperExcelByMethodAndByUrlAndParamsAndFileName('GET',this.$getJasperUrl(),
      //           {key:res.result},fileName,this);
      //     })
    },
    getFileName(fileType){

      let fileName = this.$route.query.orderNo + "-" + fileType;
      if(this.order.shipmentCode){
        fileName = fileName + "-" + this.order.shipmentCode;
      }
      // if(this.order.withinOrderRemark){
      //   fileName = fileName + "-" + this.order.withinOrderRemark;
      // }
      return fileName;
    },
    getFileName2(fileType, deliveryPoint){

      let fileName = this.$route.query.orderNo + "-" + fileType;
      if(this.order.shipmentCode){
        fileName = fileName + "-" + this.order.shipmentCode;
      }
      if(this.form.hasTax){
        fileName = fileName + "-" + (this.form.hasTax === '0' ? "不包税" :"包税");
      }
      if(deliveryPoint){
        fileName = fileName + "-" + deliveryPoint;
      }
      if(this.order.channelName){
        fileName = fileName + "-" + this.order.channelName;
      }
      // if(this.order.withinOrderRemark){
      //   fileName = fileName + "-" + this.order.withinOrderRemark;
      // }
      return fileName;
    },
    handleCommandTd(cmd) {
      if (cmd === "提单PDF") {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf'};
      let fileName = this.getFileName(cmd);
      this.preview(this.url.downloadHBL , params,fileName);
      } else if (cmd === '提单EXCEL') {
      let params = {orderNo:this.$route.query.orderNo,t:'excel'};
      let fileName = this.getFileName(cmd);
      this.downloadExcel(this.url.downloadHBL , fileName,params);
      } else if (cmd === '电放提单PDF') {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isDian:'1'};
      let fileName = this.getFileName(cmd);
      this.preview(this.url.downloadHBL,params,fileName);
      } else if (cmd === '电放条形章PDF') {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isTiao:'1'};
      let fileName = this.getFileName(cmd);
      this.preview(this.url.downloadHBL , params,fileName);
      } else if (cmd === '电放+条形章PDF') {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isDian:'1',isMerge:'1'};
      let fileName = this.getFileName(cmd);
      this.preview(this.url.downloadHBL,params,fileName);
      }
    },

    //下载订舱单
    booking() {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf'};
      let fileName = '订舱单-'+ this.$route.query.orderNo;
      this.preview(this.url.downloadBooking , params,fileName);
    },
    //下载订舱单excel
    bookingExcel() {
      let params = {orderNo:this.$route.query.orderNo,t:'excel'};
      let fileName = '订舱单-'+ this.$route.query.orderNo;
      this.downloadExcel(this.url.downloadBooking ,fileName, params);
    },
    //下载工作单
    workExcel() {
      let params = {orderNo:this.$route.query.orderNo,t:'excel'};
      let fileName = '工作单-'+ this.$route.query.orderNo;
      this.downloadExcel(this.url.downloadWork,fileName, params);
    },
    //下单提单PDF
    hblone() {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf'};
      let fileName = 'HBL提单-'+ this.$route.query.orderNo;
      this.preview(this.url.downloadHBL , params,fileName);
    },
    //下载提单excel
    hbloneexcel() {
      let params = {orderNo:this.$route.query.orderNo,t:'excel'};
      let fileName = 'HBL提单-'+ this.$route.query.orderNo;
      this.downloadExcel(this.url.downloadHBL , fileName,params);
    },
    //下载电放提单pdf
    dian() {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isDian:'1'};
      let fileName = '电放提单-'+ this.$route.query.orderNo;
      this.preview(this.url.downloadHBL,params,fileName);
    },
      //下载电放+条形章pdf
    mergeDianTiao() {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isDian:'1',isMerge:'1'};
      let fileName = '电放+条形章-'+ this.$route.query.orderNo;
      this.preview(this.url.downloadHBL,params,fileName);
    },
    //下载电放条形章pdf
    tiao() {
      let params = {orderNo:this.$route.query.orderNo,t:'pdf',isTiao:'1'};
      let fileName = '电放条形章-'+ this.$route.query.orderNo;
      this.preview(this.url.downloadHBL , params,fileName);
    },
    //下载退仓单
    tuicang() {
      let params = {orderNo:this.$route.query.orderNo,t:'excel'};
      let fileName = '退仓单-'+ this.$route.query.orderNo;
      this.downloadExcel(this.url.downloadTuicang,fileName,params);
    },
    sendEmail() {
      getAction(this.url.getEmailModel, {
        orderNo: this.$route.query.orderNo,
      }).then((res) => {
        this.emailModel = res.result;
        this.emailGroupVis = true;
      });
      // console.log('父亲，交给你了', this.$emit('ok'));
    },
    formateTime(val) {
      if (val) {
        return val.toString().substring(0, 10);
      }
    },
    initForm() {
      this.$nextTick(function () {
        // console.log("dom update complete", this.$refs);
        // this.$refs["form"].clearValidate();
      });
    },
    async initDictOpearionts() {
      await getAction(this.url.getDictOperations).then((res) => {
        this.oldOperationslist = res.result;
      });
    },
    initAllInfo(){
      getAction(this.url.queryBaseFormVueInfo,{orderNo:this.$route.query.orderNo})
        .then(res=>{
          this.basicForm = res.result.osOrder;
          this.osOrderGoodsList = res.result.osOrderGoodsList;
          this.shipmentObj = res.result;
          this.osOrderShipmentList = res.result.osOrderShipmentList;
          this.osOrderTrailerList = res.result.osOrderTrailerList;
          this.osOrderStatusList = res.result.osOrderStatusList;
          this.osOrderLogIpage = res.result.osOrderLogPage;
          this.proSelectedList = res.result.proIdList;
          this.goodsImportExcelUrl= res.result.bdTaxDist

          this.buildBaseInfoByRes(res);
        })
    },
    buildBaseInfoByRes(res){
      this.importerlist = res.result.importerVoList.result;

      this.fsee = true;
      let list = [];
      if ( this.importerlist) {
        this.importerlist.map((r) => {
          // let label = r.label;
          if (r.voList && r.voList.length > 0) {
            r.voList.map((b) => {
              list.push(b);
              let vat = b.vat?b.vat+' ':'';
              // let eori = b.eori?b.eori+' ':'';
              let country = b.country?" (" + b.country + ") ":'';
              // b.name = b.name + "(" + label + ")";
              //  b.name =vat + eori + country +b.name +"("+label+")";

              b.name =b.name + country + " " + vat;

            });
          }
        });
      }
      this.importerMap = this.listToMap(list, "id");
      // console.log("获得的进口商map", list, this.importerMap);
      this.imloading = false;

      this.channelSetList = res.result.channelSetList.result;
      this.lclPlaceList = res.result.lclPlaceList.result;
      this.deliveryPointList = res.result.deliveryPointList.result;
      //console.log("拼柜仓库集合",this.lclPlaceList);
      let docPageVo = res.result.docPageVo;
      if (docPageVo) {
        for (var name in docPageVo) {
          this.$set(this.form, name, docPageVo[name]);
        }
      }
      // this.initChannelSetList();
      // console.log('获取的客服页数据', this.form);

      this.isRucang = this.form.lclPlace && this.form.lclPlace.length > 0;
      if (
          this.form.shipmentType === "0" ||
          this.form.shipmentType === "2"
      ) {
        this.shipmentCountry='';
        if (this.form.shipmentCountry) {
          this.shipmentCountry += this.form.shipmentCountry;
        }
        if (this.form.shipmentState ==='OH') {
          this.shipmentCountry += "-" + this.form.shipmentState;
        }
        if (this.form.shipmentCode) {
          this.shipmentCountry += "-" + this.form.shipmentCode;
        }
        if (this.form.shipmentZip) {
          this.shipmentCountry += "-" + this.form.shipmentZip;
        }
      } else {
        this.shipmentCountry='';
        if (this.form.shipmentCountry) {
          this.shipmentCountry += this.form.shipmentCountry;
        }

        if (this.form.shipmentState) {
          this.shipmentCountry += "-" + this.form.shipmentState;
        }
        if (this.form.city) {
          this.shipmentCountry += "-" + this.form.city;
        }
      }

      // this.shipmentCountry =  this.form.shipmentCountry+'-'+this.form.shipmentCode + '-' + this.form.shipmentZip;
      // this.form.declEndTime = '2021-06-24 00:22:55';
      this.declEndTime = this.formateTime(this.form.declEndTime);
      this.createTime = this.formateTime(this.form.createTime);
      this.etd = this.formateTime(this.form.etd);
      this.eta = this.formateTime(this.form.eta);
      this.initForm();
    },
    checkIsCtCopy(){
if(this.form.createTime){
    // 是否为2024年04月之前的订单
    let timeFlag = this.isAfterApril2024(this.form.createTime)
    if(this.basicForm.isEurope == '1'&& timeFlag){
      return false
    }else if((this.basicForm.isEurope == '1') && this.form.containerType === '0' && this.form.solicitation !=='1'&&this.form.orderType !== '1'&&!timeFlag ){
      return true
    }else {
      return false
    }
  }
    },
  isAfterApril2024(timestamp) {
 // 将时间戳转换为 Date 对象
    let date = new Date(timestamp);
    // 判断年份和月份
    if (date.getFullYear() < 2024 || (date.getFullYear() === 2024 && date.getMonth() < 3)) {
        return true;
    } else {
        return false;
    }
},
sendOrderTypeTwo(){
  if(!this.basicForm.orderTypeTwo) {
       this.$message.warning('请选择订单类型2')
    return
  }
   postAction(this.url.updateOrderTypeTwo, {
        orderNo: this.$route.query.orderNo,
        orderTypeTwo:this.basicForm.orderTypeTwo
      }).then((res) => {
        this.$message.success(res.message)
        this.initAllInfo()
        this.getUserIsSales()
      });
},
reasonClose() {
  this.reasonVisible=false;
  this.reasonForm={}
},
  },
  created() {
    this.initAllInfo();

    this.getUserIsSales();

  },
  mounted() {
    this.initForm();
    this.$emit('getBasicFrom',this.form)

  },
};
</script>

<style lang="scss" scoped>
.el-input,.el-select{
  width: 100%;
}
.save_img {
  width: 20px;
  height: 22px;
}
</style>
<style lang="scss">
.no_label {
  display: flex;
  .el-form-item__label {
    padding-left: 5px;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
.margin_right_small {
  margin-right: 10px
}
.detail_eidt {
  .no_margin {
    margin: 0;
    padding: 0 0px 0px 15px !important;
    height: 22px !important;
  }
  .box-card {
    margin-top: 20px;
    margin-bottom: 0;
    border: 1px solid #EBEBEB;
    border-radius: 0px;
  }
  .el-card.is-always-shadow {
    box-shadow: unset;
  }
  .el-card__body {
    padding: 20px !important;
  }
}
.text_red {
  .el-input__inner {
    color: red;
  }
  .el-textarea__inner {
    color: red;
  }
}
</style>
